import { Node } from "reactflow";
import { DataProps, SelectorNode, SearchMatch, ConfigProps } from "../../types/customTypes";
import { JourneyStep } from "../../api/models/all";
import { titleize } from "../../common/helpers/string/titleize";

export interface ConfigData {
  configObject: ConfigProps | JourneyStep;
  parentKey: string[];
}

export const generateConfigData = (
  configObject: ConfigData,
  key: string,
  configProps: ConfigProps | JourneyStep
): ConfigData => {
  const parentArray = [...configObject.parentKey, key];
  return { configObject: configProps, parentKey: parentArray };
};

export const findStringInNode = (node: Node<DataProps>, search: string): Array<string[]> => {
  const stack: ConfigData[] = [{ configObject: node.data.config, parentKey: [] }];
  const pathArray: string[][] = [];
  while (stack.length) {
    const objectWithConfig = stack.pop();
    const objectPopped = objectWithConfig?.configObject;
    if (objectWithConfig && objectPopped) {
      const keys = Object.keys(objectPopped);
      for (let i = 0; i < keys.length; i += 1) {
        if (keys[i].trim() !== "subJourneys") {
          if (Array.isArray(objectPopped[keys[i]])) {
            if (keys[i].trim() !== "nodes") {
              const objectArr: ConfigData[] = (objectPopped[keys[i]] as Array<ConfigProps>).map((obj: ConfigProps) => {
                return generateConfigData(objectWithConfig, keys[i], obj);
              });
              stack.push(...objectArr);
            }
          } else if (typeof objectPopped[keys[i]] === "object") {
            stack.push(generateConfigData(objectWithConfig, keys[i], objectPopped[keys[i]] as ConfigProps));
          } else if (String(objectPopped[keys[i]]).toLowerCase().includes(search.toLowerCase())) {
            const parentArray = [...objectWithConfig.parentKey, keys[i]];
            pathArray.push(parentArray);
          }
        }
       
      }
    }
  }
  return pathArray;
};

export const getSearchedItemLabel = (type: string, keys: string[][]) => {
  if (keys.length <= 0) return "";
  const defaultLabel = `Type:${titleize(type)}/in`;
  let label = "";
  const requiredIndex = 2;
  for (let currentIndex = 0; currentIndex < keys.length; currentIndex += 1) {
    if (currentIndex === requiredIndex) {
      label = `${label} etc`;
      break;
    }
    const keyArr = keys[currentIndex];
    if (keyArr.length > 1) {
      label = `${label}, '${keyArr[0]}/../${keyArr[keyArr.length - 1]}'`;
    } else if (keyArr.length === 1) {
      label = `${label}${label === "" ? "" : ","} '${keyArr[0]}'`;
    }
  }
  return defaultLabel + label;
};

export const findNodesFromCanvas = (nodes: Node<DataProps>[], searchString: string): SelectorNode[] => {
  const foundValues: SelectorNode[] = [];
  nodes.forEach((node) => {
    const keys = findStringInNode(node, searchString);
    if (keys.length > 0) {
      const { type } = node.data.config;
      foundValues.push({ value: node, label: getSearchedItemLabel(type, keys) });
    }
  });

  return foundValues;
};

export const stepSearchResults = (match: SearchMatch, step: number): SelectorNode | null => {
  if (!match.elements.length) return null;
  const { elements, selected } = match;
  let offset = 0;
  const matchIdx = elements.findIndex((r) => r.value.id === selected?.value.id);
  if (matchIdx < 0) {
    offset = Math.sign(step) === 1 ? 0 : 1;
  }
  return elements[(elements.length + matchIdx + step + offset) % elements.length];
};

/**
 * SPM User Journey APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 7.2.0
 * Contact: tbc@postoffice.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { StepVisualTransitionProperty } from './StepVisualTransitionProperty';
import { HttpFile } from '../http/http';

export class OptionsStepVisualProperties {
    'transition'?: StepVisualTransitionProperty;
    /**
    * Visual variant for Options step type.
    */
    'view'?: OptionsStepVisualPropertiesViewEnum;
    /**
    * Used to render the step in a disabled state. Works with \"dropdown\" view only.
    */
    'disabled'?: boolean;
    /**
    * Visual display for this step type.
    */
    'layout'?: OptionsStepVisualPropertiesLayoutEnum;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "transition",
            "baseName": "transition",
            "type": "StepVisualTransitionProperty",
            "format": ""
        },
        {
            "name": "view",
            "baseName": "view",
            "type": "OptionsStepVisualPropertiesViewEnum",
            "format": ""
        },
        {
            "name": "disabled",
            "baseName": "disabled",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "layout",
            "baseName": "layout",
            "type": "OptionsStepVisualPropertiesLayoutEnum",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return OptionsStepVisualProperties.attributeTypeMap;
    }

    public constructor() {
    }
}


export type OptionsStepVisualPropertiesViewEnum = "radio" | "dropdown" ;
export type OptionsStepVisualPropertiesLayoutEnum = "grid" | "list" ;

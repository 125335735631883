import { AttributeNode } from "../../../types/customTypes";
import { commonNodeConfig } from "../../constants/commonNodeConfigProperties";

export const componentText = {
  connectionRequired: "Requires top connection to enable",
  default: "Enter Data",
  missingConnection: "Name won't export, missing top connection",
  requiredField: "required field",
};

export const getPlaceHolderText = (nodeAttribute: string, hasTopConnection: boolean): string => {
  if (nodeAttribute === commonNodeConfig.sjName) {
    return hasTopConnection ? componentText.default : componentText.connectionRequired;
  }
  return componentText.default;
};

export const getDisabledStatus = (
  nodeAttribute: string,
  hasTopConnection: boolean,
  value: string | undefined
): boolean =>
  (nodeAttribute === commonNodeConfig.sjName && !hasTopConnection && !value) || nodeAttribute === commonNodeConfig.type;

export const checkRequiredFields = (configKey: string, nodeData: AttributeNode) => {
  const { requiredFields } = nodeData.data.validationDatum;
  const obj: Record<string, boolean> = {};
  obj.confRequired = requiredFields.includes(configKey);
  const confNotPresent =
    nodeData.data.config[configKey] === undefined || (nodeData.data.config[configKey] as string).length === 0;
  obj.confRequiredNotPresent = obj.confRequired && confNotPresent;
  return obj;
};

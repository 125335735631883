/**
 * SPM User Journey APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 7.2.0
 * Contact: tbc@postoffice.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class ColumnsDataVisual {
    /**
    * Visual variant for column type.
    */
    'view'?: ColumnsDataVisualViewEnum;
    /**
    * Size of the cell
    */
    'size'?: ColumnsDataVisualSizeEnum;
    /**
    * Visual alignment for column.
    */
    'alignment'?: ColumnsDataVisualAlignmentEnum;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "view",
            "baseName": "view",
            "type": "ColumnsDataVisualViewEnum",
            "format": ""
        },
        {
            "name": "size",
            "baseName": "size",
            "type": "ColumnsDataVisualSizeEnum",
            "format": ""
        },
        {
            "name": "alignment",
            "baseName": "alignment",
            "type": "ColumnsDataVisualAlignmentEnum",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return ColumnsDataVisual.attributeTypeMap;
    }

    public constructor() {
    }
}


export type ColumnsDataVisualViewEnum = "checkbox" | "counter" | "delete" | "multipleText" | "radioButton" | "text" | "title" ;
export type ColumnsDataVisualSizeEnum = "x_small" | "small" | "medium" | "large" | "x_large" ;
export type ColumnsDataVisualAlignmentEnum = "left" | "right" ;

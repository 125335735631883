import { useEffect, useRef } from "react";
import { InputKey, CanvasKeyAction, KeyModifier } from "../../../types/customTypes";
import { getOS, Platform } from "../../helpers/platform";
import { isEqual } from "../../utils";
import { KeyboardEventWithTargetLocalName, KeyEventDispatchTrigger, KeyPressMatchProps } from "./keyDispatchTypes";

const os = getOS();
const ignoredRawEventKeys = [KeyModifier.CTRL, KeyModifier.ALT, KeyModifier.SHIFT, KeyModifier.META];

export const coalesceKey = (key: string | CanvasKeyAction): CanvasKeyAction => {
  if (typeof key === "string") {
    return { action: key as InputKey, ctrl: false, shift: false, alt: false, meta: false };
  }
  return {
    action: key.action,
    ctrl: key.ctrl || false,
    shift: key.shift || false,
    alt: key.alt || false,
    meta: key.meta || false,
  };
};

export const getLocalName = (ev: KeyboardEvent): string => {
  const e = ev as KeyboardEventWithTargetLocalName;
  if (!(e && e.target && e.target.localName)) return "";
  return e.target.localName;
};

export const matchTarget = (eventTarget: string | undefined, triggerTarget?: string[]): boolean => {
  if (triggerTarget === undefined) return true;
  if (eventTarget === undefined) return false;
  return triggerTarget.includes(eventTarget);
};

export const matchNestedKey = (
  key: CanvasKeyAction,
  keyEventTriggers: KeyEventDispatchTrigger[],
  evTarget: string
): KeyEventDispatchTrigger | undefined => {
  if (!key) return undefined;
  return keyEventTriggers.find((triggerGroup) => {
    return triggerGroup.keys.find((triggerKey) => {
      return matchTarget(evTarget, triggerGroup.target) && isEqual(coalesceKey(triggerKey), coalesceKey(key));
    });
  });
};

export const keyPressHandler = (
  ev: KeyboardEvent,
  trackedKeyEventTriggers: KeyEventDispatchTrigger[],
  targetOS = os
) => {
  if (ignoredRawEventKeys.includes(ev.key.toLocaleLowerCase() as KeyModifier)) return;
  const { shiftKey, ctrlKey, altKey, metaKey } = ev;
  const target = getLocalName(ev);
  const slimKeyEvent: KeyPressMatchProps = {
    action: ev.key as InputKey,
    ctrl: targetOS === Platform.OSX ? metaKey : ctrlKey,
    shift: shiftKey,
    alt: altKey,
    meta: targetOS === Platform.OSX ? ctrlKey : metaKey,
    target,
  };
  const matchedKeyEvent = matchNestedKey(slimKeyEvent, trackedKeyEventTriggers, target);
  if (!matchedKeyEvent) return;

  ev.preventDefault();
  matchedKeyEvent.action(slimKeyEvent);
};

export function useKeyDispatchHandler(trackedKeyEventTriggers: KeyEventDispatchTrigger[]): void {
  const initRef = useRef(false);
  useEffect(() => {
    if (initRef.current) return;
    initRef.current = true;
    window.addEventListener("keydown", (ev) => keyPressHandler(ev, trackedKeyEventTriggers));
    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener("keydown", (ev) => keyPressHandler(ev, trackedKeyEventTriggers));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export const signUpFields = [
  {
    type: "email",
    required: true,
  },
  {
    type: "password",
    required: true,
  },
];

/**
 * SPM User Journey APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 7.2.0
 * Contact: tbc@postoffice.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { CompleteStepReceiptPropertiesClerkConfirmation } from './CompleteStepReceiptPropertiesClerkConfirmation';
import { HttpFile } from '../http/http';

export class CompleteStepReceiptProperties {
    /**
    * Template Name
    */
    'template': string;
    /**
    * Data passed to template to be rendered
    */
    'context': { [key: string]: string; };
    /**
    * Message to be displayed whilst printing
    */
    'printingMessage': string;
    /**
    * Is printing optional
    */
    'optional': boolean;
    'clerkConfirmation'?: CompleteStepReceiptPropertiesClerkConfirmation;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "template",
            "baseName": "template",
            "type": "string",
            "format": ""
        },
        {
            "name": "context",
            "baseName": "context",
            "type": "{ [key: string]: string; }",
            "format": ""
        },
        {
            "name": "printingMessage",
            "baseName": "printingMessage",
            "type": "string",
            "format": ""
        },
        {
            "name": "optional",
            "baseName": "optional",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "clerkConfirmation",
            "baseName": "clerkConfirmation",
            "type": "CompleteStepReceiptPropertiesClerkConfirmation",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return CompleteStepReceiptProperties.attributeTypeMap;
    }

    public constructor() {
    }
}

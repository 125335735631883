import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Select, { SingleValue } from "react-select";
import { GroupJourneys, IDevice, JourneyItem } from "../common/helpers/apihelpers/types";
import { getDevices, getJourney, getJourneys } from "../common/helpers/apihelpers";
import { JourneyDownloadTypes } from "../common/constants/journeyDownloadTypes";

interface LibraryJourneyProps {
  importJourney: (script: string, inputType: string) => void;
}

const LibraryJourney = ({ importJourney }: LibraryJourneyProps): JSX.Element => {
  const [devices, setDevices] = useState<IDevice[]>([]);
  const [journeys, setJourneys] = useState<GroupJourneys[]>([]);
  const [selectedDevice, setSelectedDevice] = useState<string>();
  const [selectedJourney, setSelectedJourney] = useState<JourneyItem>();
  const noJourneyFound = "No Journey Found";
  const validImport = selectedJourney && selectedJourney.name !== noJourneyFound && selectedDevice;

  useEffect(() => {
    getDevices()
      .then((deviceResponse) => {
        setDevices(deviceResponse.devices);
      })
      .catch((err) => {
        toast(
          <div>
            <p>{err}</p>
          </div>
        );
      });
  }, []);

  const onDeviceChange = (device: SingleValue<IDevice>) => {
    if (!device?.id) {
      return;
    }
    setSelectedDevice(device.id);
    getJourneys(device.id)
      .then((journeysResponse) => {
        if (journeysResponse.length > 0) {
          setJourneys(journeysResponse);
          if (journeysResponse[0].options.length > 0) {
            setSelectedJourney(journeysResponse[0].options[0]);
          }
        } else {
          setSelectedJourney({
            category: noJourneyFound,
            displayName: noJourneyFound,
            categoryTitle: noJourneyFound,
            key: noJourneyFound,
            name: noJourneyFound,
          });
        }
      })
      .catch((err: Error) => {
        toast(
          <div>
            <p>{err.message}</p>
          </div>
        );
      });
  };

  const importFromCloud = () => {
    if (!(selectedJourney && selectedDevice)) return;
    getJourney(selectedJourney.name, selectedJourney.category)
      .then((journeyResponse) => {
        importJourney(JSON.stringify(journeyResponse), JourneyDownloadTypes.CLOUD);
      })
      .catch((err: Error) => {
        toast(
          <div>
            <p>{err.message}</p>
          </div>
        );
      });
  };

  return (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <div className="flex flex-col justify-center mb-2 w-80" data-testid="device-component">
        <p>
          <span className="text-pol-red">*</span> Select Device ID
        </p>
        <div data-testid="device-component-selector">
          <Select
            placeholder="Select Device"
            styles={{
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
            isSearchable={false}
            instanceId="custom-id"
            onChange={onDeviceChange}
            formatOptionLabel={(item: IDevice) => {
              return (
                <div className="reactSelectItem" key={item.id} data-testid="newId">
                  <span className="reactSelectText">{item.id}</span>
                </div>
              );
            }}
            options={devices}
          />
        </div>
      </div>
      <div className="flex flex-col justify-center mb-2 w-80" data-testid="journey-component">
        <p>
          <span className="text-pol-red">*</span> Select Journey
        </p>
        <div data-testid="journey-component-selector">
          <Select
            placeholder="Select Journey"
            isSearchable={false}
            value={selectedJourney}
            onChange={(e) => e && setSelectedJourney(e)}
            instanceId="journey-id"
            formatOptionLabel={(item) => {
              return (
                <div className="reactSelectItem" key={item.name} data-testid="newId">
                  <span className="reactSelectText">{item.displayName}</span>
                </div>
              );
            }}
            options={journeys}
          />
        </div>
      </div>
      <div className="flex justify-center items-center mt-10">
        <button
          type="button"
          data-testid="import-journey"
          className={`${validImport ? "bg-pol-red" : "bg-disabled-gray cursor-not-allowed opacity-50"}
            w-40 p-2 text-white rounded-sm relative`}
          onClick={(): void => importFromCloud()}
          disabled={!validImport}
        >
          Import
        </button>
      </div>
    </div>
  );
};

export default LibraryJourney;

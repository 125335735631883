/**
 * SPM User Journey APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 7.2.0
 * Contact: tbc@postoffice.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Enabler } from './Enabler';
import { Node } from './Node';
import { NodeWithChildren } from './NodeWithChildren';
import { NodeWithEnablers } from './NodeWithEnablers';
import { NodeWithOutputData } from './NodeWithOutputData';
import { TypeConditionStep } from './TypeConditionStep';
import { TypeConditionStepComparisons } from './TypeConditionStepComparisons';
import { HttpFile } from '../http/http';

/**
* Used to choose the next step based on input data. All the siblings of condition must be of type condition. Conditions are evaluated in a given order and first to match will allow progressing.
*/
export class ConditionStep {
    'type': TypeConditionStep;
    'comparison': TypeConditionStepComparisons;
    'expected': string | Array<string>;
    'given': string;
    'enablers'?: Array<Enabler>;
    'nodes'?: Array<Node>;
    /**
    * Values that should be preserved between journey steps. Key is dot-notation key and value is a template or simple value.
    */
    'outputData'?: { [key: string]: string; };

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "type",
            "baseName": "type",
            "type": "TypeConditionStep",
            "format": ""
        },
        {
            "name": "comparison",
            "baseName": "comparison",
            "type": "TypeConditionStepComparisons",
            "format": ""
        },
        {
            "name": "expected",
            "baseName": "expected",
            "type": "string | Array<string>",
            "format": ""
        },
        {
            "name": "given",
            "baseName": "given",
            "type": "string",
            "format": ""
        },
        {
            "name": "enablers",
            "baseName": "enablers",
            "type": "Array<Enabler>",
            "format": ""
        },
        {
            "name": "nodes",
            "baseName": "nodes",
            "type": "Array<Node>",
            "format": ""
        },
        {
            "name": "outputData",
            "baseName": "outputData",
            "type": "{ [key: string]: string; }",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return ConditionStep.attributeTypeMap;
    }

    public constructor() {
    }
}

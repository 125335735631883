import { HashCanvas, SubJourneyStruct, StackNode, WithNodes, SubJourneyBreakdown } from "../types";
import { NodeHandle } from "../../types/customTypes";
import { JourneyStep, TypeJourneyStep, TypeSubJourneyStep } from "../../openapi/api";
import { structuredClone } from "../../common/utils";

export const removeNestedNodes = (node: StackNode | undefined): StackNode | undefined => {
  if (!node) return undefined;
  const nodeCopy = structuredClone(node);
  if ((nodeCopy.node as WithNodes).nodes) {
    (nodeCopy.node as WithNodes).nodes = [];
  }
  return nodeCopy;
};

export const findNodeByID = (nodes: HashCanvas["nodes"], id: string): StackNode | undefined => {
  const node = nodes.find((n) => n.id === id)?.journeyNode;
  return node ? { node, id } : undefined;
};

export const getNextSibling = (node: StackNode, canvas: HashCanvas): StackNode | undefined => {
  const { edges, nodes } = canvas;
  const siblingEdge = edges.find((edge) => edge.source === node.id && edge.sourceHandle === NodeHandle.RIGHT);
  return siblingEdge?.target ? removeNestedNodes(findNodeByID(nodes, siblingEdge.target)) : undefined;
};

export const findSiblings = (firstNode: StackNode | undefined, canvas: HashCanvas): StackNode[] => {
  if (!firstNode) return [];
  const siblingHolder: StackNode[] = [];
  const siblingStack = [firstNode];
  while (siblingStack.length) {
    const currentNode = siblingStack.shift() as StackNode;
    siblingHolder.push(currentNode);
    const nextSibling = getNextSibling(currentNode, canvas);
    if (nextSibling) {
      siblingStack.push(nextSibling);
    }
  }
  return siblingHolder;
};

export const findFirstChild = (parentNode: StackNode | undefined, canvas: HashCanvas): StackNode | undefined => {
  const { nodes, edges } = canvas;
  const parentEdge = edges.find((edge) => edge.source === parentNode?.id && edge.sourceHandle === NodeHandle.BOTTOM);
  return parentEdge?.target ? removeNestedNodes(findNodeByID(nodes, parentEdge.target)) : undefined;
};

export const findChildren = (parentNode: StackNode, canvas: HashCanvas): StackNode[] => {
  const firstChild = findFirstChild(parentNode, canvas);
  return firstChild ? findSiblings(firstChild, canvas) : [];
};

const subJourneySubstitution = (children: StackNode[], sjs: SubJourneyStruct): SubJourneyBreakdown => {
  if (children.length < 1) {
    return { childNodes: [], subJourneys: {} };
  }
  const childNodes: SubJourneyBreakdown["childNodes"] = [];
  const subJourneys: SubJourneyBreakdown["subJourneys"] = {};
  const sjEntries = Object.entries(sjs);
  const processedChildren: string[] = [];

  children.forEach((c) => {
    if (processedChildren.indexOf(c.id) < 0) {
      const subJourney = sjEntries.find((sj) => sj[1][0] === c.id);

      if (subJourney) {
        const siblingsToInclude = children.filter((sc) => {
          return subJourney[1].indexOf(sc.id) >= 0;
        });
        processedChildren.push(...siblingsToInclude.map((s) => s.id));

        subJourneys[subJourney[0]] = siblingsToInclude.map((s) => s.node);
        childNodes.push({
          type: TypeSubJourneyStep.SubJourney,
          name: subJourney[0],
        });
      } else {
        childNodes.push(c.node);
      }
    }
  });

  return {
    childNodes,
    subJourneys,
  };
};

const subJourneyregex = RegExp("^(?!(sj-).)");
export const removeGeneratedSubJourneys = (
  subJourneys: JourneyStep["subJourneys"]
): JourneyStep["subJourneys"] | undefined => {
  if (!subJourneys) {
    return undefined;
  }
  return Object.fromEntries(
    Object.entries(subJourneys).filter((key) => {
      return subJourneyregex.test(key[0]);
    })
  );
};

/*
  Generate JourneyEngine journey structure given canvas and list of
  sub-journeys and nodeIDs that goes into them.
*/
export const buildJourney = (canvas: HashCanvas, sjs: SubJourneyStruct): JourneyStep => {
  const startNode = canvas.nodes.find((n) => n.journeyNode.type === TypeJourneyStep.Journey);
  if (!startNode) {
    throw new Error("There is no start node");
  }
  const journey: JourneyStep = startNode.journeyNode as JourneyStep;

  /* FIXME: Temporary fix for repeated subjourney generation. To be removed once we handle additional subjourneys
  We need to either recognise re-use or prevent currently generated sjs from being added to subjourneys attribute of the startNode */
  journey.subJourneys = removeGeneratedSubJourneys(journey.subJourneys);
  const s: StackNode[] = [{ node: journey, id: startNode.id }];

  while (s.length > 0) {
    const snode = s.pop() as StackNode;
    const children = findChildren(snode, canvas);

    if (children.length) {
      const { childNodes, subJourneys } = subJourneySubstitution(children, sjs);

      if (Object.keys(subJourneys).length > 0) {
        journey.subJourneys = { ...(journey.subJourneys || {}), ...subJourneys };
      }
      (snode.node as WithNodes).nodes = childNodes;
      s.push(...children);
    }
  }
  return journey;
};

import { TabToolProps } from "../types/customTypes";
import { ElementActions, ValidElementActions } from "../common/reducers/elements";

export interface TabToolBagProps {
  elementsDispatch: React.Dispatch<ValidElementActions>;
  setAttributeBar: React.Dispatch<React.SetStateAction<boolean>>;
}

const TabToolBag = ({ elementsDispatch }: TabToolBagProps): TabToolProps => {
  const onDelete = (index: number) => {
    elementsDispatch({
      type: ElementActions.DELETE_CANVAS,
      payload: { canvas: index },
    });
  };

  const onChange = (canvasIndex: number) => {
    elementsDispatch({ type: ElementActions.SET_CURRENT_TAB, payload: { canvas: canvasIndex } });
  };

  const onAdd = () => {
    elementsDispatch({ type: ElementActions.ADD_CANVAS });
  };

  const tabLogicHolder: TabToolProps = {
    onChange,
    onAdd,
    onDelete,
  };

  return tabLogicHolder;
};

export default TabToolBag;

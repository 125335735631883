/**
 * SPM User Journey APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 7.2.0
 * Contact: tbc@postoffice.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class StepVisualTransitionPropertyOneOf {
    'next'?: string;
    'back'?: string;
    'backState'?: StepVisualTransitionPropertyOneOfBackStateEnum;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "next",
            "baseName": "next",
            "type": "string",
            "format": ""
        },
        {
            "name": "back",
            "baseName": "back",
            "type": "string",
            "format": ""
        },
        {
            "name": "backState",
            "baseName": "backState",
            "type": "StepVisualTransitionPropertyOneOfBackStateEnum",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return StepVisualTransitionPropertyOneOf.attributeTypeMap;
    }

    public constructor() {
    }
}


export type StepVisualTransitionPropertyOneOfBackStateEnum = "default" | "disabled" ;

import React from "react";
import { titleize } from "../common/helpers/string/titleize";
import { keyGenerator } from "../common/helpers/tooltips/keyGenerator";
import { JSONObject, ToolTipData } from "../types/customTypes";
import NodeTooltipContent from "./NodeTooltipContent";

export interface NodeTooltipProps {
  configArray: JSONObject[];
  tooltipData: ToolTipData;
}

const NodeTooltip = ({ configArray, tooltipData }: NodeTooltipProps): JSX.Element => {
  if (configArray.length === 0) return <div />;
  return (
    <div
      className={`${tooltipData.colour} -mx-1 -mb-1 flex mt-1 flex-wrap rounded-b-md`}
      data-testid="tooltipItem-attachment"
    >
      {configArray.map((tooltipItem, index) => {
        const { id, title, value } = tooltipItem;
        const key = keyGenerator(index, id as string, title as string, value as string);
        const hoverTitle: string = tooltipItem[`${tooltipData.hoverKey}`]
          ? titleize(String(tooltipItem[`${tooltipData.hoverKey}`]))
          : "";
        const comma: string = configArray && index !== configArray.length - 1 ? ", " : "";
        return (
          <div className="group/tooltip pointer-events-auto" key={key} data-testid="tooltipItem-tag">
            <p className="hover:underline text-xs ml-1 pb-0.5">{`${hoverTitle}${comma}`}</p>
            <div
              className="bg-gray-300 p-3 top-10 left-32 z-50 pointer-events-none rounded hidden group-hover/tooltip:block absolute text-left z-50 text-wrap"
              data-testid={`${String(tooltipItem[`${tooltipData.hoverKey}`])}-tooltip`}
            >
              <NodeTooltipContent tooltipItem={tooltipItem} tooltipData={tooltipData} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default NodeTooltip;

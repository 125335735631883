/**
 * SPM User Journey APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 7.2.0
 * Contact: tbc@postoffice.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Node } from './Node';
import { NodeWithChildren } from './NodeWithChildren';
import { TypeJourneyStep } from './TypeJourneyStep';
import { HttpFile } from '../http/http';

/**
* Root node of the journey
*/
export class JourneyStep {
    'type': TypeJourneyStep;
    /**
    * Name of the journey
    */
    'title': string;
    /**
    * Highest journey engine version this journey works with
    */
    'engineVersion'?: string;
    /**
    * Data set in outputData to be available from the beginning of the journey
    */
    'initialData'?: any;
    'subJourneys'?: { [key: string]: Array<Node>; };
    'nodes'?: Array<Node>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "type",
            "baseName": "type",
            "type": "TypeJourneyStep",
            "format": ""
        },
        {
            "name": "title",
            "baseName": "title",
            "type": "string",
            "format": ""
        },
        {
            "name": "engineVersion",
            "baseName": "engineVersion",
            "type": "string",
            "format": ""
        },
        {
            "name": "initialData",
            "baseName": "initialData",
            "type": "any",
            "format": ""
        },
        {
            "name": "subJourneys",
            "baseName": "subJourneys",
            "type": "{ [key: string]: Array<Node>; }",
            "format": ""
        },
        {
            "name": "nodes",
            "baseName": "nodes",
            "type": "Array<Node>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return JourneyStep.attributeTypeMap;
    }

    public constructor() {
    }
}

/**
 * SPM User Journey APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 7.2.0
 * Contact: tbc@postoffice.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ValidationFieldAlgorithm } from './ValidationFieldAlgorithm';
import { ValidationFieldComparison } from './ValidationFieldComparison';
import { ValidationFieldConfirmation } from './ValidationFieldConfirmation';
import { ValidationFieldFormat } from './ValidationFieldFormat';
import { ValidationFieldNumericality } from './ValidationFieldNumericality';
import { ValidationFieldRequired } from './ValidationFieldRequired';
import { ValidationFieldUniqueness } from './ValidationFieldUniqueness';
import { HttpFile } from '../http/http';

/**
* Validation for given field
*/
export class ValidationField {
    /**
    * Name of the step's field to validate
    */
    'field': string;
    'format'?: ValidationFieldFormat;
    'numericality'?: ValidationFieldNumericality;
    'required'?: ValidationFieldRequired;
    'uniqueness'?: ValidationFieldUniqueness;
    'confirmation'?: ValidationFieldConfirmation;
    'algorithm'?: ValidationFieldAlgorithm;
    'comparison'?: ValidationFieldComparison;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "field",
            "baseName": "field",
            "type": "string",
            "format": ""
        },
        {
            "name": "format",
            "baseName": "format",
            "type": "ValidationFieldFormat",
            "format": ""
        },
        {
            "name": "numericality",
            "baseName": "numericality",
            "type": "ValidationFieldNumericality",
            "format": ""
        },
        {
            "name": "required",
            "baseName": "required",
            "type": "ValidationFieldRequired",
            "format": ""
        },
        {
            "name": "uniqueness",
            "baseName": "uniqueness",
            "type": "ValidationFieldUniqueness",
            "format": ""
        },
        {
            "name": "confirmation",
            "baseName": "confirmation",
            "type": "ValidationFieldConfirmation",
            "format": ""
        },
        {
            "name": "algorithm",
            "baseName": "algorithm",
            "type": "ValidationFieldAlgorithm",
            "format": ""
        },
        {
            "name": "comparison",
            "baseName": "comparison",
            "type": "ValidationFieldComparison",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return ValidationField.attributeTypeMap;
    }

    public constructor() {
    }
}

import React from "react";
import { Auth } from "aws-amplify";
import Logo from "../assets/images/logo.png";
import { ComponentText } from "../common/constants/componentText";
import HeaderWarning from "./HeaderWarning";
import packageJson from "../../package.json";

export interface HeaderProps {
  isLoggedIn: boolean;
}

const Header = ({ isLoggedIn }: HeaderProps): JSX.Element => {
  const { version } = packageJson as { version: string };
  const handleLogout = async () => {
    await Auth.signOut();
  };

  /* eslint-disable-next-line */
  const logo: string = Logo;

  return (
    <nav className="bg-white border-b border-gray-200">
      <div className="px-4 mx-auto sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <div className="flex items-center flex-shrink-0">
              <img className="block w-auto h-8" src={logo} alt="Post Office" />
            </div>
            <div className="cursor-pointer sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
              <div
                className={`inline-flex items-center px-1 pt-1 ${
                  isLoggedIn ? "border-pol-red border-b-2" : "border-gray-200 border-b"
                }`}
              >
                <h3 className="text-sm font-bold text-gray-900">{ComponentText.APP_NAME}</h3>
                <span className="text-xs font-bold text-gray-400 deleteMe">&nbsp; v{version}</span>
              </div>
            </div>
          </div>
          <div className="hidden sm:ml-6 sm:flex sm:items-center">
            <div className="relative ml-3">
              {isLoggedIn && (
                <button
                  type="button"
                  onClick={handleLogout}
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  {ComponentText.LOG_OUT}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {!isLoggedIn && <HeaderWarning />}
    </nav>
  );
};
export default Header;

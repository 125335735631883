/**
 * SPM User Journey APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 7.2.0
 * Contact: tbc@postoffice.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class ValidationFieldComparison {
    /**
    * Message to display validation fails.
    */
    'message': string;
    /**
    * Expression to evaluate and compare against one or more of the comparisons. It allows to access sent in input values via `validationInput` object.
    */
    'expression': string;
    /**
    * By default compare values as text, but can be configured to compare values as numbers.
    */
    'comparisonType'?: ValidationFieldComparisonComparisonTypeEnum;
    /**
    * Expect expression result to be equal to this value
    */
    'eq'?: string | number;
    /**
    * Expect expression result to not be equal to this value
    */
    'neq'?: string | number;
    /**
    * Expect expression result to be greater than this value
    */
    'gt'?: string | number;
    /**
    * Expect expression result to be greater than or equal this value
    */
    'gte'?: string | number;
    /**
    * Expect expression result to be less than this value
    */
    'lt'?: string | number;
    /**
    * Expect expression result to be less than or equal this value
    */
    'lte'?: string | number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "message",
            "baseName": "message",
            "type": "string",
            "format": ""
        },
        {
            "name": "expression",
            "baseName": "expression",
            "type": "string",
            "format": ""
        },
        {
            "name": "comparisonType",
            "baseName": "comparisonType",
            "type": "ValidationFieldComparisonComparisonTypeEnum",
            "format": ""
        },
        {
            "name": "eq",
            "baseName": "eq",
            "type": "string | number",
            "format": ""
        },
        {
            "name": "neq",
            "baseName": "neq",
            "type": "string | number",
            "format": ""
        },
        {
            "name": "gt",
            "baseName": "gt",
            "type": "string | number",
            "format": ""
        },
        {
            "name": "gte",
            "baseName": "gte",
            "type": "string | number",
            "format": ""
        },
        {
            "name": "lt",
            "baseName": "lt",
            "type": "string | number",
            "format": ""
        },
        {
            "name": "lte",
            "baseName": "lte",
            "type": "string | number",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return ValidationFieldComparison.attributeTypeMap;
    }

    public constructor() {
    }
}


export type ValidationFieldComparisonComparisonTypeEnum = "text" | "number" ;

/**
 * SPM User Journey APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 7.2.0
 * Contact: tbc@postoffice.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class ValidationFieldUniqueness {
    /**
    * Message to display when field is not unique.
    */
    'message': string;
    /**
    * JSONPath selector of values to check uniqueness against
    */
    'selector': string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "message",
            "baseName": "message",
            "type": "string",
            "format": ""
        },
        {
            "name": "selector",
            "baseName": "selector",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return ValidationFieldUniqueness.attributeTypeMap;
    }

    public constructor() {
    }
}

import { GenericNodeTypes } from "../../../files/types";

export const getNodesWithSjName = (nodes: GenericNodeTypes[]): Record<string, string> => {
  const nodesHolder = nodes.reduce((acc, node) => {
    const { id, journeyNode } = node;
    const { sjName } = journeyNode;
    if (sjName) {
      return { ...acc, [id]: sjName };
    }
    return acc;
  }, {} as Record<string, string>);
  return nodesHolder;
};

import { isEqual } from "../../utils";

type compareInput = Record<string, unknown> | string | null;
export const equalJsonContent = (src?: compareInput, cmp?: compareInput): boolean => {
  if(src === undefined || cmp === undefined) return false;
  if(src === null || cmp === null) return false;
  if(src === cmp) return true;
  let source = src;
  let compare = cmp;

  if(typeof src === "object"){ source = JSON.stringify(src); }
  if(typeof cmp === "object"){ compare = JSON.stringify(cmp); }

  try{
    return isEqual(JSON.parse(source as string), JSON.parse(compare as string));
  } catch (error){
    return false;
  }
};

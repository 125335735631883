import React from "react";

interface ModalContent {
  modalChildren: Record<string, JSX.Element>;
  selectedTab: string;
}

const ModalContent = ({ modalChildren, selectedTab }: ModalContent): JSX.Element => {
  const modalJsxContent = modalChildren[selectedTab];
  return (
    <div data-testid="modal-content" className="h-full">
      {modalJsxContent}
    </div>
  );
};

export default ModalContent;

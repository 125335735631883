export const SEARCH_MIN_CHAR = 3;

export enum SearchErrorStatus {
  NO_RESULT = "no_results",
  TOO_SHORT = "too_short",
}

export const errorStatusMessages: { [key in SearchErrorStatus]: string } = {
  no_results: "No results found",
  too_short: `Please enter at least ${SEARCH_MIN_CHAR} characters`,
};

import environment from "../../config/environment";

export const baseURL: string = environment.REACT_APP_DEVICE_MANAGER_API_URL || "";
export const DEVICE_OTP_PATH = "/device-management/v2/builder/devices/{deviceID}/generate-otp";
export const SAVE_JOURNEY_PATH = "/user-journey/v7/journey";
export const DEVICES_PATH = "/simulator/device";
export const GET_JOURNEY_LIST_PATH = "/user-journey/v7/journeys";
export const GET_JOURNEY_PATH = "/user-journey/v7/journey";
export const PREVIEW_DRAFT_JOURNEY_PATH = "/user-journey/v7/draft-journey";
export enum REQUEST_METHODS {
  POST = "POST",
  GET = "GET",
  PUT = "PUT",
  DELETE = "DELETE",
}

export enum JourneyCategories {
  DEFAULT_JOURNEYS = "defaultJourneys",
  DEFAULT_SUB_JOURNEYS = "defaultSubJourneys",
  DEVICE_JOURNEYS = "deviceJourneys",
  DEVICE_SUB_JOURNEYS = "deviceSubJourneys",
}

export enum ApiFetchStatus {
  FAIL = "fail",
  SUCCESS = "success",
}

import React from "react";
import { AttributeConfigProps, ElementChangeFunction } from "../types/customTypes";
import { titleize } from "../common/helpers/string/titleize";

interface AttributeBooleanFieldProps {
  config: AttributeConfigProps;
  booleanParam: string;
  handleOnChange: ElementChangeFunction;
}
const AttributeBooleanField = ({ booleanParam, handleOnChange, config }: AttributeBooleanFieldProps): JSX.Element => {
  return (
    <div key={booleanParam} className="mb-2 p-2 flex" data-testid="Boolean-Editor-container">
      <label htmlFor={booleanParam} className="block text-gray-700 text-sm font-bold mb-2 capitalize">
        {titleize(booleanParam)}
      </label>
      <input
        type="checkbox"
        data-cy={`${booleanParam}-editor`}
        data-testid={`${booleanParam}-editor`}
        checked={config[booleanParam] === true || (config[booleanParam] as string) === "true"}
        id={booleanParam}
        className="bg-white ml-auto focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-2 block appearance-none leading-normal text-xs font-normal"
        onChange={(e) =>
          handleOnChange({
            value: e.target.checked.toString(),
            key: booleanParam,
            nodeType: config.type,
            commitToHistory: true,
          })
        }
      />
    </div>
  );
};

export default AttributeBooleanField;

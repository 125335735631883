import React, { useCallback, useEffect } from "react";
import { KeyboardEvent } from "../types/customTypes";
import { nonCloseModalTitles } from "../common/constants/modals/modalData";

interface BackdropProps {
  children: JSX.Element;
  tabTitles: string[];
  close: () => void;
}

const Backdrop = ({ children, close, tabTitles }: BackdropProps): JSX.Element => {
  const disableBackgroundModal = nonCloseModalTitles.includes(tabTitles[0]);
  const onEscKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape" && !disableBackgroundModal) close();
    },
    [close, disableBackgroundModal]
  );

  const handleOnClick = () => {
    if (disableBackgroundModal) return;
    close();
  };

  useEffect(() => {
    document.addEventListener("keydown", onEscKeyDown, false);

    return () => {
      document.removeEventListener("keydown", onEscKeyDown, false);
    };
  }, [onEscKeyDown, close]);

  return (
    <div
      data-testid="modal-background"
      className="fixed inset-0 flex items-center justify-center z-50 bg-gray-600 bg-opacity-50 h-full w-full"
      onClick={handleOnClick}
      onKeyDown={onEscKeyDown}
      role="button"
      tabIndex={0}
    >
      {children}
    </div>
  );
};

export default Backdrop;

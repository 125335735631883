import { JsonFieldKeys, Format, FieldKeys } from "../../../types/customTypes";

interface HydrateInputReturn {
  formatKey: keyof FieldKeys;
  inputType: JsonFieldKeys | string;
}

export const hydrateInputTypes = (format: Format): HydrateInputReturn => {
  if (format.type === "number") {
    return { formatKey: "numberKeys", inputType: format.name };
  }
  if (format.type === "boolean") {
    return { formatKey: "booleanKeys", inputType: format.name };
  }
  if (
    (format.type.split(" ").includes("string") && !format.type.includes("|")) ||
    format.type.search("Type") !== -1 ||
    format.type.includes("Array<string") ||
    format.type.includes("Array<any>")
  ) {
    return { formatKey: "stringKeys", inputType: format.name };
  }
  if (format.type.includes("{ [key: string]:")) {
    return { formatKey: "jsonFieldKeys", inputType: { name: format.name, displayWith: "jsonObject" } };
  }
  if (format.type.includes("Array")) {
    return { formatKey: "jsonFieldKeys", inputType: { name: format.name, displayWith: "jsonArray" } };
  }
  return { formatKey: "jsonFieldKeys", inputType: { name: format.name, displayWith: "jsonObject" } };
};

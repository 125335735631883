export const tabStyles = (currentCanvas: number | null, index: number): Record<string, string> => {
  if (currentCanvas === index) {
    return {
      backgroundColor: "bg-pol-purple",
      tabBorder: "",
      buttonTextColor: "white",
    };
  }
  return {
    backgroundColor: "hover:bg-opacity-25 hover:bg-pol-purple",
    tabBorder: "border-r-2 border-gray-400",
    buttonTextColor: "black",
  };
};

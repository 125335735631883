import { CanvasNode, DataPropsWithId, AttributeNode } from "../../../types/customTypes";
import { isEqual } from "../../utils";

const createCompareNode = (input: CanvasNode | AttributeNode): DataPropsWithId => {
  return {
    id: input.id,
    config: input.data.config,
    label: input.data.label,
    valid: input.data.valid,
    errorCodes: input.data.errorCodes,
  };
};

export const equivalentNodes = (src: CanvasNode | AttributeNode, cmp: CanvasNode | AttributeNode): boolean => {
  return isEqual(createCompareNode(src), createCompareNode(cmp));
};

import { Edge, Node } from "reactflow";
import { toast } from "react-toastify";
import React from "react";
import { endNodeErrorMsg } from "../../fixtures/endNodes";
import { endNodes } from "../constants/endNodes";
import { DataProps, ExportCheckMessage } from "../../types/customTypes";

const isValidConnection = (sourceHandle: string, targetHandle: string) => {
  return (sourceHandle === "R" && targetHandle === "L") || (sourceHandle === "B" && targetHandle === "T");
};

const leftExistsRejectAll = (targetArray: Edge[]): boolean => {
  let result = false;
  targetArray.forEach((edge) => {
    if (edge.targetHandle === "L") {
      result = true;
    }
  });
  return result;
};
const topExistsRejectLeft = (targetArray: Edge[], targetHandle: string): boolean => {
  let result = false;
  targetArray.forEach((edge) => {
    if (edge.targetHandle === "T" && targetHandle === "L") {
      result = true;
    }
  });
  return result;
};

const disallowMultipleOutputs = (sourceArray: Edge[], sourceHandle: string): boolean => {
  let result = false;
  sourceArray.forEach((edge) => {
    if ((edge.sourceHandle === "B" && sourceHandle === "B") || (edge.sourceHandle === "R" && sourceHandle === "R")) {
      result = true;
    }
  });
  return result;
};
const nodeAndConnectionCheck = (
  edgesArray: Edge[],
  source: string,
  sourceHandle: string,
  target: string,
  targetHandle: string
): boolean => {
  const filteredTargetEdgeArray = edgesArray.filter((edge) => edge.target === target);
  const filteredSourceEdgeArray = edgesArray.filter((edge) => edge.source === source);
  if (filteredSourceEdgeArray.length === 0 && filteredTargetEdgeArray.length === 0) return true;
  if (leftExistsRejectAll(filteredTargetEdgeArray)) return false;
  if (topExistsRejectLeft(filteredTargetEdgeArray, targetHandle)) return false;
  if (disallowMultipleOutputs(filteredSourceEdgeArray, sourceHandle)) return false;
  return true;
};

export const validateNodeConnectionRules = (
  arrayOfEdges: Edge[],
  source: string,
  sourceHandle: string,
  target: string,
  targetHandle: string
): boolean => {
  return (
    nodeAndConnectionCheck(arrayOfEdges, source, sourceHandle, target, targetHandle) &&
    source !== target &&
    isValidConnection(sourceHandle, targetHandle)
  );
};

export const validateNodeTypeConnectionRules = (
  arrayOfNode: Node[],
  source: string,
  sourceHandle: string,
  target: string,
  targetHandle: string
): boolean => {
  const sourceType = arrayOfNode.filter((node) => node.id === source)[0].type;
  const targetType = arrayOfNode.filter((node) => node.id === target)[0].type;

  return conditionsOnlyHaveConditionSiblings(sourceType, sourceHandle, targetType, targetHandle);
};
const conditionsOnlyHaveConditionSiblings = (sourceType, sourceHandle, targetType, targetHandle): boolean => {
  if (
    sourceType === "conditionstep" &&
    targetType === "conditionstep" &&
    (targetHandle === "L" || targetHandle === "R")
  ) {
    return true;
  }
  if (
    (targetType !== "conditionstep" && sourceType !== "conditionstep") ||
    (targetHandle === "T" && sourceHandle === "B") ||
    (targetHandle === "B" && sourceHandle === "T")
  ) {
    return true;
  }
  toast.error(
    <div>
      <p>Condition must connect horizontally with another condition.</p>
    </div>
  );
  return false;
};

export const hasSiblings = (edges: Edge[], id: string): boolean => {
  const attachedEdge = edges.filter(
    (edge) =>
      (edge.source === id && edge.targetHandle === "R") ||
      (edge.target === id && edge.sourceHandle === "L") ||
      (edge.source === id && edge.targetHandle === "L") ||
      (edge.target === id && edge.sourceHandle === "R")
  );
  if (attachedEdge.length === 0) return false;
  return true;
};

export const hasConnectedEndNode = (nodes: Node[], edges: Edge[]): ExportCheckMessage => {
  const endingNodes = nodes.filter((node) => endNodes.includes(node.type as string));
  let result = { result: false, message: [`Missing ${endNodeErrorMsg(endNodes)} node`] };
  if (endingNodes.length > 0) {
    endingNodes.forEach((node) => {
      if (edges.filter((edge) => node.id === edge.source || node.id === edge.target).length > 0) {
        result = { result: true, message: [""] };
      }
    });
  }
  return result;
};

export const hasAllValidNodes = (nodes: Node<DataProps>[]): ExportCheckMessage => {
  if (nodes.find((node) => !node.data.valid)) return { result: false, message: ["Invalid Node Found"] };
  return { result: true, message: [""] };
};

export const isCompletedJourney = (nodes: Node[], edges: Edge[]): ExportCheckMessage => {
  const endNodeCheck = hasConnectedEndNode(nodes, edges);
  const validNodes = hasAllValidNodes(nodes);
  return {
    result: endNodeCheck.result && validNodes.result,
    message: [...endNodeCheck.message, ...validNodes.message],
  };
};

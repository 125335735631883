import React from "react";
import { JSONArray, ToolTipData } from "../types/customTypes";

export interface NodesBadgeProps {
  configArray: JSONArray;
  tooltipData: ToolTipData;
}

const NodeBadge = ({ configArray, tooltipData }: NodesBadgeProps): JSX.Element => {
  if (configArray.length === 0) return <div />;

  const getBadgeDetails = (): string => {
    return `${tooltipData.colour} ${tooltipData.badgePosition}`;
  };

  return (
    <div className={`${getBadgeDetails()} rounded-full w-6 flex justify-center items-center -mt-4  absolute`}>
      <p data-testid="config-array-badge">{configArray.length}</p>
    </div>
  );
};

export default NodeBadge;

import { StackNode, HashCanvas, WithNodes, NodeWithSubJourneyRef } from "../types";
import { TypeSubJourneyStep, JourneyStep, TypeJourneyStep, Node } from "../../openapi/api";
import { findChildren } from "./builder";
import { getNodesWithConvergence, getNodesWithSjName } from "../../common/helpers/nodes/index";

export const getConvergenceObj = (
  nodesWithConvergence: string[],
  nodesWithSjName: Record<string, string>
): Record<string, Node[]> => {
  const res: [string, []][] = nodesWithConvergence.map((nodeId) => {
    if (nodeId in nodesWithSjName) {
      return [nodesWithSjName[nodeId], []];
    }
    return [`sj-${nodeId}`, []];
  });
  return Object.fromEntries(res);
};

export const getSjNameObj = (nodesWithSjName: Record<string, string>) => {
  const sjNames: string[] = Object.values(nodesWithSjName);
  const sjNameHolder: Record<string, []> = sjNames.reduce((acc, sjName) => {
    return { ...acc, [sjName]: [] };
  }, {} as Record<string, []>);
  return sjNameHolder;
};

export const generateSjDictionary = (canvas: HashCanvas): Record<string, Node[]> => {
  const { nodes, edges } = canvas;
  const nodesWithSjName = getNodesWithSjName(nodes);
  const nodesWithConvergence = getNodesWithConvergence(edges);
  const convergenceObject = getConvergenceObj(nodesWithConvergence, nodesWithSjName);
  const sjNameObject = getSjNameObj(nodesWithSjName);
  const res = { ...convergenceObject, ...sjNameObject };
  return res;
};

export const sanitiseChildren = (children: StackNode[]): Node[] => children.map((child) => child.node);

export const buildJourney = (
  rootNode: StackNode,
  canvas: HashCanvas,
  sjDictionary: Record<string, Node[]>
): JourneyStep => {
  const stack: StackNode[] = [rootNode];
  while (stack.length) {
    const currentNode = stack.pop() as StackNode;
    const currentNodeChildren = findChildren(currentNode, canvas);
    if (currentNodeChildren.length) {
      const firstChild = currentNodeChildren[0];
      const sjNameCheck = (firstChild.node as NodeWithSubJourneyRef).sjName;
      const lookUpNodeId = sjNameCheck || `sj-${firstChild.id}`;
      const sjContent = sjDictionary[lookUpNodeId];
      const sanitisedChildren = sanitiseChildren(currentNodeChildren);
      if (sjContent) {
        if (sjContent.length === 0) {
          /* eslint no-param-reassign: "error" */
          sjContent.push(...sanitisedChildren);
          (currentNode.node as WithNodes).nodes = sanitisedChildren;
          stack.push(...currentNodeChildren);
        }
        (currentNode.node as WithNodes).nodes = [
          {
            type: TypeSubJourneyStep.SubJourney,
            name: lookUpNodeId,
          },
        ];
      } else {
        (currentNode.node as WithNodes).nodes = sanitisedChildren;
        stack.push(...currentNodeChildren);
      }
    }
    // TODO: Ideally incorporate this as part of sanitise node
    delete (currentNode.node as NodeWithSubJourneyRef).sjName;

    // TODO: Regenerate from OpenApi types, Regression test and rerelease without this hotfix.
    if (currentNode.node.type === "complete") {
      delete currentNode.node.branchReceipt;
      delete currentNode.node.customerReceipt;
    }
  }
  return rootNode.node as JourneyStep;
};

export const fastJourneyBuilder = (canvas: HashCanvas): JourneyStep => {
  const sjDictionary = generateSjDictionary(canvas);
  const startNode = canvas.nodes.find((n) => n.journeyNode.type === TypeJourneyStep.Journey);
  if (!startNode) {
    throw new Error("There is no start node");
  }
  const journey: JourneyStep = startNode.journeyNode as JourneyStep;
  const journeyStackNode: StackNode = { node: journey, id: startNode.id };
  const journeyNodeChildren = findChildren(journeyStackNode, canvas);
  if (!journeyNodeChildren) {
    return journey;
  }
  const journeyNodes = buildJourney(journeyStackNode, canvas, sjDictionary);
  if (Object.keys(sjDictionary).length > 0) {
    journey.subJourneys = { ...(journey.subJourneys || {}), ...sjDictionary };
  }
  return journeyNodes;
};

import React from "react";
import ReactTooltip from "react-tooltip";
import { Info } from "@mui/icons-material";

interface UserFriendlyTooltipProps {
  attribute: string;
  description: string;
  example: string | undefined;
}

const UserFriendlyTooltip = ({ attribute, description, example }: UserFriendlyTooltipProps): JSX.Element => {
  return (
    <div>
      <Info key={attribute} data-tip data-for={attribute} data-testid={`${attribute}-tooltip`} />
      <ReactTooltip className="w-1/5" id={attribute} effect="solid">
        <p>
          <strong>description</strong> - {description}
        </p>
        {example && (
          <p className="text-ellipsis overflow-hidden">
            <strong>example</strong> - {example}
          </p>
        )}
      </ReactTooltip>
    </div>
  );
};

export default UserFriendlyTooltip;

import React from "react";
import { TextareaAutosize } from "@mui/material";
import { AttributeNode, ElementChangeFunction } from "../types/customTypes";
import { commonNodeConfig } from "../common/constants/commonNodeConfigProperties";

import {
  getPlaceHolderText,
  getDisabledStatus,
  checkRequiredFields,
  componentText,
} from "../common/helpers/attributeBar";

interface AttributeBarTextAreaProps {
  handleOnChange: ElementChangeFunction;
  configKey: string;
  nodeData: AttributeNode;
  hasTopConnection: boolean;
}

const AttributeBarTextArea = ({ handleOnChange, configKey, nodeData, hasTopConnection }: AttributeBarTextAreaProps) => {
  const value = (nodeData.data.config[configKey] as string) || "";
  const { confRequired, confRequiredNotPresent } = checkRequiredFields(configKey, nodeData);
  const isDisabled = getDisabledStatus(configKey, hasTopConnection, value);
  const isConfigSjName = configKey === commonNodeConfig.sjName && value;
  const fieldInfoMessage = isConfigSjName ? componentText.missingConnection : componentText.requiredField;

  return (
    <label
      htmlFor={configKey}
      className={`${
        isDisabled && configKey !== commonNodeConfig.type ? "text-gray-400" : "text-gray-700"
      } block text-sm font-bold mb-2 capitalize relative`}
    >
      <p>
        {configKey}
        {confRequired && <span className="text-failure-red font-normal"> *</span>}
      </p>
      <TextareaAutosize
        data-testid={`textarea_${configKey}`}
        placeholder={getPlaceHolderText(configKey, hasTopConnection)}
        disabled={isDisabled}
        id={configKey}
        value={value}
        className={`${confRequiredNotPresent ? "border-failure-red border-2" : "border-gray-300"} ${
          isDisabled ? "bg-gray-100 cursor-not-allowed" : ""
        } bg-white focus:outline-none focus:shadow-outline border rounded py-2 px-2 block w-full max-h-64 appearance-none leading-normal text-xs font-normal`}
        onChange={(e) => {
          handleOnChange({
            value: e.target.value,
            key: configKey,
            nodeType: nodeData.data.config.type,
            commitToHistory: false,
          });
        }}
        onBlur={(e) => {
          handleOnChange({
            value: e.target.value,
            key: configKey,
            nodeType: nodeData.data.config.type,
            commitToHistory: true,
          });
        }}
      />
      {(confRequiredNotPresent || (isConfigSjName && !hasTopConnection)) && (
        <p className="text-xs text-failure-red font-normal absolute ">{fieldInfoMessage}</p>
      )}
    </label>
  );
};

export default AttributeBarTextArea;

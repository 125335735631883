import produce, { Draft } from "immer";
import { GroupJourneys } from "../helpers/apihelpers/types";
import { structuredClone } from "../utils";

export interface SubJourneysStoreState {
  [key: string]: GroupJourneys[];
}
export const initialSubJourneysListState: SubJourneysStoreState = {};

export enum SubJourneyListActions {
  UPDATE_SUB_JOURNEYS = "update_subJourneys",
}

export type ValidSubJourneysListActions = UpdateSubJourneys;

export interface UpdateSubJourneys {
  type: SubJourneyListActions.UPDATE_SUB_JOURNEYS;
  payload: { deviceId: string; subJourneyList: GroupJourneys[] };
}

export const subJourneysListReducer = (
  subJourneyListState: SubJourneysStoreState,
  action: ValidSubJourneysListActions
): SubJourneysStoreState => {
  const newState = produce(subJourneyListState, (draft): Draft<SubJourneysStoreState> => {
    switch (action.type) {
      case SubJourneyListActions.UPDATE_SUB_JOURNEYS: {
        draft[action.payload.deviceId] = structuredClone(action.payload.subJourneyList);
        return draft;
      }
      default:
        return draft;
    }
  });
  return newState;
};

import { difference, pullAll } from "../common/utils";

export const preferredOrder = (keys: string[], isBeginning = true): string[] => {
  const preDefinedOrder = ["given", "comparison", "expected"];
  const notIncludedElements = difference(preDefinedOrder, keys);
  pullAll(preDefinedOrder, notIncludedElements);
  pullAll(keys, preDefinedOrder);
  const orderedConditionAttribute = isBeginning ? [...preDefinedOrder, ...keys] : [...keys, ...preDefinedOrder];
  return orderedConditionAttribute;
};

import React, { useEffect, useState } from "react";
import { HiOutlineDownload } from "react-icons/hi";
import JourneyJsonEditor from "./JourneyJsonEditor";

interface DownloadJourneyProps {
  exportJourney: () => string;
}

const DownloadJourney = ({ exportJourney }: DownloadJourneyProps): JSX.Element => {
  const [textField, setTextField] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");

  useEffect(() => {
    setTextField(exportJourney());
  }, [exportJourney]);

  return (
    <div className="flex justify-center items-center h-full">
      <section className=" flex flex-col w-1/2 font-sans justify-center items-center">
        <div className="flex flex-wrap justify-center">
          <input
            type="text"
            name="fileName"
            className="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal"
            placeholder="Enter file name to save"
            value={fileName}
            onChange={(e) => setFileName(e.target.value)}
          />
        </div>
        <a
          href={`data:text/json;charset=utf-8,${encodeURIComponent(textField)}`}
          download={`${fileName || "data"}.json`}
          className="bg-pol-red p-2 btn disabled:bg-grey-500 text-white rounded-sm flex justify-center items-center mt-2"
        >
          <HiOutlineDownload className="mr-2" size={22} />
          Download as JSON
        </a>
      </section>

      <section className="flex justify-center w-1/2 mt-1">
        <JourneyJsonEditor title="export" textField={textField} handleOnChange={setTextField} />
      </section>
    </div>
  );
};

export default DownloadJourney;

import { EnablerWrapper, SelectorValue } from "../../types/customTypes";

export const ENABLER_CONFIG: EnablerWrapper = {
  customEnabler: {
    name: "",
    id: "",
    action: "",
    stepEvent: "",
    params: {},
    loadingMessage: "",
    retriable: false,
    errorMessage: "",
    cancelable: false,
  },
  tokeniser: {
    name: "tokeniser",
    id: "tokeniser",
    action: "tokenise",
    stepEvent: "onMatch",
    params: {
      barcode: "",
    },
    loadingMessage: "",
    retriable: false,
    errorMessage: "",
    cancelable: false,
  },
  RMProductLookup: {
    name: "RMProductLookup",
    id: "RMProductLookup",
    action: "getProduct_v2",
    stepEvent: "onMatch",
    params: {
      parsedBarcode: "",
      journeyType: "",
      isFeePayable: "",
    },
    loadingMessage: "",
    retriable: false,
    errorMessage: "",
    cancelable: false,
  },
  estimatedDeliveryDate: {
    name: "estimatedDeliveryDate",
    id: "estimatedDeliveryDate",
    action: "getestimatedDeliveryDate",
    stepEvent: "onMatch",
    params: {
      originPostalCode: "",
      packageAcceptanceDateTime: "",
      destinationCountryCode: "",
      destinationPostalCode: "",
    },
    loadingMessage: "",
    retriable: false,
    errorMessage: "",
    cancelable: false,
  },
  lastDespatch: {
    name: "despatch",
    id: "lastDespatch",
    action: "afterLastDespatch",
    stepEvent: "onMatch",
    params: {
      branchID: "",
      counterID: "",
      clerkID: "",
      carrierID: "",
    },
    loadingMessage: "",
    retriable: false,
    errorMessage: "",
    cancelable: false,
  },
  country: {
    id: "country",
    name: "country",
    action: "getCountry",
    stepEvent: "onMatch",
    params: {
      kahalaSupportedFilter: false,
    },
    loadingMessage: "",
    retriable: false,
    errorMessage: "",
    cancelable: false,
  },
  accountValidation: {
    name: "banking",
    id: "accountValidation",
    action: "AccountValidation",
    stepEvent: "onMatch",
    params: {
      accountNumber: "",
      sortCode: "",
    },
    loadingMessage: "",
    retriable: false,
    errorMessage: "",
    cancelable: false,
  },
  bankAccountValidation: {
    name: "bankAccountValidation",
    id: "bankAccountValidation",
    action: "bankAccountValidation",
    stepEvent: "onMatch",
    params: {
      accountNumber: "",
      sortCode: "",
    },
    loadingMessage: "",
    retriable: false,
    errorMessage: "",
    cancelable: false,
  },
  insurance: {
    name: "insurance",
    id: "insurance",
    action: "getInsurance",
    stepEvent: "onMatch",
    params: {
      age: "",
      smokingStatus: "",
    },
    loadingMessage: "",
    retriable: false,
    errorMessage: "",
    cancelable: false,
  },
};

export const ENABLERS_LIST: Array<SelectorValue> = [
  {
    label: "Custom Enabler",
    value: "customEnabler",
  },
  {
    label: "Tokeniser",
    value: "tokeniser",
  },
  {
    label: "Product",
    value: "RMProductLookup",
  },
  {
    label: "Estimate Delivery Date",
    value: "estimatedDeliveryDate",
  },
  {
    label: "despatch",
    value: "lastDespatch",
  },
  {
    label: "country",
    value: "country",
  },
  {
    label: "banking",
    value: "accountValidation",
  },
  {
    label: "bankAccountValidation",
    value: "bankAccountValidation",
  },
  {
    label: "insurance",
    value: "insurance",
  },
];

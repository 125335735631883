/* tslint:disable */
/* eslint-disable */
/**
 * SPM User Journey APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 6.0.0
 * Contact: tbc@postoffice.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Ends journey unsuccessfully and reset.
 * @export
 * @interface AbortStep
 */
export interface AbortStep {
  /**
   *
   * @type {TypeAbortStep}
   * @memberof AbortStep
   */
  type: TypeAbortStep;
  /**
   * Name of the sub-journey to start a new journey with.
   * @type {string}
   * @memberof AbortStep
   */
  returnTo?: string;
  /**
   * Values that should be preserved between journey steps. Key is dot-notation key and value is a template or simple value.
   * @type {{ [key: string]: string; }}
   * @memberof AbortStep
   */
  outputData?: { [key: string]: string };
  /**
   *
   * @type {Array<Enabler>}
   * @memberof AbortStep
   */
  enablers?: Array<Enabler>;
}
/**
 * Used to capture barcode from a device or entered by a person.
 * @export
 * @interface BarcodeStep
 */
export interface BarcodeStep {
  /**
   *
   * @type {TypeBarcodeStep}
   * @memberof BarcodeStep
   */
  type: TypeBarcodeStep;
  /**
   *
   * @type {string}
   * @memberof BarcodeStep
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof BarcodeStep
   */
  description?: string;
  /**
   * Setting for node\'s visual properties.
   * @type {BarcodeStepVisualProperties}
   * @memberof BarcodeStep
   */
  visual?: BarcodeStepVisualProperties;
  /**
   *
   * @type {Array<Node>}
   * @memberof BarcodeStep
   */
  nodes?: Array<Node> | null;
  /**
   *
   * @type {Array<Enabler>}
   * @memberof BarcodeStep
   */
  enablers?: Array<Enabler>;
  /**
   * Values that should be preserved between journey steps. Key is dot-notation key and value is a template or simple value.
   * @type {{ [key: string]: string; }}
   * @memberof BarcodeStep
   */
  outputData?: { [key: string]: string };
  /**
   * Used to ensure the data entered by a user is valid
   * @type {Array<ValidationField>}
   * @memberof BarcodeStep
   */
  inputValidation?: Array<ValidationField>;
}
/**
 *
 * @export
 * @interface BarcodeStepVisualProperties
 */
export interface BarcodeStepVisualProperties {
  /**
   * Determines whether this is last node of the page
   * @type {boolean}
   * @memberof BarcodeStepVisualProperties
   */
  transition?: boolean;
  /**
   * Visual variant for this step type.
   * @type {string}
   * @memberof BarcodeStepVisualProperties
   */
  view?: BarcodeStepVisualPropertiesViewEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum BarcodeStepVisualPropertiesViewEnum {
  Default = "default",
}

/**
 * Used to allow a user to choose between multiple possible options. It can be a list of buttons or a radio button group.
 * @export
 * @interface ChoiceStep
 */
export interface ChoiceStep {
  /**
   *
   * @type {TypeChoiceStep}
   * @memberof ChoiceStep
   */
  type: TypeChoiceStep;
  /**
   *
   * @type {string}
   * @memberof ChoiceStep
   */
  title: string;
  /**
   *
   * @type {ChoiceStepVisualProperties}
   * @memberof ChoiceStep
   */
  visual?: ChoiceStepVisualProperties;
  /**
   *
   * @type {Array<Node>}
   * @memberof ChoiceStep
   */
  nodes?: Array<Node> | null;
  /**
   *
   * @type {Array<Enabler>}
   * @memberof ChoiceStep
   */
  enablers?: Array<Enabler>;
  /**
   * Values that should be preserved between journey steps. Key is dot-notation key and value is a template or simple value.
   * @type {{ [key: string]: string; }}
   * @memberof ChoiceStep
   */
  outputData?: { [key: string]: string };
}
/**
 *
 * @export
 * @interface ChoiceStepVisualProperties
 */
export interface ChoiceStepVisualProperties {
  /**
   * Determines whether this is last node of the page
   * @type {boolean}
   * @memberof ChoiceStepVisualProperties
   */
  transition?: boolean;
  /**
   * Base64 image or URL to an image
   * @type {string}
   * @memberof ChoiceStepVisualProperties
   */
  imageData?: string;
  /**
   * Used to display badge on Item
   * @type {string}
   * @memberof ChoiceStepVisualProperties
   */
  badge?: string;
  /**
   * Used to allow a user to choose between multiple possible choice views
   * @type {string}
   * @memberof ChoiceStepVisualProperties
   */
  view?: ChoiceStepVisualPropertiesViewEnum;
  /**
   * Used to allow a user to choose choice size
   * @type {string}
   * @memberof ChoiceStepVisualProperties
   */
  size?: ChoiceStepVisualPropertiesSizeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum ChoiceStepVisualPropertiesViewEnum {
  Primary = "primary",
  PrimaryDisabled = "primary-disabled",
  Secondary = "secondary",
  SecondaryDisabled = "secondary-disabled",
  Tertiary = "tertiary",
  Item = "item",
  ItemSelected = "item-selected",
  ItemDisabled = "item-disabled",
  Link = "link",
}
/**
 * @export
 * @enum {string}
 */
export enum ChoiceStepVisualPropertiesSizeEnum {
  Extrasmall = "extrasmall",
  Small = "small",
  Medium = "medium",
  Large = "large",
}

/**
 * Data with which to generate a columns of list.
 * @export
 * @interface ColumnsData
 */
export interface ColumnsData {
  [key: string]: string | any;

  /**
   * Title of the column
   * @type {string}
   * @memberof ColumnsData
   */
  title: string;
  /**
   * Specifies which record\'s field is displayed in this column
   * @type {string}
   * @memberof ColumnsData
   */
  field: string;
  /**
   * Determines whether or not the field is sortable
   * @type {boolean}
   * @memberof ColumnsData
   */
  sortable?: boolean;
  /**
   *
   * @type {ColumnsDataVisual}
   * @memberof ColumnsData
   */
  visual?: ColumnsDataVisual;
}
/**
 *
 * @export
 * @interface ColumnsDataVisual
 */
export interface ColumnsDataVisual {
  /**
   * Visual variant for column type.
   * @type {string}
   * @memberof ColumnsDataVisual
   */
  view?: ColumnsDataVisualViewEnum;
  /**
   * Visual alignment for column.
   * @type {string}
   * @memberof ColumnsDataVisual
   */
  viewAlignment?: ColumnsDataVisualViewAlignmentEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum ColumnsDataVisualViewEnum {
  Text = "text",
  Counter = "counter",
  Checkbox = "checkbox",
  Delete = "delete",
  Input = "input",
}
/**
 * @export
 * @enum {string}
 */
export enum ColumnsDataVisualViewAlignmentEnum {
  Left = "left",
  Right = "right",
  Center = "center",
}

/**
 * Used to finish journey and map data collected in journey to basket and transactional data.
 * @export
 * @interface CompleteStep
 */
export interface CompleteStep {
  /**
   *
   * @type {TypeCompleteStep}
   * @memberof CompleteStep
   */
  type: TypeCompleteStep;
  /**
   * subjourney name passed to switch to specific screen.
   * @type {string}
   * @memberof CompleteStep
   */
  returnTo?: string;
  /**
   * Data passed to basket to correctly display information about items in the basket.
   * @type {{ [key: string]: string; }}
   * @memberof CompleteStep
   */
  basket: { [key: string]: string };
  /**
   * Data passed to transaction when it\'s sent to backend.
   * @type {{ [key: string]: string; }}
   * @memberof CompleteStep
   */
  transaction: { [key: string]: string };
  /**
   * Key name that holds basket item list.
   * @type {string}
   * @memberof CompleteStep
   */
  itemSelector?: string;
  /**
   * Customer receipt to print
   * @type {CompleteStepReceiptProperties}
   * @memberof CompleteStep
   */
  customerReceipt?: CompleteStepReceiptProperties;
  /**
   * Branch receipt to print
   * @type {CompleteStepReceiptProperties}
   * @memberof CompleteStep
   */
  branchReceipt?: CompleteStepReceiptProperties;
  /**
   *
   * @type {Array<Enabler>}
   * @memberof CompleteStep
   */
  enablers?: Array<Enabler>;
  /**
   * Values that should be preserved between journey steps. Key is dot-notation key and value is a template or simple value.
   * @type {{ [key: string]: string; }}
   * @memberof CompleteStep
   */
  outputData?: { [key: string]: string };
}
/**
 *
 * @export
 * @interface CompleteStepReceiptProperties
 */
export interface CompleteStepReceiptProperties {
  /**
   * Template Name
   * @type {string}
   * @memberof CompleteStepReceiptProperties
   */
  template: string;
  /**
   * Data passed to template to be rendered
   * @type {{ [key: string]: string; }}
   * @memberof CompleteStepReceiptProperties
   */
  context: { [key: string]: string };
  /**
   * Message to be displayed whilst printing
   * @type {string}
   * @memberof CompleteStepReceiptProperties
   */
  printingMessage: string;
  /**
   * Is printing optional
   * @type {boolean}
   * @memberof CompleteStepReceiptProperties
   */
  optional: boolean;
}
/**
 * Used to choose the next step based on input data. All the siblings of condition must be of type condition. Conditions are evaluated in a given order and first to match will allow progressing.
 * @export
 * @interface ConditionStep
 */
export interface ConditionStep {
  /**
   *
   * @type {TypeConditionStep}
   * @memberof ConditionStep
   */
  type: TypeConditionStep;
  /**
   *
   * @type {TypeConditionStepComparisons}
   * @memberof ConditionStep
   */
  comparison: TypeConditionStepComparisons;
  /**
   *
   * @type {string | Array<string>}
   * @memberof ConditionStep
   */
  expected: string | Array<string>;
  /**
   *
   * @type {string}
   * @memberof ConditionStep
   */
  given: string;
  /**
   *
   * @type {Array<Enabler>}
   * @memberof ConditionStep
   */
  enablers?: Array<Enabler>;
  /**
   *
   * @type {Array<Node>}
   * @memberof ConditionStep
   */
  nodes?: Array<Node> | null;
  /**
   * Values that should be preserved between journey steps. Key is dot-notation key and value is a template or simple value.
   * @type {{ [key: string]: string; }}
   * @memberof ConditionStep
   */
  outputData?: { [key: string]: string };
}
/**
 * Data with which to generate a list of countries.
 * @export
 * @interface CountryData
 */
export interface CountryData {
  [key: string]: string | any;

  /**
   * Name of the Country
   * @type {string}
   * @memberof CountryData
   */
  countryName: string;
  /**
   * iso2code of the Country
   * @type {string}
   * @memberof CountryData
   */
  iso2Code: string;
  /**
   * unique numeric code of the country
   * @type {string}
   * @memberof CountryData
   */
  countryCode?: string;
}
/**
 * Used to pick the country from country list.
 * @export
 * @interface CountryStep
 */
export interface CountryStep {
  /**
   *
   * @type {TypeCountryStep}
   * @memberof CountryStep
   */
  type: TypeCountryStep;
  /**
   *
   * @type {Array<CountryData>}
   * @memberof CountryStep
   */
  countryList?: Array<CountryData>;
  /**
   *
   * @type {CountryStepVisualProperties}
   * @memberof CountryStep
   */
  visual?: CountryStepVisualProperties;
  /**
   *
   * @type {Array<Node>}
   * @memberof CountryStep
   */
  nodes?: Array<Node> | null;
  /**
   *
   * @type {Array<Enabler>}
   * @memberof CountryStep
   */
  enablers?: Array<Enabler>;
  /**
   * Values that should be preserved between journey steps. Key is dot-notation key and value is a template or simple value.
   * @type {{ [key: string]: string; }}
   * @memberof CountryStep
   */
  outputData?: { [key: string]: string };
  /**
   * Used to ensure the data entered by a user is valid
   * @type {Array<ValidationField>}
   * @memberof CountryStep
   */
  inputValidation?: Array<ValidationField>;
  /**
   * Values that are used to pre-populate step with some initial data.
   * @type {{ [key: string]: string; }}
   * @memberof CountryStep
   */
  defaultValues?: { [key: string]: string };
}
/**
 *
 * @export
 * @interface CountryStepVisualProperties
 */
export interface CountryStepVisualProperties {
  /**
   * Determines whether this is last node of the page
   * @type {boolean}
   * @memberof CountryStepVisualProperties
   */
  transition?: boolean;
  /**
   * Country node\'s visual variants
   * @type {string}
   * @memberof CountryStepVisualProperties
   */
  view?: CountryStepVisualPropertiesViewEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum CountryStepVisualPropertiesViewEnum {
  Default = "default",
}

/**
 * Function that are triggered as part of journey step lifecycle.
 * @export
 * @interface Enabler
 */
export interface Enabler {
  /**
   * Identifier of the enabler that is used to store values in steps inputData.
   * @type {string}
   * @memberof Enabler
   */
  id: string;
  /**
   * Name of the known enabler.
   * @type {string}
   * @memberof Enabler
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Enabler
   */
  action: string;
  /**
   *
   * @type {string}
   * @memberof Enabler
   */
  stepEvent?: string;
  /**
   *
   * @type {object}
   * @memberof Enabler
   */
  params: object;
  /**
   *
   * @type {string}
   * @memberof Enabler
   */
  loadingMessage?: string;
  /**
   *
   * @type {string}
   * @memberof Enabler
   */
  errorMessage?: string;
  /**
   *
   * @type {string}
   * @memberof Enabler
   */
  cancelMessage?: string;
  /**
   *
   * @type {boolean}
   * @memberof Enabler
   */
  retriable?: boolean;
  /**
   * Is used to specify whether enabler has cancel capability
   * @type {boolean}
   * @memberof Enabler
   */
  cancelable?: boolean;
  /**
   *
   * @type {EnablerVisualProperties}
   * @memberof Enabler
   */
  visual?: EnablerVisualProperties;
}
/**
 *
 * @export
 * @interface EnablerVisualProperties
 */
export interface EnablerVisualProperties {
  /**
   * Name of the icon
   * @type {string}
   * @memberof EnablerVisualProperties
   */
  icon?: EnablerVisualPropertiesIconEnum;
  /**
   * Enabler modal box view size
   * @type {string}
   * @memberof EnablerVisualProperties
   */
  size?: EnablerVisualPropertiesSizeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum EnablerVisualPropertiesIconEnum {
  Success = "success",
  Failure = "failure",
  Alert = "alert",
  Loading = "loading",
  Print = "print",
}
/**
 * @export
 * @enum {string}
 */
export enum EnablerVisualPropertiesSizeEnum {
  Small = "small",
  Medium = "medium",
  Large = "large",
}

/**
 * Display text and does not expect any user input.
 * @export
 * @interface InfoStep
 */
export interface InfoStep {
  /**
   *
   * @type {TypeInfoStep}
   * @memberof InfoStep
   */
  type: TypeInfoStep;
  /**
   * Displayed as header text
   * @type {string}
   * @memberof InfoStep
   */
  title?: string;
  /**
   * Displayed as normal text
   * @type {string}
   * @memberof InfoStep
   */
  description?: string;
  /**
   *
   * @type {InfoStepVisualProperties}
   * @memberof InfoStep
   */
  visual?: InfoStepVisualProperties;
  /**
   * Data used to display list of key/value pairs. Only used with \"list\" view.
   * @type {string | Array<ListData>}
   * @memberof InfoStep
   */
  params?: string | Array<ListData>;
  /**
   *
   * @type {Array<Enabler>}
   * @memberof InfoStep
   */
  enablers?: Array<Enabler>;
}
/**
 *
 * @export
 * @interface InfoStepVisualProperties
 */
export interface InfoStepVisualProperties {
  /**
   * Determines whether this is last node of the page
   * @type {boolean}
   * @memberof InfoStepVisualProperties
   */
  transition?: boolean;
  /**
   * Visual variant for this step type.
   * @type {string}
   * @memberof InfoStepVisualProperties
   */
  view?: InfoStepVisualPropertiesViewEnum;
  /**
   * Notification view size
   * @type {string}
   * @memberof InfoStepVisualProperties
   */
  size?: InfoStepVisualPropertiesSizeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum InfoStepVisualPropertiesViewEnum {
  Default = "default",
  List = "list",
  DisabledInput = "disabled-input",
  InputList = "input-list",
  NotificationInfo = "notification-info",
  NotificationWarning = "notification-warning",
  NotificationError = "notification-error",
  NotificationSuccess = "notification-success",
}
/**
 * @export
 * @enum {string}
 */
export enum InfoStepVisualPropertiesSizeEnum {
  Small = "small",
  Medium = "medium",
  Large = "large",
}

/**
 * @type InputGroupNode
 * @export
 */
export type InputGroupNode = CountryStep | InfoStep | InputStep | OptionsStep;

/**
 * Used to display Grouped Input.
 * @export
 * @interface InputGroupStep
 */
export interface InputGroupStep {
  /**
   *
   * @type {TypeInputGroupStep}
   * @memberof InputGroupStep
   */
  type: TypeInputGroupStep;
  /**
   *
   * @type {string}
   * @memberof InputGroupStep
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof InputGroupStep
   */
  description?: string;
  /**
   *
   * @type {InputGroupStepVisualProperties}
   * @memberof InputGroupStep
   */
  visual?: InputGroupStepVisualProperties;
  /**
   *
   * @type {Array<InputGroupNode>}
   * @memberof InputGroupStep
   */
  groupNodes?: Array<InputGroupNode>;
  /**
   *
   * @type {Array<Node>}
   * @memberof InputGroupStep
   */
  nodes?: Array<Node> | null;
  /**
   *
   * @type {Array<Enabler>}
   * @memberof InputGroupStep
   */
  enablers?: Array<Enabler>;
  /**
   * Values that should be preserved between journey steps. Key is dot-notation key and value is a template or simple value.
   * @type {{ [key: string]: string; }}
   * @memberof InputGroupStep
   */
  outputData?: { [key: string]: string };
  /**
   * Used to ensure the data entered by a user is valid
   * @type {Array<ValidationField>}
   * @memberof InputGroupStep
   */
  inputValidation?: Array<ValidationField>;
}
/**
 *
 * @export
 * @interface InputGroupStepVisualProperties
 */
export interface InputGroupStepVisualProperties {
  /**
   * Determines whether this is last node of the page
   * @type {boolean}
   * @memberof InputGroupStepVisualProperties
   */
  transition?: boolean;
  /**
   * Node\'s visual variants.
   * @type {string}
   * @memberof InputGroupStepVisualProperties
   */
  view?: InputGroupStepVisualPropertiesViewEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum InputGroupStepVisualPropertiesViewEnum {
  Default = "default",
}

/**
 * Step for keyboard input. Masked or unmasked input.
 * @export
 * @interface InputStep
 */
export interface InputStep {
  /**
   *
   * @type {TypeInputStep}
   * @memberof InputStep
   */
  type: TypeInputStep;
  /**
   *
   * @type {string}
   * @memberof InputStep
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof InputStep
   */
  description?: string;
  /**
   *
   * @type {InputStepVisualProperties}
   * @memberof InputStep
   */
  visual?: InputStepVisualProperties;
  /**
   *
   * @type {TypeInputStepInputMasks}
   * @memberof InputStep
   */
  inputMask?: TypeInputStepInputMasks;
  /**
   *
   * @type {InputStepFormatProperties}
   * @memberof InputStep
   */
  format?: InputStepFormatProperties;
  /**
   * custom masking value
   * @type {string}
   * @memberof InputStep
   */
  customMask?: string;
  /**
   *
   * @type {UnitProperties}
   * @memberof InputStep
   */
  unit?: UnitProperties;
  /**
   *
   * @type {Array<Node>}
   * @memberof InputStep
   */
  nodes?: Array<Node> | null;
  /**
   *
   * @type {Array<Enabler>}
   * @memberof InputStep
   */
  enablers?: Array<Enabler>;
  /**
   * Values that should be preserved between journey steps. Key is dot-notation key and value is a template or simple value.
   * @type {{ [key: string]: string; }}
   * @memberof InputStep
   */
  outputData?: { [key: string]: string };
  /**
   * Used to ensure the data entered by a user is valid
   * @type {Array<ValidationField>}
   * @memberof InputStep
   */
  inputValidation?: Array<ValidationField>;
  /**
   * Values that are used to pre-populate step with some initial data.
   * @type {{ [key: string]: string; }}
   * @memberof InputStep
   */
  defaultValues?: { [key: string]: string };
}
/**
 *
 * @export
 * @interface InputStepFormatProperties
 */
export interface InputStepFormatProperties {
  /**
   * Determines the input text case format
   * @type {string}
   * @memberof InputStepFormatProperties
   */
  case?: InputStepFormatPropertiesCaseEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum InputStepFormatPropertiesCaseEnum {
  CustomMask = "customMask",
  LowerCase = "lowerCase",
  UpperCase = "upperCase",
}

/**
 *
 * @export
 * @interface InputStepVisualProperties
 */
export interface InputStepVisualProperties {
  /**
   * Determines whether this is last node of the page
   * @type {boolean}
   * @memberof InputStepVisualProperties
   */
  transition?: boolean;
  /**
   * Input node visual variants
   * @type {string}
   * @memberof InputStepVisualProperties
   */
  view?: InputStepVisualPropertiesViewEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum InputStepVisualPropertiesViewEnum {
  Default = "default",
}

/**
 * Error in product journey
 * @export
 * @interface JourneyError
 */
export interface JourneyError {
  /**
   * breadcrumb of subjourneys to the error the step
   * @type {string}
   * @memberof JourneyError
   */
  breadcrumb?: string;
  /**
   * name of the sub-journey(if any)
   * @type {string}
   * @memberof JourneyError
   */
  subJourney?: string;
  /**
   * node position in expanded product journey
   * @type {string}
   * @memberof JourneyError
   */
  position?: string;
  /**
   * node position in sub-journey(if any)
   * @type {string}
   * @memberof JourneyError
   */
  positionInSubJourney?: string;
  /**
   * error in product journey
   * @type {string}
   * @memberof JourneyError
   */
  error?: string;
  /**
   * sub-journey names which are potentially creating a loop. Grey set gives the name of the sub-journeys which need to be checked and fixed in order to avoid the loop creation.
   * @type {string}
   * @memberof JourneyError
   */
  loopGreySet?: string;
}
/**
 * Root node of the journey
 * @export
 * @interface JourneyStep
 */
export interface JourneyStep {
  /**
   *
   * @type {TypeJourneyStep}
   * @memberof JourneyStep
   */
  type: TypeJourneyStep;
  /**
   * Name of the journey
   * @type {string}
   * @memberof JourneyStep
   */
  title: string;
  /**
   * Highest journey engine version this journey works with
   * @type {string}
   * @memberof JourneyStep
   */
  engineVersion?: string;
  /**
   *
   * @type {{ [key: string]: Array<Node>; }}
   * @memberof JourneyStep
   */
  subJourneys?: { [key: string]: Array<Node> };
  /**
   *
   * @type {Array<Node>}
   * @memberof JourneyStep
   */
  nodes?: Array<Node> | null;
}
/**
 * Data objects to generate the list of items in given order.
 * @export
 * @interface ListData
 */
export interface ListData {
  [key: string]: string | any;

  /**
   * key of the datum
   * @type {string}
   * @memberof ListData
   */
  key: string;
  /**
   * Value of the datum
   * @type {string}
   * @memberof ListData
   */
  value: string;
}
/**
 *
 * @export
 * @interface ModelError
 */
export interface ModelError {
  /**
   *
   * @type {string}
   * @memberof ModelError
   */
  message: string;
}
/**
 * Used to capture multiple barcodes from a device or entered by a person.
 * @export
 * @interface MultiBarcodeStep
 */
export interface MultiBarcodeStep {
  /**
   *
   * @type {TypeMultiBarcodeStep}
   * @memberof MultiBarcodeStep
   */
  type: TypeMultiBarcodeStep;
  /**
   *
   * @type {string}
   * @memberof MultiBarcodeStep
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof MultiBarcodeStep
   */
  description?: string;
  /**
   * Setting for node\'s visual properties.
   * @type {MultiBarcodeStepVisualProperties}
   * @memberof MultiBarcodeStep
   */
  visual?: MultiBarcodeStepVisualProperties;
  /**
   *
   * @type {Array<Node>}
   * @memberof MultiBarcodeStep
   */
  nodes?: Array<Node> | null;
  /**
   *
   * @type {Array<Enabler>}
   * @memberof MultiBarcodeStep
   */
  enablers?: Array<Enabler>;
  /**
   * Values that should be preserved between journey steps. Key is dot-notation key and value is a template or simple value.
   * @type {{ [key: string]: string; }}
   * @memberof MultiBarcodeStep
   */
  outputData?: { [key: string]: string };
  /**
   * Used to ensure the data entered by a user is valid
   * @type {Array<ValidationField>}
   * @memberof MultiBarcodeStep
   */
  inputValidation?: Array<ValidationField>;
}
/**
 *
 * @export
 * @interface MultiBarcodeStepVisualProperties
 */
export interface MultiBarcodeStepVisualProperties {
  /**
   * Determines whether this is last node of the page
   * @type {boolean}
   * @memberof MultiBarcodeStepVisualProperties
   */
  transition?: boolean;
  /**
   * Visual variant for this step type.
   * @type {string}
   * @memberof MultiBarcodeStepVisualProperties
   */
  view?: MultiBarcodeStepVisualPropertiesViewEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum MultiBarcodeStepVisualPropertiesViewEnum {
  Default = "default",
}

/**
 * @type Node
 * @export
 */
export type Node =
  | AbortStep
  | BarcodeStep
  | ChoiceStep
  | CompleteStep
  | ConditionStep
  | CountryStep
  | InfoStep
  | InputGroupStep
  | InputStep
  | JourneyStep
  | MultiBarcodeStep
  | OptionsStep
  | PromptStep
  | SelectionTableStep
  | SubJourneyStep
  | UndoStep;

/**
 *
 * @export
 * @interface NodeWithChildren
 */
export interface NodeWithChildren {
  /**
   *
   * @type {Array<Node>}
   * @memberof NodeWithChildren
   */
  nodes?: Array<Node> | null;
}
/**
 *
 * @export
 * @interface NodeWithDefaultValues
 */
export interface NodeWithDefaultValues {
  /**
   * Values that are used to pre-populate step with some initial data.
   * @type {{ [key: string]: string; }}
   * @memberof NodeWithDefaultValues
   */
  defaultValues?: { [key: string]: string };
}
/**
 *
 * @export
 * @interface NodeWithEnablers
 */
export interface NodeWithEnablers {
  /**
   *
   * @type {Array<Enabler>}
   * @memberof NodeWithEnablers
   */
  enablers?: Array<Enabler>;
}
/**
 *
 * @export
 * @interface NodeWithOutputData
 */
export interface NodeWithOutputData {
  /**
   * Values that should be preserved between journey steps. Key is dot-notation key and value is a template or simple value.
   * @type {{ [key: string]: string; }}
   * @memberof NodeWithOutputData
   */
  outputData?: { [key: string]: string };
}
/**
 *
 * @export
 * @interface NodeWithValidations
 */
export interface NodeWithValidations {
  /**
   * Used to ensure the data entered by a user is valid
   * @type {Array<ValidationField>}
   * @memberof NodeWithValidations
   */
  inputValidation?: Array<ValidationField>;
}
/**
 * Data with which to generate a list of options.
 * @export
 * @interface OptionsData
 */
export interface OptionsData {
  [key: string]: string | any;

  /**
   * Title of the datum
   * @type {string}
   * @memberof OptionsData
   */
  title: string;
  /**
   * Description of the datum
   * @type {string}
   * @memberof OptionsData
   */
  description?: string;
  /**
   * Value of the datum
   * @type {string}
   * @memberof OptionsData
   */
  value: string;
  /**
   * Specifies whether the option should be disabled or not.
   * @type {boolean}
   * @memberof OptionsData
   */
  disabled?: boolean;
  /**
   * Badge value of the datum
   * @type {string}
   * @memberof OptionsData
   */
  badge?: string;
}
/**
 * Used to statically or dynamically generate a list of options. It allows user to select/choose one.
 * @export
 * @interface OptionsStep
 */
export interface OptionsStep {
  /**
   *
   * @type {TypeOptionsStep}
   * @memberof OptionsStep
   */
  type: TypeOptionsStep;
  /**
   *
   * @type {string}
   * @memberof OptionsStep
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof OptionsStep
   */
  description?: string;
  /**
   * Allow to remove selection from the previously selected option.
   * @type {boolean}
   * @memberof OptionsStep
   */
  nullifiable?: boolean;
  /**
   *
   * @type {string | Array<OptionsData>}
   * @memberof OptionsStep
   */
  data: string | Array<OptionsData>;
  /**
   *
   * @type {OptionsStepVisualProperties}
   * @memberof OptionsStep
   */
  visual?: OptionsStepVisualProperties;
  /**
   *
   * @type {Array<Node>}
   * @memberof OptionsStep
   */
  nodes?: Array<Node> | null;
  /**
   *
   * @type {Array<Enabler>}
   * @memberof OptionsStep
   */
  enablers?: Array<Enabler>;
  /**
   * Values that should be preserved between journey steps. Key is dot-notation key and value is a template or simple value.
   * @type {{ [key: string]: string; }}
   * @memberof OptionsStep
   */
  outputData?: { [key: string]: string };
  /**
   * Used to ensure the data entered by a user is valid
   * @type {Array<ValidationField>}
   * @memberof OptionsStep
   */
  inputValidation?: Array<ValidationField>;
}
/**
 *
 * @export
 * @interface OptionsStepVisualProperties
 */
export interface OptionsStepVisualProperties {
  /**
   * Determines whether this is last node of the page
   * @type {boolean}
   * @memberof OptionsStepVisualProperties
   */
  transition?: boolean;
  /**
   * Visual variant for this step type.
   * @type {string}
   * @memberof OptionsStepVisualProperties
   */
  view?: OptionsStepVisualPropertiesViewEnum;
  /**
   * Visual display for this step type.
   * @type {string}
   * @memberof OptionsStepVisualProperties
   */
  layout?: OptionsStepVisualPropertiesLayoutEnum;
  /**
   * Used to allow a user to choose option size
   * @type {string}
   * @memberof OptionsStepVisualProperties
   */
  size?: OptionsStepVisualPropertiesSizeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum OptionsStepVisualPropertiesViewEnum {
  Default = "default",
  Radio = "radio",
  Item = "item",
}
/**
 * @export
 * @enum {string}
 */
export enum OptionsStepVisualPropertiesLayoutEnum {
  Grid = "grid",
  List = "list",
}
/**
 * @export
 * @enum {string}
 */
export enum OptionsStepVisualPropertiesSizeEnum {
  Small = "small",
  Medium = "medium",
  Large = "large",
}

/**
 * List of actions the user is prompted about.
 * @export
 * @interface PromptActionList
 */
export interface PromptActionList {
  /**
   * Title of the action
   * @type {string}
   * @memberof PromptActionList
   */
  title: string;
  /**
   * Value of the selected action
   * @type {string}
   * @memberof PromptActionList
   */
  value: string;
  /**
   *
   * @type {PromptStepActionsVisualProperties}
   * @memberof PromptActionList
   */
  visual?: PromptStepActionsVisualProperties;
}
/**
 * Prompts the user for action
 * @export
 * @interface PromptStep
 */
export interface PromptStep {
  /**
   *
   * @type {TypePromptStep}
   * @memberof PromptStep
   */
  type: TypePromptStep;
  /**
   *
   * @type {string}
   * @memberof PromptStep
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof PromptStep
   */
  description: string;
  /**
   *
   * @type {PromptStepIcons}
   * @memberof PromptStep
   */
  promptIcon?: PromptStepIcons;
  /**
   *
   * @type {Array<PromptActionList>}
   * @memberof PromptStep
   */
  actions?: Array<PromptActionList>;
  /**
   *
   * @type {PromptStepVisualProperties}
   * @memberof PromptStep
   */
  visual?: PromptStepVisualProperties;
  /**
   *
   * @type {Array<Node>}
   * @memberof PromptStep
   */
  nodes?: Array<Node> | null;
  /**
   *
   * @type {Array<Enabler>}
   * @memberof PromptStep
   */
  enablers?: Array<Enabler>;
  /**
   * Values that should be preserved between journey steps. Key is dot-notation key and value is a template or simple value.
   * @type {{ [key: string]: string; }}
   * @memberof PromptStep
   */
  outputData?: { [key: string]: string };
}
/**
 *
 * @export
 * @interface PromptStepActionsVisualProperties
 */
export interface PromptStepActionsVisualProperties {
  /**
   * Visual variant for this step type.
   * @type {string}
   * @memberof PromptStepActionsVisualProperties
   */
  view?: PromptStepActionsVisualPropertiesViewEnum;
  /**
   * Action button size
   * @type {string}
   * @memberof PromptStepActionsVisualProperties
   */
  size?: PromptStepActionsVisualPropertiesSizeEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum PromptStepActionsVisualPropertiesViewEnum {
  Primary = "primary",
  Secondary = "secondary",
  Link = "link",
}
/**
 * @export
 * @enum {string}
 */
export enum PromptStepActionsVisualPropertiesSizeEnum {
  Extrasmall = "extrasmall",
  Small = "small",
  Medium = "medium",
  Large = "large",
}

/**
 *
 * @export
 * @enum {string}
 */

export enum PromptStepIcons {
  Success = "success",
  Failure = "failure",
  Alert = "alert",
  Loading = "loading",
  Print = "print",
}

/**
 *
 * @export
 * @interface PromptStepVisualProperties
 */
export interface PromptStepVisualProperties {
  /**
   * Determines whether this is last node of the page
   * @type {boolean}
   * @memberof PromptStepVisualProperties
   */
  transition?: boolean;
  /**
   * Prompt view width.
   * @type {string}
   * @memberof PromptStepVisualProperties
   */
  view?: PromptStepVisualPropertiesViewEnum;
  /**
   * Action button alignment
   * @type {string}
   * @memberof PromptStepVisualProperties
   */
  actionAlignment?: PromptStepVisualPropertiesActionAlignmentEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum PromptStepVisualPropertiesViewEnum {
  Small = "small",
  Medium = "medium",
  Large = "large",
}
/**
 * @export
 * @enum {string}
 */
export enum PromptStepVisualPropertiesActionAlignmentEnum {
  Left = "left",
  Right = "right",
  Center = "center",
}

/**
 *
 * @export
 * @interface SectionHeaderProperties
 */
export interface SectionHeaderProperties {
  /**
   * Title of section header
   * @type {string}
   * @memberof SectionHeaderProperties
   */
  title: string;
  /**
   * Top row values in header
   * @type {{ [key: string]: string; }}
   * @memberof SectionHeaderProperties
   */
  topRow?: { [key: string]: string };
  /**
   * Bottom row values in header
   * @type {{ [key: string]: string; }}
   * @memberof SectionHeaderProperties
   */
  bottomRow?: { [key: string]: string };
}
/**
 * Used to statically or dynamically generate a list. It allows user to select/choose.
 * @export
 * @interface SelectionTableStep
 */
export interface SelectionTableStep {
  /**
   *
   * @type {TypeSelectionTableStep}
   * @memberof SelectionTableStep
   */
  type: TypeSelectionTableStep;
  /**
   *
   * @type {string}
   * @memberof SelectionTableStep
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof SelectionTableStep
   */
  description?: string;
  /**
   *
   * @type {Array<ColumnsData>}
   * @memberof SelectionTableStep
   */
  columns: Array<ColumnsData>;
  /**
   *
   * @type {string | Array<any>}
   * @memberof SelectionTableStep
   */
  table: string | Array<any>;
  /**
   *
   * @type {SelectionTableStepVisualProperties}
   * @memberof SelectionTableStep
   */
  visual?: SelectionTableStepVisualProperties;
  /**
   *
   * @type {Array<Node>}
   * @memberof SelectionTableStep
   */
  nodes?: Array<Node> | null;
  /**
   *
   * @type {Array<Enabler>}
   * @memberof SelectionTableStep
   */
  enablers?: Array<Enabler>;
  /**
   * Values that should be preserved between journey steps. Key is dot-notation key and value is a template or simple value.
   * @type {{ [key: string]: string; }}
   * @memberof SelectionTableStep
   */
  outputData?: { [key: string]: string };
  /**
   * Used to ensure the data entered by a user is valid
   * @type {Array<ValidationField>}
   * @memberof SelectionTableStep
   */
  inputValidation?: Array<ValidationField>;
}
/**
 *
 * @export
 * @interface SelectionTableStepVisualProperties
 */
export interface SelectionTableStepVisualProperties {
  /**
   * Determines whether this is last node of the page
   * @type {boolean}
   * @memberof SelectionTableStepVisualProperties
   */
  transition?: boolean;
  /**
   * Visual variant for this step type.
   * @type {string}
   * @memberof SelectionTableStepVisualProperties
   */
  view?: SelectionTableStepVisualPropertiesViewEnum;
  /**
   * Header properties to describe the section
   * @type {SectionHeaderProperties}
   * @memberof SelectionTableStepVisualProperties
   */
  sectionHeader?: SectionHeaderProperties;
  /**
   * Message to display when there are no elements in the list
   * @type {string}
   * @memberof SelectionTableStepVisualProperties
   */
  emptyTableMessage?: string;
  /**
   * Message to display when there are no items in the list after filtering
   * @type {string}
   * @memberof SelectionTableStepVisualProperties
   */
  emptyFilterMessage?: string;
  /**
   * Determines whether step complete on button click or user interaction
   * @type {string}
   * @memberof SelectionTableStepVisualProperties
   */
  manualComplete?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum SelectionTableStepVisualPropertiesViewEnum {
  Default = "default",
  ReportTable = "reportTable",
}

/**
 * References re-usable list of Nodes by name from root node subJourneys.
 * @export
 * @interface SubJourneyStep
 */
export interface SubJourneyStep {
  /**
   *
   * @type {TypeSubJourneyStep}
   * @memberof SubJourneyStep
   */
  type: TypeSubJourneyStep;
  /**
   *
   * @type {string}
   * @memberof SubJourneyStep
   */
  name: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum TypeAbortStep {
  Abort = "abort",
}

/**
 *
 * @export
 * @enum {string}
 */

export enum TypeBarcodeStep {
  Barcode = "barcode",
}

/**
 *
 * @export
 * @enum {string}
 */

export enum TypeChoiceStep {
  Choice = "choice",
}

/**
 *
 * @export
 * @enum {string}
 */

export enum TypeCompleteStep {
  Complete = "complete",
}

/**
 *
 * @export
 * @enum {string}
 */

export enum TypeConditionStep {
  Condition = "condition",
}

/**
 *
 * @export
 * @enum {string}
 */

export enum TypeConditionStepComparisons {
  Eq = "eq",
  Lt = "lt",
  Gt = "gt",
  In = "in",
  Matchregexp = "matchregexp",
}

/**
 *
 * @export
 * @enum {string}
 */

export enum TypeCountryStep {
  Country = "country",
}

/**
 *
 * @export
 * @enum {string}
 */

export enum TypeInfoStep {
  Info = "info",
}

/**
 *
 * @export
 * @enum {string}
 */

export enum TypeInputGroupStep {
  InputGroup = "inputGroup",
}

/**
 *
 * @export
 * @enum {string}
 */

export enum TypeInputStep {
  Input = "input",
}

/**
 *
 * @export
 * @enum {string}
 */

export enum TypeInputStepInputMasks {
  Phone = "phone",
  Numeric = "numeric",
  Alpha = "alpha",
  Alphanumeric = "alphanumeric",
  Currency = "currency",
  Date = "date",
  ShortDate = "shortDate",
  Time = "time",
  Calendar = "calendar",
  DateOfBirth = "dateOfBirth",
  Custom = "custom",
}

/**
 *
 * @export
 * @enum {string}
 */

export enum TypeJourneyStep {
  Journey = "journey",
}

/**
 *
 * @export
 * @enum {string}
 */

export enum TypeMultiBarcodeStep {
  MultiBarcode = "multiBarcode",
}

/**
 *
 * @export
 * @enum {string}
 */

export enum TypeOptionsStep {
  Options = "options",
}

/**
 *
 * @export
 * @enum {string}
 */

export enum TypePromptStep {
  Prompt = "prompt",
}

/**
 *
 * @export
 * @enum {string}
 */

export enum TypeSelectionTableStep {
  SelectionTable = "selectionTable",
}

/**
 *
 * @export
 * @enum {string}
 */

export enum TypeSubJourneyStep {
  SubJourney = "subJourney",
}

/**
 *
 * @export
 * @enum {string}
 */

export enum TypeUndoStep {
  Undo = "undo",
}

/**
 * Undo the number of journey steps.
 * @export
 * @interface UndoStep
 */
export interface UndoStep {
  /**
   *
   * @type {TypeUndoStep}
   * @memberof UndoStep
   */
  type: TypeUndoStep;
  /**
   * Number of steps to undo.
   * @type {number}
   * @memberof UndoStep
   */
  numberOfSteps?: number;
  /**
   * Values that should be preserved between journey steps. Key is dot-notation key and value is a template or simple value.
   * @type {{ [key: string]: string; }}
   * @memberof UndoStep
   */
  outputData?: { [key: string]: string };
  /**
   *
   * @type {Array<Enabler>}
   * @memberof UndoStep
   */
  enablers?: Array<Enabler>;
}
/**
 *
 * @export
 * @interface UnitProperties
 */
export interface UnitProperties {
  /**
   * Determine text or image to render on TextInput
   * @type {string}
   * @memberof UnitProperties
   */
  type?: string;
  /**
   * Render currency symbol
   * @type {string}
   * @memberof UnitProperties
   */
  value?: string;
  /**
   * Determine the position of type/value horizontally left or right
   * @type {string}
   * @memberof UnitProperties
   */
  position?: string;
}
/**
 * Validation for given field
 * @export
 * @interface ValidationField
 */
export interface ValidationField {
  /**
   * Name of the step\'s field to validate
   * @type {string}
   * @memberof ValidationField
   */
  field: string;
  /**
   *
   * @type {ValidationFieldFormat}
   * @memberof ValidationField
   */
  format?: ValidationFieldFormat;
  /**
   *
   * @type {ValidationFieldNumericality}
   * @memberof ValidationField
   */
  numericality?: ValidationFieldNumericality;
  /**
   *
   * @type {ValidationFieldRequired}
   * @memberof ValidationField
   */
  required?: ValidationFieldRequired;
  /**
   *
   * @type {ValidationFieldUniqueness}
   * @memberof ValidationField
   */
  uniqueness?: ValidationFieldUniqueness;
  /**
   *
   * @type {ValidationFieldConfirmation}
   * @memberof ValidationField
   */
  confirmation?: ValidationFieldConfirmation;
}
/**
 *
 * @export
 * @interface ValidationFieldConfirmation
 */
export interface ValidationFieldConfirmation {
  /**
   * Message to display when value doesn\'t match field
   * @type {string}
   * @memberof ValidationFieldConfirmation
   */
  message: string;
  /**
   * Value to check matches field
   * @type {string}
   * @memberof ValidationFieldConfirmation
   */
  value: string;
}
/**
 *
 * @export
 * @interface ValidationFieldFormat
 */
export interface ValidationFieldFormat {
  /**
   * Message to display when field is not valid.
   * @type {string}
   * @memberof ValidationFieldFormat
   */
  message: string;
  /**
   * Regular expression field should match
   * @type {string}
   * @memberof ValidationFieldFormat
   */
  match?: string;
  /**
   * Regular expression field should not match
   * @type {string}
   * @memberof ValidationFieldFormat
   */
  notMatch?: string;
}
/**
 *
 * @export
 * @interface ValidationFieldNumericality
 */
export interface ValidationFieldNumericality {
  /**
   * Message to display when field is not valid.
   * @type {string}
   * @memberof ValidationFieldNumericality
   */
  message: string;
  /**
   * Field that should be greater than (“>“)
   * @type {string | number}
   * @memberof ValidationFieldNumericality
   */
  gt?: string | number;
  /**
   * Field that should be greater than (“>“) or equal to (“>=”)
   * @type {string | number}
   * @memberof ValidationFieldNumericality
   */
  gte?: string | number;
  /**
   * Field that should be less than (“<“)
   * @type {string | number}
   * @memberof ValidationFieldNumericality
   */
  lt?: string | number;
  /**
   * Field that should be less than (“<“) or equal to (“<=”)
   * @type {string | number}
   * @memberof ValidationFieldNumericality
   */
  lte?: string | number;
}
/**
 *
 * @export
 * @interface ValidationFieldRequired
 */
export interface ValidationFieldRequired {
  /**
   * Message to display when field is not present.
   * @type {string}
   * @memberof ValidationFieldRequired
   */
  message: string;
}
/**
 *
 * @export
 * @interface ValidationFieldUniqueness
 */
export interface ValidationFieldUniqueness {
  /**
   * Message to display when field is not unique.
   * @type {string}
   * @memberof ValidationFieldUniqueness
   */
  message: string;
  /**
   * JSONPath selector of values to check uniqueness against
   * @type {string}
   * @memberof ValidationFieldUniqueness
   */
  selector: string;
}
/**
 * Is used to describe how a node should be displayed.
 * @export
 * @interface Visual
 */
export interface Visual {
  /**
   *
   * @type {boolean}
   * @memberof Visual
   */
  transition?: boolean;
  /**
   * Base64 image or URL to an image
   * @type {string}
   * @memberof Visual
   */
  imageData?: string;
  /**
   *
   * @type {string}
   * @memberof Visual
   */
  view?: VisualViewEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum VisualViewEnum {
  List = "list",
}

import { Edge, Node } from "reactflow";
import { Canvas, DataProps, AppState, CanvasData } from "../../../types/customTypes";

export const selectCanvas = (selected: number | null, state: AppState): number | null => {
  if (selected !== null) return selected;
  return state.tabData.current;
};
export const getNodes = (state: AppState, canvas: number | null): Node<DataProps>[] => {
  const targetCanvas = selectCanvas(canvas, state);
  if (targetCanvas !== null) {
    if (state.tabData.tabs[targetCanvas]) {
      return state.tabData.tabs[targetCanvas].nodes;
    }
  }
  return [];
};
export const getEdges = (state: AppState, canvas: number | null): Edge[] => {
  const targetCanvas = selectCanvas(canvas, state);
  if (targetCanvas !== null) {
    if (state.tabData.tabs[targetCanvas]) {
      return state.tabData.tabs[targetCanvas].edges;
    }
  }
  return [];
};

export const getCanvasData = (state: AppState, canvas: number | null): CanvasData => {
  const targetCanvas = selectCanvas(canvas, state);
  if (targetCanvas !== null) {
    if (state.tabData.tabs[targetCanvas]) {
      return state.tabData.tabs[targetCanvas].canvasData;
    }
  }
  return { id: "", viewport: { x: 0, y: 0, zoom: 1 }, deviceID: "" };
};

export const getCanvas = (state: AppState, canvas: number | null = null): Canvas => {
  return {
    nodes: getNodes(state, canvas),
    edges: getEdges(state, canvas),
    canvasData: getCanvasData(state, canvas),
  };
};

import { OSMappedKey, getOS, Platform, PlatformKeyString, BaseKeyString } from "./platform";

export enum FlowAction {
  AREA_SELECT = "area_select",
  TOGGLE_SELECT = "toggle_select",
}
export type CanvasFlowActionKeyMap = {
  [key in FlowAction]: OSMappedKey;
};

const detectedOS = getOS() || Platform.WINDOWS;

enum ReactFlowKey {
  CONTROL = "Control",
  META = "Meta",
  SHIFT = "Shift",
  ALT = "Alt",
}

type BaseFlowKeyTranslation = {
  [Key in BaseKeyString]?: ReactFlowKey;
};

type PlatformFlowKeyTranslation = {
  [Key in PlatformKeyString]?: ReactFlowKey;
};

type FlowKeyTranslation = BaseFlowKeyTranslation & PlatformFlowKeyTranslation;

export const canvasFlowActionKeys: CanvasFlowActionKeyMap = {
  [FlowAction.AREA_SELECT]: {
    [Platform.OSX]: PlatformKeyString.OPT,
    [Platform.WINDOWS]: BaseKeyString.ALT,
    [Platform.LINUX]: BaseKeyString.ALT,
  },
  [FlowAction.TOGGLE_SELECT]: {
    [Platform.OSX]: PlatformKeyString.CMD,
    [Platform.WINDOWS]: BaseKeyString.CTRL,
    [Platform.LINUX]: BaseKeyString.CTRL,
  },
};
export const flowKey = (
  action: FlowAction,
  os = detectedOS,
  keyActions = canvasFlowActionKeys
): BaseKeyString | PlatformKeyString | null => {
  if (!keyActions[action] || os === null) return null;
  return keyActions[action][os] || null;
};

export const flowKeyTranslations: FlowKeyTranslation = {
  [BaseKeyString.CTRL]: ReactFlowKey.CONTROL,
  [PlatformKeyString.CMD]: ReactFlowKey.META,
  [PlatformKeyString.WINKEY]: ReactFlowKey.META,
  [PlatformKeyString.SUPER]: ReactFlowKey.META,
  [BaseKeyString.ALT]: ReactFlowKey.ALT,
  [PlatformKeyString.OPT]: ReactFlowKey.ALT,
};

export const mappedFlowKeyValue = (key: BaseKeyString | PlatformKeyString) => {
  return flowKeyTranslations[key] || key;
};

export const flowKeyName = (action: FlowAction, os = detectedOS, keyActions = canvasFlowActionKeys): string => {
  return flowKey(action, os, keyActions) || "";
};

export const flowKeyValue = (action: FlowAction, os = detectedOS, keyActions = canvasFlowActionKeys): string => {
  const fk = flowKey(action, os, keyActions);
  return fk ? mappedFlowKeyValue(fk) : "";
};

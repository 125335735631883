import { Edge, Node } from "reactflow";
import { v4 } from "uuid";
import { CacheStoreProps, DataProps } from "../../types/customTypes";
import { getNodeData } from "./nodeMapper";

interface IDInjectReturn {
  nodesWithNewIDs: Node<DataProps>[];
  edgesWithNewIDs: Edge[];
}

export const copyIdInject = (selectedElement: CacheStoreProps): IDInjectReturn => {
  const idDictionary: { [key: string]: string } = {};
  const { nodes, edges }: CacheStoreProps = selectedElement;
  const nodesWithNewIDs: Node<DataProps>[] = nodes.map((node) => {
    const newID = v4();
    idDictionary[`${node.id}`] = newID;
    const newNode: Node<DataProps> = getNodeData(newID, `${node.config.type}step`, node.position);

    return {
      ...newNode,
      data: { ...newNode.data, config: { ...newNode.data.config, ...node.config } },
    } as Node<DataProps>;
  });
  const edgesWithNewIDs: Edge[] = edges.map((edge) => {
    return { ...edge, source: idDictionary[`${edge.source}`], target: idDictionary[`${edge.target}`], id: v4() };
  });
  return { nodesWithNewIDs, edgesWithNewIDs };
};

import { getSteps } from "../openAPI";
import * as types from "../../../api/models/all";

const stepsTypesMapping = getSteps(types as never).reduce((acc, node) => {
  const key = node.slice(0, -4);
  return { ...acc, [key.toLowerCase()]: key };
}, {});

export const getNodeType = (nodeKey: string, mapperList = stepsTypesMapping): string => {
  const key = nodeKey.toLowerCase();
  if (Object.prototype.hasOwnProperty.call(mapperList, key)) {
    return mapperList[key] as string;
  }
  return nodeKey;
};

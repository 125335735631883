import { JsonObject } from "../../../types/json";

export const updateCache = (oldData: JsonObject, key: string, value: string | boolean) => {
  const cache = { ...oldData, [key]: value };
  if (value === false || value === "") {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [`${key}`]: string, ...newcache } = cache;
    return newcache;
  }
  return cache;
};

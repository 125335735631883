interface ModalData {
  modalTitle: string;
  modalMessage: string;
  modalPrimaryButtonTitle: string;
  modalSecondaryButtonTitle: string;
}

enum ModalType {
  RESTORE = "restore",
  DELETE_TAB = "deleteTab",
}

export const modalData: { [key in ModalType]: ModalData } = {
  restore: {
    modalTitle: "Restore tabs?",
    modalMessage: "Journey builder didn't shut down correctly",
    modalPrimaryButtonTitle: "Restore",
    modalSecondaryButtonTitle: "Delete",
  },

  deleteTab: {
    modalTitle: "Alert",
    modalMessage: "Are you sure you want to close this tab?",
    modalPrimaryButtonTitle: "Yes",
    modalSecondaryButtonTitle: "No",
  },
};

export const nonCloseModalTitles = Object.values(modalData).map((modalvals) => modalvals.modalTitle);

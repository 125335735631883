import { JNode } from "../../../types";
import { CanvasTools } from "../../../types/collections";
import { TypeSubJourneyStep } from "../../../openapi/api";
import { CanvasNode, TabToolProps, ButtonToolProps, SjDownloadResponseMessage } from "../../../types/customTypes";
import { maxTabs } from "../../constants/maxTabs";
import { JourneyDownloadTypes } from "../../constants/journeyDownloadTypes";
import { getJourney, authenticateDevice } from "../apihelpers";
import { ApiFetchStatus } from "../../constants/apiConst";

const enum SJBasketLocations {
  DEVICE_SUBJOURNIES = "deviceSubJourneys",
  DEFAULT_JOURNEYS = "defaultJourneys",
}

const enum SjDownloadMessages {
  SUCCESS = "Successfully downloaded",
  NAME_NOT_IN_CLOUD = "Cannot find journey name",
  INVALID_DEVICE_ID = "Invalid device ID",
  MAX_TABS = "Max number of tabs opened",
  NO_NAME_PRESENT = "No subJourney name present",
}

export const sjDownloadSuccessCase = (
  journeyResponse: JNode,
  tabTools: TabToolProps,
  buttonTools: ButtonToolProps,
  journeyName: string,
  setResponseMessage: React.Dispatch<React.SetStateAction<SjDownloadResponseMessage | null>>,
  openTabs: number,
  location: string
) => {
  tabTools.onAdd();
  buttonTools.importJourney(JSON.stringify(journeyResponse), JourneyDownloadTypes.CLOUD, openTabs);
  setResponseMessage({
    status: ApiFetchStatus.SUCCESS,
    message: SjDownloadMessages.SUCCESS,
    journeyName,
    location,
  });
};

export const sjDownloadFailCase = (
  message: string,
  setResponseMessage: React.Dispatch<React.SetStateAction<SjDownloadResponseMessage | null>>
) => {
  setResponseMessage({ status: ApiFetchStatus.FAIL, message });
};

export const sjDownloader = (
  node: CanvasNode,
  canvasTools: CanvasTools,
  buttonTools: ButtonToolProps,
  tabTools: TabToolProps,
  setResponseMessage: React.Dispatch<React.SetStateAction<SjDownloadResponseMessage | null>>
): void => {
  const journeyName = node.data.config.name as string;
  const { state, canvas } = canvasTools;
  const sjModelName = `${TypeSubJourneyStep.SubJourney.toLowerCase()}`;
  const isTypeSubJourney = node.data.config.type === sjModelName;
  const openTabs = state.tabData.tabs.length;
  const { deviceID } = canvas.canvasData;

  if (!isTypeSubJourney) return;
  if (isTypeSubJourney && !journeyName) {
    sjDownloadFailCase(SjDownloadMessages.NO_NAME_PRESENT, setResponseMessage);
    return;
  }
  if (openTabs >= maxTabs && journeyName) {
    sjDownloadFailCase(SjDownloadMessages.MAX_TABS, setResponseMessage);
    return;
  }
  authenticateDevice(deviceID)
    .then(() => {
      getJourney(journeyName, SJBasketLocations.DEVICE_SUBJOURNIES)
        .then((journeyResponse) => {
          sjDownloadSuccessCase(
            journeyResponse,
            tabTools,
            buttonTools,
            journeyName,
            setResponseMessage,
            openTabs,
            SJBasketLocations.DEVICE_SUBJOURNIES
          );
        })
        .catch(() => {
          getJourney(journeyName, SJBasketLocations.DEFAULT_JOURNEYS)
            .then((journeyResponse) => {
              sjDownloadSuccessCase(
                journeyResponse,
                tabTools,
                buttonTools,
                journeyName,
                setResponseMessage,
                openTabs,
                SJBasketLocations.DEFAULT_JOURNEYS
              );
            })
            .catch(() => {
              sjDownloadFailCase(`${SjDownloadMessages.NAME_NOT_IN_CLOUD} ${journeyName}`, setResponseMessage);
            });
        });
    })
    .catch(() => sjDownloadFailCase(SjDownloadMessages.INVALID_DEVICE_ID, setResponseMessage));
};

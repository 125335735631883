import { NON_INPUT_FIELDS, INTERCEPT_ENUMS_KEY, INTERCEPT_JSON_ARRAY } from "../../constants/AttributeInterceptKeys";
import * as typesModule from "../../../api/models/all";
import { FieldKeys, Format, JsonFieldKeys, Module } from "../../../types/customTypes";
import { hydrateInputTypes } from "./hydrateInputTypes";

export const getSteps = (types: Module): string[] => Object.keys(types).filter((key) => key.endsWith("Step"));

export const getNodeLibrary = (types: Module): string[] => Object.keys(types).map((type) => type.toLowerCase());

export const getFieldTypes = (type: string): FieldKeys => {
  const seperatedInputs = {
    jsonFieldKeys: [],
    enumFieldKeys: [],
    stringKeys: [],
    numberKeys: [],
    booleanKeys: [],
  } as FieldKeys;
  const typesLibrary = Object.entries(typesModule);
  const construct = typesLibrary.find((tuple) => type === tuple[0].toLowerCase());
  if (construct === undefined) return seperatedInputs;
  const data = construct[1].attributeTypeMap;

  data.forEach((format: Format) => {
    if (NON_INPUT_FIELDS.includes(format.name)) return;
    if (INTERCEPT_ENUMS_KEY.includes(format.name)) {
      seperatedInputs.enumFieldKeys.push({ displayWith: format.type, name: format.name });
      return;
    }
    if (INTERCEPT_JSON_ARRAY.includes(format.name)) {
      seperatedInputs.jsonFieldKeys.push({ displayWith: "jsonArray", name: format.name });
      return;
    }
    const { formatKey, inputType } = hydrateInputTypes(format);
    seperatedInputs[`${formatKey && String(formatKey)}` as keyof FieldKeys].push(inputType as JsonFieldKeys & string);
  });
  return seperatedInputs;
};

/**
 * SPM User Journey APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 7.2.0
 * Contact: tbc@postoffice.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { CompleteStepReceiptProperties } from './CompleteStepReceiptProperties';
import { TypeCompleteStep } from './TypeCompleteStep';
import { HttpFile } from '../http/http';

/**
* Used to finish journey and map data collected in journey to basket and transactional data.
*/
export class CompleteStep {
    'type': TypeCompleteStep;
    /**
    * subJourney name passed to switch to specific screen.
    */
    'returnTo'?: string;
    /**
    * Data passed to basket to correctly display information about items in the basket.
    */
    'basket': { [key: string]: string; };
    /**
    * Data passed to transaction when it's sent to backend.
    */
    'transaction': { [key: string]: string; };
    /**
    * Key name that holds basket item list.
    */
    'itemSelector'?: string;
    /**
    * Customer receipt to print
    */
    'customerReceipt'?: CompleteStepReceiptProperties;
    /**
    * Branch receipt to print
    */
    'branchReceipt'?: CompleteStepReceiptProperties;
    /**
    * Data passed to commit & fulfillment for processing of a fulfillment.
    */
    'fulfilment'?: { [key: string]: string; };

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "type",
            "baseName": "type",
            "type": "TypeCompleteStep",
            "format": ""
        },
        {
            "name": "returnTo",
            "baseName": "returnTo",
            "type": "string",
            "format": ""
        },
        {
            "name": "basket",
            "baseName": "basket",
            "type": "{ [key: string]: string; }",
            "format": ""
        },
        {
            "name": "transaction",
            "baseName": "transaction",
            "type": "{ [key: string]: string; }",
            "format": ""
        },
        {
            "name": "itemSelector",
            "baseName": "itemSelector",
            "type": "string",
            "format": ""
        },
        {
            "name": "customerReceipt",
            "baseName": "customerReceipt",
            "type": "CompleteStepReceiptProperties",
            "format": ""
        },
        {
            "name": "branchReceipt",
            "baseName": "branchReceipt",
            "type": "CompleteStepReceiptProperties",
            "format": ""
        },
        {
            "name": "fulfilment",
            "baseName": "fulfilment",
            "type": "{ [key: string]: string; }",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return CompleteStep.attributeTypeMap;
    }

    public constructor() {
    }
}

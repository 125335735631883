import React, { useState, useCallback } from "react";
import Backdrop from "./ModalBackdrop";
import ModalContent from "./ModalContent";
import ModalHeader from "./ModalHeader";

export interface ModalProps {
  close: () => void;
  modalChildren: Record<string, JSX.Element>;
  size: string;
  showHeader: boolean;
}

const Modal = ({ close, modalChildren, size, showHeader }: ModalProps): JSX.Element => {
  const tabTitles = Object.keys(modalChildren);
  const [selectedTab, setSelectedTab] = useState<string>(tabTitles[0]);

  const onEscKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === "Escape") close();
    },
    [close]
  );

  const onSelect = (event: Event) => {
    const target = event.target as HTMLButtonElement;
    setSelectedTab(target.textContent as string);
  };

  return (
    <Backdrop close={close} tabTitles={tabTitles}>
      <div
        data-testid="modal-holder"
        className={`w-${size} h-${size} flex flex-col relative bg-white`}
        onKeyPress={onEscKeyDown}
        role="button"
        tabIndex={0}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {showHeader && (
          <ModalHeader tabTitles={tabTitles} onSelect={onSelect} selectedTab={selectedTab} close={close} />
        )}
        <ModalContent modalChildren={modalChildren} selectedTab={selectedTab} />
      </div>
    </Backdrop>
  );
};

export default Modal;

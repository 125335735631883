import React from "react";

// eslint-disable-next-line react/require-default-props
export const CanvasKeyGuideItem = (props: { name?: string; value?: string }): JSX.Element => {
  const { name, value } = props;
  if (!(name || value)) return <div data-testid="empty-keyguide-item" />;
  return (
    <p>
      <>
        {name && <span className="ckgiName font-bold">{name} :</span>}
        {value && <span className="ckgiValue">{value}</span>}
      </>
    </p>
  );
};

import { toast } from "react-toastify";
import { Canvas, NodesAndEdges } from "../../../types/customTypes";
import { toastMessages } from "../../constants/toastMessages/UploadJourneyToast";

const getFile = async (): Promise<Blob | File> => {
  const [handle] = await window.showOpenFilePicker();
  return handle.getFile();
};

export const openCanvasFile = async (): Promise<NodesAndEdges> => {
  const file = await getFile();
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsText(file, "UTF-8");
    fileReader.onload = (event) => {
      if (event.target !== null && typeof event.target.result === "string") {
        const fileResult = JSON.parse(event.target.result) as Canvas;
        if (fileResult.edges === undefined) {
          toast.error(toastMessages.errorFile);
        } else {
          resolve({ nodes: fileResult.nodes, edges: fileResult.edges });
        }
      }
    };
    fileReader.onabort = () => {
      reject(new Error("on load failed"));
    };
  });
};

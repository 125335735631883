import React, { useState } from "react";
import { ElementChangeProps, AttributeNode } from "../types/customTypes";
import GroupNodeEditorModal from "./GroupNodeEditorModal";
import Modal from "./Modal";

interface Props {
  nodeData: AttributeNode;
  onChange: (change: ElementChangeProps) => void;
  jsonFieldName: string;
  hasTopConnection: boolean;
  deviceId?: string | null;
}

const GroupNodeEditor = ({
  nodeData,
  onChange,
  jsonFieldName,
  hasTopConnection,
  deviceId = null,
}: Props): JSX.Element => {
  const [groupNodesModal, setGroupNodesModal] = useState<boolean>(false);

  const close = (): void => {
    setGroupNodesModal(false);
  };
  return (
    <div className="w-full flex flex-col my-2">
      <h3 className="block text-gray-700 text-sm font-bold mb-2 capitalize">Group Nodes</h3>
      <button
        type="button"
        aria-label="button"
        title="customise group nodes"
        className="dragndrop-btn py-2 px-4 mx-auto"
        data-testid="groupnodes-modal-button"
        onClick={() => setGroupNodesModal(true)}
      >
        <p>Group Nodes</p>
      </button>
      {groupNodesModal && (
        <Modal
          showHeader={false}
          modalChildren={{
            groupNodes: (
              <GroupNodeEditorModal
                config={nodeData.data.config}
                onChange={onChange}
                jsonFieldName={jsonFieldName}
                close={close}
                hasTopConnection={hasTopConnection}
                deviceId={deviceId}
              />
            ),
          }}
          close={close}
          size="5/6"
        />
      )}
    </div>
  );
};

GroupNodeEditor.defaultProps = {
  deviceId: null,
};

export default GroupNodeEditor;

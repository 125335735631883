import React, { useState } from "react";
import { toast } from "react-toastify";
import { oldStyleImport } from "../files/import/journeyImport";
import { JourneyImportStatus } from "../types/customTypes";
import { inputChecker } from "../common/helpers/modals/inputChecker";
import FileUploader from "./FileUploader";
import JourneyJsonEditor from "./JourneyJsonEditor";
import ButonWithLoader from "./ButtonWithLoader";
import { ComponentText } from "../common/constants/componentText";

interface ModalProps {
  importJourney: (script: string, inputType: string) => void;
  close: () => void;
}

const UploadJourney = ({ importJourney, close }: ModalProps): JSX.Element => {
  const [files, setFiles] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [textField, setTextField] = useState<string>("");
  const { isValidInput, journey, inputType } = inputChecker(files, textField);
  const buttonTitle = isLoading ? ComponentText.IMPORTING : ComponentText.IMPORT;
  const isDisabled = !isValidInput || isLoading;

  const handleSubmit = () => {
    setIsLoading(true);
    setTimeout(() => {
      if (journey && inputType) {
        const { importStatus, responseMsg } = oldStyleImport(journey, inputType);
        if (importStatus === JourneyImportStatus.SUCCESS) {
          setIsLoading(false);
          toast.success(responseMsg);
          importJourney(journey, inputType);
          close();
        } else {
          setIsLoading(false);
          toast.error(responseMsg);
        }
      }
    });
  };

  return (
    <div className="flex flex-col items-center justify-center h-full">
      <div className="flex h-3/5 w-full">
        <FileUploader setFiles={setFiles} isLoading={isLoading} />
        <section className="flex items-center w-1/2 max-h-full mx-4">
          <JourneyJsonEditor title="import" textField={textField} handleOnChange={setTextField} />
        </section>
      </div>
      <div className="flex justify-center items-center mt-2">
        <ButonWithLoader
          buttonTitle={buttonTitle}
          handleOnClick={handleSubmit}
          isDisabled={isDisabled}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default UploadJourney;

import React, { useState, useEffect, useRef } from "react";
import { HiOutlineCheckCircle, HiOutlineXCircle } from "react-icons/hi";
import { JSONObject } from "../types/customTypes";
import { saveJourney } from "../common/helpers/apihelpers/index";
import MessageView from "./MessageView";
import { ModalVisibility } from "../common/constants/modals/modalVisibility";
import { ApiFetchStatus } from "../common/constants/apiConst";
import { ComponentText } from "../common/constants/componentText";
import { removeWhitespace } from "../common/helpers/string/removeWhitespace";
import ButtonWithLoader from "./ButtonWithLoader";
import { OpenPreViewLink } from "../files/types";

interface ModalProps {
  exportJourney: () => string;
  isDraftJourney: boolean;
  canvasIndex: number;
  addDeviceID: (deviceID: string) => void;
  existingDeviceID: string;
  setModalVisibility: React.Dispatch<React.SetStateAction<string>>;
  openPreviewLink: OpenPreViewLink;
}

const PublishJourney = ({
  exportJourney,
  isDraftJourney,
  canvasIndex,
  addDeviceID,
  existingDeviceID,
  setModalVisibility,
  openPreviewLink,
}: ModalProps): JSX.Element => {
  const journey = exportJourney();
  const journeyObj = JSON.parse(journey) as JSONObject;
  const { title } = journeyObj;
  const defaultTitle = removeWhitespace(`${title as string}`);
  const [fileName, setFileName] = useState<string>(defaultTitle);
  const [deviceID, setDeviceID] = useState<string>(existingDeviceID);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [apiResponseMessage, setApiResponseMessage] = useState<string>("");
  const [sentMessageStatus, setSentMessageStatus] = useState<string>("");
  const successMessageTemplate = isDraftJourney ? ComponentText.DRAFT_JOURNEY : ComponentText.JOURNEY;
  const buttonTitle = isDraftJourney ? ComponentText.PREVIEW : ComponentText.PUBLISH;
  const allInputsPresent = deviceID && fileName;
  const isDisabled = !allInputsPresent || isLoading;

  // TODO:Find the solution of double render which is impacted by strict mode.This is a temporary fix.
  const renderCount = useRef(1);

  useEffect(() => {
    if (isDraftJourney && defaultTitle && existingDeviceID.length > 1 && renderCount.current === 1) {
      renderCount.current += 1;
      onSavePress();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSavePress = () => {
    setIsLoading(true);
    addDeviceID(deviceID);
    saveJourney(deviceID, fileName, journey, isDraftJourney)
      .then(() => {
        setIsLoading(false);
        if (isDraftJourney) {
          openPreviewLink(deviceID, fileName, canvasIndex);
          setModalVisibility(ModalVisibility.OFF);
        }
        setApiResponseMessage(`Success: ${successMessageTemplate} saved`);
        setSentMessageStatus(ApiFetchStatus.SUCCESS);
      })
      .catch((err: string) => {
        setIsLoading(false);
        const journeyType = isDraftJourney ? "previewing" : "saving";
        const displayErr = err || `There is some issue while ${journeyType} journey. Please try again.`;
        setApiResponseMessage(`Error: ${displayErr}`);
        setSentMessageStatus(ApiFetchStatus.FAIL);
      });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  return (
    <form
      data-testid="publish-journey"
      className="w-full h-full flex flex-col justify-center items-center"
      onSubmit={handleSubmit}
    >
      <div className="flex justify-center mb-2 w-80">
        <label htmlFor="fileName">
          <p>
            <span className="text-pol-red">*</span> File Name
          </p>
          <input
            className="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 w-full appearance-none leading-normal"
            type="text"
            id="fileName"
            placeholder="Enter file name to save"
            value={fileName}
            onChange={(e) => setFileName(removeWhitespace(e.target.value))}
          />
        </label>
      </div>
      <div className="flex justify-center mb-2 w-80">
        <label htmlFor="deviceID">
          <p>
            <span className="text-pol-red">*</span> Device ID
          </p>
          <input
            className="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 w-full appearance-none leading-normal"
            type="text"
            id="deviceID"
            placeholder="Enter device ID"
            value={deviceID}
            onChange={(e) => setDeviceID(e.target.value)}
          />
        </label>
      </div>
      <div className="flex flex-col justify-center items-center mt-10">
        <ButtonWithLoader
          buttonTitle={buttonTitle}
          handleOnClick={onSavePress}
          isDisabled={isDisabled}
          isLoading={isLoading}
        />
        {apiResponseMessage && (
          <div
            className={`${
              sentMessageStatus === ApiFetchStatus.FAIL ? "text-failure-red" : "text-success-green"
            } flex p-2 ml-30`}
          >
            {sentMessageStatus === ApiFetchStatus.FAIL ? (
              <HiOutlineXCircle data-testid="fail-icon" size={25} />
            ) : (
              <HiOutlineCheckCircle data-testid="success-icon" size={25} />
            )}
            <MessageView message={apiResponseMessage} />
          </div>
        )}
      </div>
    </form>
  );
};

export default PublishJourney;

import {
  debounce as lodashDebounce,
  List,
  pullAll as lodashPullAll,
  difference as lodashDifference,
  cloneDeep,
  DebouncedFunc,
  isEqual as lodashIsEqual,
  isEqualWith as lodashIsEqualWith,
  IsEqualCustomizer,
} from "lodash";

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const debounce = <T extends (...args: any) => any>(func: T, wait: number | undefined): DebouncedFunc<T> => {
  return lodashDebounce(func, wait);
};

export const pullAll = <T>(arr1: T[], arr2: List<T>): T[] => {
  return lodashPullAll(arr1, arr2);
};
export const difference = <T>(arr: List<T>, values: List<T>): T[] => {
  return lodashDifference(arr, values);
};

export const structuredClone = <T>(value: T): T => {
  return cloneDeep(value);
};

// eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const isEqual = (value: any, other: any): boolean => {
  return lodashIsEqual(value, other);
};

// eslint-disable-next-line  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const isEqualWith = (value: any, other: any, customizer: any): boolean => {
  return lodashIsEqualWith(value, other, customizer as IsEqualCustomizer);
};

export default Object.freeze({
  debounce,
  pullAll,
  difference,
  structuredClone,
  isEqual,
  isEqualWith,
});

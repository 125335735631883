/**
 * SPM User Journey APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 7.2.0
 * Contact: tbc@postoffice.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class InlineResponse200 {
    'defaultJourneys'?: Array<string>;
    'defaultSubJourneys'?: Array<string>;
    'deviceJourneys'?: Array<string>;
    'deviceSubJourneys'?: Array<string>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "defaultJourneys",
            "baseName": "defaultJourneys",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "defaultSubJourneys",
            "baseName": "defaultSubJourneys",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "deviceJourneys",
            "baseName": "deviceJourneys",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "deviceSubJourneys",
            "baseName": "deviceSubJourneys",
            "type": "Array<string>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return InlineResponse200.attributeTypeMap;
    }

    public constructor() {
    }
}

import { HashCanvas } from "../../files/types";
import { sanitiseNode } from "../../files/export/nodeSanitiser";
import { structuredClone } from "../utils";

export const prepareCanvas = (canvas: HashCanvas): HashCanvas => {
  const detachedCanvas = structuredClone(canvas);
  const { nodes } = detachedCanvas;
  const sanitisedNodes = nodes.map((node) => {
    const { id, hashID } = node;
    const sanitisedNode = sanitiseNode(node.journeyNode);
    return { id, hashID, journeyNode: sanitisedNode };
  });
  detachedCanvas.nodes = sanitisedNodes;
  return detachedCanvas;
};

export function keyGenerator(index: number, ...args: (string | undefined)[]): string {
  let key = "";
  args.forEach((arg: string | undefined) => {
    if (arg != null) {
      key += `${arg}_`;
    }
  });
  key += `${index.toString()}`;
  return key.toLowerCase();
}

import { produce, Draft } from "immer";
import { AppState, ElementSet, CanvasWithHistory, CanvasHistory, Canvas } from "../../../types/customTypes";
import { isEqualWith, structuredClone } from "../../utils";

const historyMaxDepth = 50;

export type ProcessCanvas = {
  current: ElementSet;
  src: ElementSet[];
  dest: ElementSet[];
};

const ignoredCanvasKeys = ["selected", "dragging"];
const customComparison = (_: unknown, __: unknown, key: string): true | undefined => {
  return ignoredCanvasKeys.includes(key) ? true : undefined;
};

export const canvasWithHistory = (canvas: Canvas): CanvasWithHistory => {
  const detachedCanvas = structuredClone(canvas);
  const baseElements: ElementSet = {
    nodes: detachedCanvas.nodes,
    edges: detachedCanvas.edges,
  };
  const newCanvas: CanvasWithHistory = {
    ...detachedCanvas,
    history: {
      past: [],
      present: baseElements,
      future: [],
    },
  };
  return newCanvas;
};

// TODO: Find a way to satisfy TypeScript to allow this to be merged into canvasWithHistory (Canvas | Canvas[])
// Current limitation prevent it from being used with manual casting of the return value.
export const injectCanvasHistory = (input: Canvas[]): CanvasWithHistory[] => {
  return input.map((canvas) => canvasWithHistory(canvas));
};

export const shiftHistory = (canvas: ProcessCanvas): ProcessCanvas => {
  if (!canvas.src.length) return canvas;
  const srcElements = canvas.src[canvas.src.length - 1];
  if (!srcElements) return canvas;
  const stagedElements = {
    nodes: canvas.current.nodes,
    edges: canvas.current.edges,
  };

  return {
    src: canvas.src.slice(0, -1),
    dest: [...canvas.dest, stagedElements],
    current: {
      nodes: srcElements.nodes,
      edges: srcElements.edges,
    },
  };
};

export const historyUndo = (canvas: CanvasWithHistory): CanvasWithHistory => {
  const { history, nodes, edges } = canvas;
  const { future, past } = history;

  const shiftedHistory = shiftHistory({ current: { nodes, edges }, src: past, dest: future });
  const updatedHistory = {
    past: shiftedHistory.src,
    present: shiftedHistory.current,
    future: shiftedHistory.dest,
  };

  return {
    ...canvas,
    nodes: shiftedHistory.current.nodes,
    edges: shiftedHistory.current.edges,
    history: updatedHistory,
  };
};

export const historyRedo = (canvas: CanvasWithHistory): CanvasWithHistory => {
  const { history, nodes, edges } = canvas;
  const { future, past } = history;

  const shiftedHistory = shiftHistory({ current: { nodes, edges }, src: future, dest: past });
  const updatedHistory = {
    past: shiftedHistory.dest,
    present: shiftedHistory.current,
    future: shiftedHistory.src,
  };

  return {
    ...canvas,
    nodes: shiftedHistory.current.nodes,
    edges: shiftedHistory.current.edges,
    history: updatedHistory,
  };
};

export const hasValidHistory = (history: CanvasHistory | undefined): boolean => {
  if (history?.past && history?.future && history?.present?.nodes && history?.present?.edges) {
    return true;
  }
  return false;
};

export const polyfillHistory = (history: CanvasHistory | undefined): CanvasHistory => {
  if (hasValidHistory(history)) return history as CanvasHistory;

  if (history === undefined) {
    return {
      past: [],
      present: { nodes: [], edges: [] },
      future: [],
    };
  }

  const { past, present, future } = history;
  return {
    past: past ?? [],
    present: present ? { nodes: present.nodes ?? [], edges: present.edges ?? [] } : { nodes: [], edges: [] },
    future: future ?? [],
  };
};

export const recordHistory = (state: AppState, tabIndex: number | null, maxDepth = historyMaxDepth): AppState => {
  // TODO: 0 maxHistory size should also trim history;
  if (tabIndex === null || tabIndex >= state.tabData.tabs.length || maxDepth === 0) return state;
  const historyCorrectedState = produce(state, (draft): Draft<AppState> => {
    const { nodes, edges, history } = state.tabData.tabs[tabIndex];
    const current = { nodes, edges };

    const polyfilledHistory = polyfillHistory(history);
    const staged: ElementSet = {
      nodes: polyfilledHistory.present.nodes,
      edges: polyfilledHistory.present.edges,
    };

    draft.tabData.tabs[tabIndex].history = polyfilledHistory;

    if (!isEqualWith(current, staged, customComparison)) {
      draft.tabData.tabs[tabIndex].history.past.push({
        nodes: polyfilledHistory.present.nodes,
        edges: polyfilledHistory.present.edges,
      });
      draft.tabData.tabs[tabIndex].history.past.splice(0, polyfilledHistory.past.length - maxDepth + 1);
      draft.tabData.tabs[tabIndex].history.present = { nodes, edges };
      draft.tabData.tabs[tabIndex].history.future = [];
    }
    return draft;
  });
  return historyCorrectedState;
};

import { Edge } from "reactflow";
import { NodesAndEdges } from "../../../types/customTypes";

export const getConnectedEdges = (canvas: NodesAndEdges, includePeripheryEdges = true): Edge[] => {
  let filteredEdges: Edge[];
  if (includePeripheryEdges) {
    filteredEdges = canvas.edges.filter(
      (e) =>
        e.source !== e.target &&
        (canvas.nodes.some((n) => n.id === e.target) || canvas.nodes.some((n) => n.id === e.source))
    );
  } else {
    filteredEdges = canvas.edges.filter(
      (e) =>
        e.source !== e.target &&
        canvas.nodes.some((n) => n.id === e.target) &&
        canvas.nodes.some((n) => n.id === e.source)
    );
  }
  return filteredEdges;
};

export const removeOrphanedEdges = (canvas: NodesAndEdges): NodesAndEdges => {
  const filteredEdges = getConnectedEdges(canvas, false);
  return { ...canvas, edges: filteredEdges };
};

export const selectConnectedEdges = (canvas: NodesAndEdges): NodesAndEdges => {
  const filteredNodes = canvas.nodes.filter((n) => n.selected === true);
  const connectedEdges = getConnectedEdges({ nodes: filteredNodes, edges: canvas.edges }, true);
  const correctedEdges = canvas.edges.map((e) => {
    return { ...e, selected: connectedEdges.some((c) => c.id === e.id) };
  });
  return { nodes: canvas.nodes, edges: correctedEdges };
};

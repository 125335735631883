import { SortableItem } from "@thaddeusjiang/react-sortable-list";
import React from "react";
import { HiOutlineDotsVertical, HiOutlineTrash } from "react-icons/hi";
import { SortableItemPropsWithConfig } from "../types/customTypes";
import { structuredClone } from "../common/utils";

const DragHandler = (props) => (
  <div
    {...props}
    className=" flex  justify-center items-center h-8 w-8 rounded border m-1 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-blue-500 hover:text-white duration-300"
  >
    <div className="" title="drag handler">
      <HiOutlineDotsVertical />
    </div>
  </div>
);

interface Props {
  setSelected: React.Dispatch<React.SetStateAction<SortableItemPropsWithConfig | null>>;
  handleDelete: (id: string) => void;
  item: SortableItemPropsWithConfig;
  selected: SortableItemPropsWithConfig | null;
}

const GroupNodeSortableItem = ({ setSelected, handleDelete, item, selected }: Props): JSX.Element => {
  const config = structuredClone(item.config);
  const itemID: string = item.id;

  return (
    <div key={itemID} className="mx-2 my-1 px-2 py-1 relative" data-testid={`groupnodes-node-${config.type}`}>
      <button type="button" className="z-50 -mr-6 w-full h-full" onClick={() => setSelected(item)}>
        <SortableItem
          key={itemID}
          id={itemID}
          DragHandler={DragHandler}
          data-testid={`groupnodes-item-${itemID}`}
          className={`flex items-center w-full h-full bg-white py-1 px-2 ${
            selected && selected.id === itemID ? "border-2 border-green-400 shadow-md shadow-green-500/50" : ""
          }`}
        >
          <div className="flex justify-between items-center ml-5 w-1/2">
            <p>{config.type}</p>
            <p>{config.title}</p>
          </div>
          <HiOutlineTrash className="absolute right-0  mr-10 " />
        </SortableItem>
      </button>
      <button type="button" className="absolute z-10 h-full right-0 -mt-1 mr-10" onClick={() => handleDelete(itemID)}>
        <HiOutlineTrash className="text-transparent" />
      </button>
    </div>
  );
};

export default GroupNodeSortableItem;

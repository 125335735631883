import React, { useEffect, useState } from "react";
import Editor, { loader } from "@monaco-editor/react";
import {
  Fullscreen,
  FullscreenExit,
  FormatAlignJustify,
  FormatAlignLeft,
  ContentPaste,
  ContentCopy,
  Check,
} from "@mui/icons-material";
import { dataTypeChecker } from "../common/helpers/string/dataTypeChecker";
import { jsonFormatter } from "../common/helpers/string/jsonFormatting";
import { titleize } from "../common/helpers/string/titleize";
import { setClipboard, getClipboard } from "../common/helpers/clipboard";

loader.config({ paths: { vs: "../js/monaco-editor/min/vs" } });

interface JourneyJsonEditorProps {
  title: string;
  textField: string;
  handleOnChange: React.Dispatch<React.SetStateAction<string>>;
}

const JourneyJsonEditor = ({ title, textField, handleOnChange }: JourneyJsonEditorProps): JSX.Element => {
  const [jsonValue, setJsonValue] = useState<string>("");
  const [expand, setExpand] = useState<boolean>(false);
  const [copySuccess, setCopySuccess] = useState<boolean>(false);
  const [pasteSuccess, setPasteSuccess] = useState<boolean>(false);
  const [resetting, setResetting] = useState<boolean>(false);

  useEffect(() => {
    setJsonValue(textField);
  }, [textField]);
  useEffect(() => {
    setTimeout(() => {
      if (copySuccess) {
        setCopySuccess(false);
      }
    }, 300);
  }, [copySuccess]);
  useEffect(() => {
    setTimeout(() => {
      if (pasteSuccess) {
        setPasteSuccess(false);
      }
    }, 300);
  }, [pasteSuccess]);

  const formatData = () => {
    const checkedInput = dataTypeChecker(jsonValue);
    setJsonValue(jsonFormatter(checkedInput));
  };

  // TODO: Remove this workaround when monaco editor emits correct events.
  // Monaco Editor fails to emit events for delete all contents.
  // This causes paste to fail when pasting the same data twice with wipe in between as the effect sees the same data.
  const resetEditor = () => {
    setResetting(true);
  };

  useEffect(() => {
    if (resetting) {
      setResetting(false);
    }
  }, [resetting]);

  const handleChange = (change: string) => {
    setJsonValue(change);
    handleOnChange(change);
  };

  const unFormatData = () => {
    const checkedInput = dataTypeChecker(JSON.parse(jsonValue));
    setJsonValue(checkedInput);
  };

  const copyToClipboard = async () => {
    setCopySuccess(await setClipboard(jsonValue));
  };

  const pasteFromClipboard = async () => {
    const clipData = await getClipboard();
    if (clipData !== null) {
      setJsonValue(clipData);
      setPasteSuccess(true);
    }
    resetEditor(); // TODO: Remove this when possible. See comment above.
  };

  return (
    <div className="w-full h-full mr-2">
      <div
        data-testid="journey_json_editor_wrap"
        className={`flex flex-col border-2 border-black bg-purple-200
          ${expand ? "absolute -inset-x-1/4 -inset-y-1/4 z-20 pb-1" : "relative"}`}
      >
        <button
          aria-label="backdrop"
          type="button"
          className={`${
            expand ? "fixed bg-opacity-50" : "hidden bg-opacity-0"
          } w-screen h-screen bg-slate-400 top-0 left-0 `}
          onClick={() => setExpand(!expand)}
        />

        <div className="flex w-full z-0">
          <button
            className="flex justify-center items-center bg-pol-purple w-10 text-white border-double border-4 hover:bg-purple-900"
            type="button"
            title="Copy All"
            aria-label="copy-to-clipboard"
            onClick={() => copyToClipboard()}
          >
            {copySuccess ? <Check /> : <ContentCopy />}
          </button>
          <button
            className="flex justify-center items-center bg-pol-purple w-10 text-white border-double border-4 hover:bg-purple-900"
            type="button"
            title="Paste Replace"
            aria-label="paste-from-clipboard"
            onClick={() => pasteFromClipboard()}
          >
            {pasteSuccess ? <Check /> : <ContentPaste />}
          </button>
          <label
            htmlFor={title}
            className="flex capitalize text-white text-sm font-bold p-2 w-full justify-center bg-pol-purple truncate"
          >
            {titleize(title)}
          </label>
          <div className="flex mx-auto">
            <button
              aria-label="format"
              className="flex justify-center items-center  bg-pol-purple w-10 text-white border-double border-4 hover:bg-purple-900"
              type="button"
              onClick={formatData}
            >
              <FormatAlignLeft />
            </button>
            <button
              aria-label="un-format"
              className="flex justify-center items-center bg-pol-purple w-10 text-white border-double border-4 hover:bg-purple-900"
              type="button"
              onClick={unFormatData}
            >
              <FormatAlignJustify />
            </button>
            <button
              aria-label={expand ? "unexpand" : "expand"}
              className="flex justify-center items-center  bg-pol-purple w-10 text-white border-double border-4 hover:bg-purple-900"
              type="button"
              onClick={() => setExpand(!expand)}
            >
              {expand ? <FullscreenExit /> : <Fullscreen />}
            </button>
          </div>
        </div>

        <div className="h-full" data-testid="editor">
          <Editor
            defaultLanguage="json"
            className="bg-blue-200"
            height={`${expand ? "70vh" : "21vh"}`}
            width="100%"
            value={jsonValue}
            onChange={(e) => (e ? handleChange(e) : "")}
            options={{
              minimap: { enabled: false },
              wordWrap: "on",
              formatOnPaste: true,
              formatOnType: true,
              scrollbar: { alwaysConsumeMouseWheel: false },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default JourneyJsonEditor;

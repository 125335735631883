import { produce, Draft } from "immer";
import { ValidAttributeNodeActions, AttributeNodeActions } from "./actions";
import { AttributeNode } from "../../../types/customTypes";
import { amendErrorStore } from "../../helpers/attributes/jsonError";
import { structuredClone } from "../../utils";
import { validateNode } from "../../actions/nodeValidation";

export const initialAttributeNodeState: AttributeNode = {
  id: "",
  data: {
    label: "",
    valid: false,
    errorCodes: [],
    config: {
      type: "",
    },
    validationDatum: {
      requiredFields: [],
      nodeJsonErrors: {},
    },
  },
};

export const attributeNodeReducer = (state: AttributeNode, action: ValidAttributeNodeActions): AttributeNode => {
  const newState = produce(state, (draft): Draft<AttributeNode> => {
    switch (action.type) {
      case AttributeNodeActions.SWITCH_NODE: {
        const { preserveValidationData } = action.payload;
        const { id, data } = action.payload.inputNode;
        draft.id = id;
        if (preserveValidationData) {
          draft.data = { ...draft.data, ...data };
        } else {
          draft.data = { ...data, validationDatum: { ...initialAttributeNodeState.data.validationDatum } };
        }
        return draft;
      }

      case AttributeNodeActions.UPDATE_CONFIG_KEY: {
        draft.data.config[action.payload.key] = action.payload.value;
        return draft;
      }

      case AttributeNodeActions.AMEND_ERROR_STORE: {
        const { nodeJsonErrors } = state.data.validationDatum;
        const { key, jsonError } = action.payload;
        draft.data.validationDatum.nodeJsonErrors = amendErrorStore(nodeJsonErrors, key, jsonError);
        return draft;
      }

      case AttributeNodeActions.SET_REQUIRED_FIELDS: {
        draft.data.validationDatum.requiredFields = action.payload.requiredFields;
        return draft;
      }

      default: {
        return draft;
      }
    }
  });

  const validatedNewState = produce(newState, (draft): Draft<AttributeNode> => {
    const { nodeJsonErrors, requiredFields } = draft.data.validationDatum;
    const validStatus = validateNode(structuredClone(draft.data), requiredFields, nodeJsonErrors);
    draft.data.valid = validStatus.valid;
    draft.data.errorCodes = validStatus.errorCodes;
    return draft;
  });

  return validatedNewState;
};

import React from "react";
import { TypeOptions } from "react-toastify";
import * as monaco from "monaco-editor";
import { Edge as FlowEdge, Node as FlowNode, XYPosition, Viewport } from "reactflow";
import { Node } from "../openapi/api";
import { JsonObject, JsonArray, JsonValue } from "./json";
import { SearchErrorStatus } from "../components/searchComponent/types";

export type Edge = FlowEdge;
export type AttributeConfigProps = ConfigProps;
export type DataPropsWithId = DataProps & { id: string };
export type DataPropsWithIdAndPosition = DataPropsWithId & { position: XYPosition };

/*
 * TODO: import type from library - error with lirary is that eslint errors with use of any when is it recognises by TS as var typed
 * const selectedAttr: SortableItemPropsWithConfig
 * Invalid type "any" of template literal expression.eslint
 * Unsafe member access .id on an `any` value.eslint
 * */
type SortableItem = {
  id: string;
  DragHandler?: Element;
  className?: string;
};

export type SortableItemPropsWithConfig = SortableItem & { config: ConfigProps };

export type SortableItemWithAttNode = SortableItem & {
  attNode: AttributeNode;
};

export type AttributeNode = Pick<FlowNode<DataPropsWithValidation>, "id" | "data">;

export interface CacheStoreProps {
  nodes: DataPropsWithIdAndPosition[];
  edges: Edge[];
}

export type CanvasEdge = FlowEdge;
export type CanvasNode = FlowNode<DataProps>;

export interface SelectorNode {
  value: CanvasNode;
  label: string;
}

export interface EnablerWrapper {
  [k: string]: JSONObject;
}

export interface SelectorValue {
  value: string;
  label: string;
}

export interface ElementSet {
  nodes: CanvasNode[];
  edges: Edge[];
}

export type NodesAndEdges = ElementSet;

export type CanvasHistory = {
  past: ElementSet[];
  present: ElementSet;
  future: ElementSet[];
};

export type CanvasWithHistory = Canvas & {
  history: CanvasHistory;
};

export enum NodeValidationCode {
  REQUIRED = "required",
  JSON = "json",
  ENDNODE = "endnode",
  SIBLINGS = "siblings",
}

export interface DataProps {
  label: string;
  valid: boolean;
  errorCodes: NodeValidationCode[];
  viewOptions?: string[];
  config: ConfigProps;
  dataOptions?: string[];
  inputMaskOptions?: string[];
}

export type NodeValidationDatum = {
  requiredFields: string[];
  nodeJsonErrors: { [key: string]: monaco.editor.IMarker[] };
};

export type DataPropsWithValidation = DataProps & {
  validationDatum: NodeValidationDatum;
};

export interface MouseData {
  mouseClick: boolean;
  mouseButton: number;
  mousePosition: XYPosition;
}

export interface ToastReturn {
  message: string;
  status: TypeOptions;
}

export type Canvas = ElementSet & {
  canvasData: CanvasData;
};

export enum JourneyImportStatus {
  SUCCESS = "success",
  ERROR_TEXT_INPUT = "text input error",
  ERROR_FILE_INPUT = "file input error",
  ERROR_CLOUD_INPUT = "cloud input error",
  ERROR_DEFAULT = "default error",
}

export interface ImportStatus {
  importStatus: JourneyImportStatus;
  responseMsg: string;
  result: ElementSet;
  center: XYPosition;
}

export interface CanvasData {
  id: string;
  viewport: Viewport;
  deviceID: string;
  search?: SearchDetails | null;
}

export interface SearchMatch {
  matchedAgainst: string;
  elements: SelectorNode[];
  selected?: SelectorNode | null;
}

export interface SearchDetails {
  searchString: string;
  match: SearchMatch;
  error?: SearchErrorStatus | null;
}

export type AppState = {
  tabData: TabState;
};

export type TabState = {
  current: number | null;
  tabs: CanvasWithHistory[];
};

export interface ConfigProps {
  type: string;
  title?: string;
  view?: JSONObject;
  description?: string | undefined;
  params?: JSONArray;
  inputValidation?: JSONArray;
  nullifiable?: boolean;
  engineVersion?: string;
  outputData?: Record<string, unknown> | undefined;
  basket?: Record<string, unknown> | undefined;
  transaction?: Record<string, unknown> | undefined;
  defaultValues?: JSONObject;
  countryList?: JSONArray;
  unit?: JSONObject;
  customMask?: string;
  validations?: JSONArray;
  inputMask?: string;
  data?: JSONArray;
  returnTo?: string;
  expected?: string;
  comparison?: string;
  given?: string;
  groupNodes?: ConfigProps[];
  enablers?: JSONArray;
  actions?: JSONArray;
  itemSelector?: string;
  name?: string;
  visual?: Record<string, unknown>;
  table?: JSONArray;
  columns?: JSONArray;
  numberOfSteps?: number;
  imageData?: string;
  transition?: boolean;
  format?: JSONObject;
  calendarProps?: JSONObject;
  branchReceipt?: JSONObject;
  customerReceipt?: JSONObject;
  promptIcon?: string;
  nodes?: Node[];
  subJourneys?: JSONObject;
  sjName?: string;
}

export type ElementStoreProps = Canvas[];

export interface InputNodeForm {
  nodeType: string;
  jsonData: JSONValue | ConfigProps;
  jsonFieldName: string;
  immediatelyUpdate?: boolean;
  handleOnChange: ElementChangeFunction;
}

export enum UserFriendlyModes {
  BASIC = "basic",
  ADVANCED = "advanced",
}

export type JsonFieldKeys = {
  name: string;
  displayWith: string;
};
export type EnumFieldKeys = {
  name: string;
  displayWith: string;
};

export interface ButtonToolProps {
  saveCanvas: () => Promise<void>;
  clearCanvas: () => void;
  importJourney: (imported: string, importType: string, canvas?: number) => void;
  openCanvas: () => Promise<void>;
  exportJourney: () => string;
  generateModal: (e: Event) => void;
  setModalVisibility: React.Dispatch<React.SetStateAction<string>>;
  modalChildren: Record<string, JSX.Element>;
  isValidJourney: ExportCheckMessage;
  modalVisibility: string;
  centerPoint: XYPosition;
}

export interface FocusTools {
  focusedNode: CanvasNode | undefined;
  setFocusedNode: React.Dispatch<React.SetStateAction<CanvasNode | undefined>>;
}

export interface TabToolProps {
  onChange: (canvasIndex: number) => void;
  onAdd: () => void;
  onDelete: (canvasIndex: number) => void;
}

export interface YAMLData {
  components: {
    schemas: {
      [key: string]: YAMLContent;
    };
  };
}

export interface YAMLSchema {
  [key: string]: YAMLContent;
}

export interface YAMLContent {
  description: string;
  required: string[];
  properties?: { [key: string]: YAMLProperty };
  enum?: string[];
}
export interface YAMLNode {
  name: string;
  data: YAMLContent;
}

export interface YAMLProperty {
  description: string;
  type: string;
  enum?: string[];
  example?: string;
}

export type ToolTipDataMap = {
  [key in ToolTipConfigKey]: ToolTipData;
};
export type ToolTipConfigKey = "enablers" | "actions" | "groupNodes";

export interface ToolTipData {
  configKey: keyof ConfigProps;
  hoverKey: string;
  badgePosition: string;
  colour: string;
}

export interface KeyboardEvent {
  key: string;
}

export interface ExportCheckMessage {
  result: boolean;
  message: string[];
}

export type FieldKeys = {
  jsonFieldKeys: JsonFieldKeys[];
  enumFieldKeys: EnumFieldKeys[];
  stringKeys: string[];
  numberKeys: string[];
  booleanKeys: string[];
};

export type Format = {
  name: string;
  baseName: string;
  type: string;
  format: string;
};

export type Primitive = string | number | boolean | null;
export type JSONArray = JsonArray;
export type JSONObject = JsonObject;
export type JSONValue = JsonValue;

export type JSONEditorOutput = Primitive | JSONArray | JSONObject;

export interface SelectOption {
  label: string;
  value: string;
}

export interface KeyCodeMap {
  name: string;
  code: number;
}

export enum CanvasStep {
  PARENT = "parent",
  CHILD = "child",
  PREVIOUS_SIBLING = "prevSibling",
  NEXT_SIBLING = "nextSibling",
}
export enum SearchStep {
  PREVIOUS_RESULT = "prevResult",
  NEXT_RESULT = "nextResult",
}
export enum CanvasActionType {
  SEARCH = "search",
  STEP = "step",
}
export type CanvasKeyAction = {
  action: InputKey;
} & {
  [key in KeyModifier]?: boolean;
};

export enum InputKey {
  ArrowUp = "ArrowUp",
  ArrowDown = "ArrowDown",
  ArrowRight = "ArrowRight",
  ArrowLeft = "ArrowLeft",
  Enter = "Enter",
  Backspace = "Backspace",
  Delete = "Delete",
  A = "a",
  Z = "z",
  Y = "y",
}

export enum NodeHandle {
  TOP = "T",
  BOTTOM = "B",
  LEFT = "L",
  RIGHT = "R",
}

export enum EdgeConnectKey {
  SOURCE = "source",
  TARGET = "target",
}

export enum EdgeConnectHandle {
  SOURCE_HANDLE = "sourceHandle",
  TARGET_HANDLE = "targetHandle",
}

export interface NodeTraversal {
  sourceIDKey: EdgeConnectKey;
  sourceHandleKey: EdgeConnectHandle;
  sourceHandle: NodeHandle;
  targetHandleKey: EdgeConnectKey;
}

export interface NodeTraversalCollection {
  parent: NodeTraversal;
  child: NodeTraversal;
  nextSibling: NodeTraversal;
  prevSibling: NodeTraversal;
}
export interface SearchAction {
  type: CanvasActionType.SEARCH;
  action: SearchStep;
}
export interface StepAction {
  type: CanvasActionType.STEP;
  action: CanvasStep[] | null;
}

export type TraverseAction = StepAction | SearchAction;

export enum KeyModifier {
  ALT = "alt",
  SHIFT = "shift",
  CTRL = "ctrl",
  META = "meta",
}
export type ModifiedKeyActionMapping = {
  [key in KeyModifier]?: TraverseAction;
};
export type KeyActionMapping = ModifiedKeyActionMapping & {
  default: TraverseAction;
};

export type KeyActionMappings = Record<string, KeyActionMapping>;

export type Module = Record<string, () => void>;

export type TraversalTarget = {
  type: CanvasActionType;
  node: CanvasNode;
};

export interface SjDownloadResponseMessage {
  status: string;
  message: string;
  journeyName?: string;
  location?: string;
}

export type ElementChange = {
  value: string;
  key: string;
  nodeType: string;
  isJSONEditor?: boolean;
  commitToHistory?: boolean;
  jsonError?: monaco.editor.IMarker[];
};

export type ElementChangeProps = ElementChange;

export type ElementChangeFunction = (change: ElementChangeProps) => void;

export type UserFriendlyChangeProps = {
  key: string;
  value: string | boolean;
  immediateCommit?: boolean;
};

export type UserFriendlyChangeFunction = (change: UserFriendlyChangeProps) => void;

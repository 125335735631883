import React from "react";
import { Handle, Position } from "reactflow";

const DeprecatedNodeHandles = (): JSX.Element => {
  return (
    <div data-testid="depecated-node-handle-container">
      <Handle type="target" position={Position.Left} id="L" data-testid="deprecated-handle">
        <div className="visual-handle">
          <div />
        </div>
      </Handle>
      <Handle type="source" position={Position.Right} id="R" data-testid="deprecated-handle">
        <div className="visual-handle">
          <div />
        </div>
      </Handle>
      <Handle type="target" position={Position.Top} id="T" data-testid="deprecated-handle">
        <div className="visual-handle">
          <div />
        </div>
      </Handle>
      <Handle type="source" position={Position.Bottom} id="B" data-testid="deprecated-handle">
        <div className="visual-handle">
          <div />
        </div>
      </Handle>
    </div>
  );
};

export default DeprecatedNodeHandles;

import { ENABLER_CONFIG } from "../../constants/EnablersMapping";
import { JSONObject } from "../../../types/customTypes";

export const changeJsonData = (actionType: string, value: string, data: JSONObject[]): JSONObject[] => {
  let updatedJson = [...data];
  if (updatedJson) {
    if (actionType === "select-option") {
      const selectedValue = value && ENABLER_CONFIG[value];
      if (selectedValue) {
        updatedJson = [...updatedJson, selectedValue];
      }
    } else if (actionType === "remove-value") {
      const idKey = "id";
      const removeValue = value === "customEnabler" ? "" : value;
      const foundIndex = updatedJson.findIndex((item) => item && item[idKey] === removeValue);
      if (foundIndex !== -1) {
        updatedJson = [...updatedJson.slice(0, foundIndex), ...updatedJson.slice(foundIndex + 1)];
      }
    }
  }
  return updatedJson;
};

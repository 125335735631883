import { Primitive } from "../../../types/customTypes";

export const isValidJsonObject = (input: Primitive): boolean => {
  try {
    if (input) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const result = JSON.parse(String(input));
      if (result && typeof result === "object") return true;
    }
  } catch (error) {
    return false;
  }
  return false;
};

import { useState, useEffect } from "react";
import { Auth, Hub } from "aws-amplify";

export const useUserStatus = (): boolean => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    const updateUser = () => {
      Auth.currentAuthenticatedUser()
        .then(() => {
          setIsLoggedIn(true);
        })
        .catch(() => setIsLoggedIn(false));
    };
    updateUser();
    Hub.listen("auth", updateUser);
    return () => Hub.remove("auth", updateUser);
  }, [isLoggedIn]);
  return isLoggedIn;
};

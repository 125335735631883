import { useEffect, useState } from "react";
import { CanvasKeyAction, InputKey } from "../../types/customTypes";

type KeyboardEventWithTargetLocalName = KeyboardEvent & {
  target: {
    localName: string;
  };
};
export function useCanvasNavigation(): [CanvasKeyAction | null] {
  const [canvasAction, setCanvasAction] = useState<CanvasKeyAction | null>(null);

  const trackedKeys: InputKey[] = [
    InputKey.ArrowDown,
    InputKey.ArrowLeft,
    InputKey.ArrowRight,
    InputKey.ArrowUp,
    InputKey.Enter,
  ];

  const matchKey = (key: string, keyList: InputKey[]): string | undefined => {
    if (!key) return undefined;
    return keyList.find((n) => n === key);
  };

  // TODO: Find a way to satisfy TS without having this unknown-typed proxy method.
  const isValidPath = (ev: unknown): boolean => {
    const e = ev as KeyboardEventWithTargetLocalName;
    if (e.target.localName === "body") {
      return true;
    }
    return false;
  };

  const keyPresshandler = (ev: KeyboardEvent) => {
    if (!isValidPath(ev)) return;
    const matchedKey = matchKey(ev.key, trackedKeys);
    if (!matchedKey) return;
    const { shiftKey, ctrlKey, altKey } = ev;
    setCanvasAction({ action: matchedKey as InputKey, shift: shiftKey, ctrl: ctrlKey, alt: altKey });
  };

  useEffect(() => {
    if (canvasAction !== null) {
      setCanvasAction(null);
    }
  }, [canvasAction]);

  useEffect(() => {
    window.addEventListener("keydown", keyPresshandler);
    return () => {
      window.removeEventListener("keydown", keyPresshandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [canvasAction];
}

import { CacheStoreProps } from "../../../types/customTypes";

export enum CacheNodeActions {
  COPY_NODE = "copy_node",
}

export interface CopyNode {
  type: CacheNodeActions.COPY_NODE;
  payload: { selectedElement: CacheStoreProps };
}

export type CopyNodeActions = CopyNode;

import React from "react";
import { ComponentText } from "../common/constants/componentText";

const HeaderWarning = (): JSX.Element => {
  return (
    <div className="bg-yellow-200 p-2 text-center text-sm">
      <h1>
        <strong>{ComponentText.PLEASE_NOTE}</strong>
        {ComponentText.VERIFICATION_CODE_MSG}
      </h1>
    </div>
  );
};

export default HeaderWarning;

(window as unknown as { _env: Record<string, string> })._env ??= {};

const environment =
  process.env.NODE_ENV === "test" || process.env.NODE_ENV === "development"
    ? process.env
    : (window as unknown as { _env: Record<string, string> })._env;

export default {
  Auth: {
    region: environment.REACT_APP_REGION,
    userPoolId: environment.REACT_APP_USERPOOLID,
    userPoolWebClientId: environment.REACT_APP_WEBCLIENTID,
  },
  Analytics: {
    disabled: true,
  },
};

import { SanitiseRule, SanitiseAction, SanitiseTrigger } from "../files/types";
import { TypeInputStep, TypePromptStep } from "../openapi/api";

export const sanitiseRules: SanitiseRule[] = [
  {
    nodeType: TypeInputStep.Input,
    key: "inputMask",
    action: SanitiseAction.REMOVE,
    trigger: SanitiseTrigger.EMPTY_STRING_OR_UNDEFINED,
  },
  {
    nodeType: TypePromptStep.Prompt,
    key: "promptIcon",
    action: SanitiseAction.REMOVE,
    trigger: SanitiseTrigger.EMPTY_STRING_OR_UNDEFINED,
  },
];

/**
 * SPM User Journey APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 7.2.0
 * Contact: tbc@postoffice.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { StepVisualTransitionProperty } from './StepVisualTransitionProperty';
import { HttpFile } from '../http/http';

export class InputStepVisualProperties {
    'transition'?: StepVisualTransitionProperty;
    /**
    * Input node visual variants
    */
    'view'?: InputStepVisualPropertiesViewEnum;
    /**
    * Specifies whether input is editable
    */
    'editable'?: boolean;
    /**
    * Input node size variants
    */
    'size'?: InputStepVisualPropertiesSizeEnum;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "transition",
            "baseName": "transition",
            "type": "StepVisualTransitionProperty",
            "format": ""
        },
        {
            "name": "view",
            "baseName": "view",
            "type": "InputStepVisualPropertiesViewEnum",
            "format": ""
        },
        {
            "name": "editable",
            "baseName": "editable",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "size",
            "baseName": "size",
            "type": "InputStepVisualPropertiesSizeEnum",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return InputStepVisualProperties.attributeTypeMap;
    }

    public constructor() {
    }
}


export type InputStepVisualPropertiesViewEnum = "default" ;
export type InputStepVisualPropertiesSizeEnum = "small" | "medium" | "large" | "x_large" ;

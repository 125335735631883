export enum BaseKeyString {
  ALT = "alt",
  META = "meta",
  SHIFT = "shift",
  CTRL = "ctrl",
}
export enum PlatformKeyString {
  OPT = "opt",
  CMD = "cmd",
  SUPER = "super",
  WINKEY = "winkey",
}

export enum Platform {
  OSX = "osx",
  WINDOWS = "windows",
  LINUX = "linux",
}

export type OSMappedKey = {
  [key in Platform]?: BaseKeyString | PlatformKeyString;
};

type KeyMap = {
  [key in BaseKeyString]?: OSMappedKey;
};
const keyMap: KeyMap = {
  alt: {
    [Platform.OSX]: PlatformKeyString.OPT,
    [Platform.WINDOWS]: BaseKeyString.ALT,
    [Platform.LINUX]: BaseKeyString.ALT,
  },
  meta: {
    [Platform.OSX]: PlatformKeyString.CMD,
    [Platform.WINDOWS]: PlatformKeyString.WINKEY,
    [Platform.LINUX]: PlatformKeyString.SUPER,
  },
};

const browserPlatform = window.navigator.platform;

export const getOS = (osString = browserPlatform): Platform | null => {
  const lOS = osString.toLocaleLowerCase();
  let os: Platform | null = null;
  if (lOS.indexOf("win") >= 0) {
    os = Platform.WINDOWS;
  } else if (lOS.indexOf("mac") >= 0 || lOS.indexOf("iphone") >= 0) {
    os = Platform.OSX;
  } else if (lOS.indexOf("linux") >= 0) {
    os = Platform.LINUX;
  }
  return os;
};

export const osKey = (key: string, operatingSystem = browserPlatform): string => {
  const lKey = key.toLowerCase();
  if (!keyMap[lKey]) return key;
  const os = getOS(operatingSystem) || Platform.WINDOWS;
  if (Object.prototype.hasOwnProperty.call(keyMap[lKey], os)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return keyMap[lKey][os] as string;
  }
  return key;
};

/**
 * SPM User Journey APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 7.2.0
 * Contact: tbc@postoffice.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { TypeSubJourneyStep } from './TypeSubJourneyStep';
import { HttpFile } from '../http/http';

/**
* References re-usable list of Nodes by name from root node subJourneys.
*/
export class SubJourneyStep {
    'type': TypeSubJourneyStep;
    'name': string;
    'alias'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "type",
            "baseName": "type",
            "type": "TypeSubJourneyStep",
            "format": ""
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "alias",
            "baseName": "alias",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return SubJourneyStep.attributeTypeMap;
    }

    public constructor() {
    }
}

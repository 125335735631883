import React, { useMemo, ReactNode } from "react";
import { Handle, Position, useStore, ReactFlowState } from "reactflow";
import { titleize } from "../common/helpers/string/titleize";
import {
  ConfigProps,
  JSONArray,
  JSONObject,
  JSONValue,
  NodeValidationCode,
  ToolTipData,
  CanvasNode,
} from "../types/customTypes";
import { hasSiblings } from "../common/actions/ruleValidation";
import * as types from "../api/models/all";
import { getNodeLibrary } from "../common/helpers/openAPI";
import { nodeValidationMessages } from "../fixtures/nodeValidationMessages";
import { tooltipMap } from "../common/constants/tooltip/tooltipMap";
import { nextPage } from "../common/helpers/nodes/nextPage";
import NodeLabel from "./NodeLabel";
import NodeBadge from "./NodeBadge";
import NodeTooltip from "./NodeTooltip";
import NodesDeprecated from "./NodesDeprecated";

const styleNodeLabel = "flex-start text-xs text-center break-words";

const isNotFinisherAndStarter = (type: string) => {
  const finisherAndStarter = ["undo", "abort", "complete", "journey"];
  return !finisherAndStarter.includes(type);
};

export const NodeHTML = ({ data, type, id }: CanvasNode): ReactNode => {
  if (!data || !type) return <div />;
  const edges = useStore((state: ReactFlowState) => state.edges);
  const { config } = data;
  const tooltipAttributes = useMemo((): string[] => {
    const result: string[] = [];
    Object.keys(config).map((key) => Object.keys(tooltipMap).includes(key) && result.push(key));
    return result;
  }, [config]);

  const memoizedSiblings = useMemo(() => {
    if (type !== "infostep") return true;
    return hasSiblings(edges, id);
  }, [edges, id, type]);

  const isObject = (configValue: JSONValue): boolean => {
    return typeof configValue === "object";
  };
  const isValid = data.valid && memoizedSiblings && data.errorCodes.length === 0;

  return (
    <div
      data-cy={`${type}-node`}
      className={`node-container group/errors ${isValid ? "" : "border-red-700"} ${
        nextPage(type, config) ? "border-b-blue-700 border-b-4" : ""
      }`}
    >
      {tooltipAttributes.map(
        (configKey: string) =>
          isObject(config[`${configKey}`] as JSONValue) && (
            <NodeBadge
              key={`${configKey}-badge`}
              configArray={config[`${configKey}`] as JSONArray}
              tooltipData={tooltipMap[`${configKey as keyof ConfigProps}`] as ToolTipData}
            />
          )
      )}
      {isValid ? null : (
        <div className="bg-gray-300 -top-10 left-32 bg-opacity-50 pointer-events-none rounded hidden group-hover/errors:block absolute text-left py-2 pl-6 z-50 w-72">
          {data.errorCodes && data.errorCodes.map((error) => <p key={error}>{nodeValidationMessages[error]}</p>)}
          {!memoizedSiblings && <p className="">{nodeValidationMessages[NodeValidationCode.SIBLINGS]}</p>}
        </div>
      )}
      <div className="flex-start text-center font-bold text-xs mr-1 leading-snug">{titleize(data.config.type)}</div>
      <div
        className={`${styleNodeLabel} ${data?.config?.type === "condition" ? "leading-snug" : "leading-none"} truncate`}
      >
        {data && <NodeLabel data={data} />}
      </div>
      {tooltipAttributes.map(
        (configKey: string) =>
          isObject(config[`${configKey}`] as JSONValue) && (
            <NodeTooltip
              key={`${configKey}-tooltip`}
              configArray={config[`${configKey}`] as JSONObject[]}
              tooltipData={tooltipMap[`${configKey as keyof ConfigProps}`] as ToolTipData}
            />
          )
      )}

      {isNotFinisherAndStarter(data.config.type) ? (
        <div>
          <Handle type="target" position={Position.Left} id="L">
            <div className="visual-handle">
              <div />
            </div>
          </Handle>
          <Handle type="source" position={Position.Right} id="R">
            <div className="visual-handle">
              <div />
            </div>
          </Handle>
        </div>
      ) : null}

      {type !== "journeystep" && (
        <Handle type="target" position={Position.Top} id="T">
          <div className="visual-handle">
            <div />
          </div>
        </Handle>
      )}
      {Object.keys(data.config).includes("nodes") && (
        <Handle type="source" position={Position.Bottom} id="B">
          <div className="visual-handle">
            <div />
          </div>
        </Handle>
      )}
    </div>
  );
};

const getNodeHTML = (importList: string[]): [string, ({ data, type, id }: CanvasNode) => ReactNode][] => {
  return importList.map((element: string): [string, ({ data, type, id }: CanvasNode) => ReactNode] => {
    return [element, NodeHTML];
  });
};

export const nodeTypes = {
  ...Object.fromEntries(getNodeHTML(getNodeLibrary(types as never))),
  deprecated: NodesDeprecated,
} as unknown as ReactNode;

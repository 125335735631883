import React from "react";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { Node } from "reactflow";
import { titleize } from "../common/helpers/string/titleize";
import { DataProps } from "../types/customTypes";
import DeprecatedNodeHandles from "./DeprecatedNodeHandles";

const NodesDeprecated = ({ data }: Node<DataProps>): JSX.Element => {
  return (
    <div className="deprecated-node-container group p-2" data-cy="deprecated-node">
      <div className="bg-gray-300 p-3 bottom-10 ml-3 rounded hidden group-hover:block absolute text-left py-2 pl-6 z-50 w-72">
        <p className="">This is a deprecated node type</p>
      </div>
      <div className="flex flex-row">
        <p className="font-bold text-xs mr-1">{titleize(data.config.type)}</p>
        <HiOutlineExclamationCircle color="red" />
      </div>
      <DeprecatedNodeHandles />
    </div>
  );
};

export default NodesDeprecated;

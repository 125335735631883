/**
 * SPM User Journey APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 7.2.0
 * Contact: tbc@postoffice.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AbortStep } from './AbortStep';
import { ChoiceStep } from './ChoiceStep';
import { CompleteStep } from './CompleteStep';
import { CompleteStepReceiptProperties } from './CompleteStepReceiptProperties';
import { ConditionStep } from './ConditionStep';
import { Enabler } from './Enabler';
import { InfoListData } from './InfoListData';
import { InfoStep } from './InfoStep';
import { InfoStepVisualProperties } from './InfoStepVisualProperties';
import { InputGroupStep } from './InputGroupStep';
import { InputStep } from './InputStep';
import { JourneyStep } from './JourneyStep';
import { OptionsStep } from './OptionsStep';
import { PromptStep } from './PromptStep';
import { ScannerStep } from './ScannerStep';
import { SelectionStep } from './SelectionStep';
import { SelectionTableStep } from './SelectionTableStep';
import { SubJourneyStep } from './SubJourneyStep';
import { TypeSubJourneyStep } from './TypeSubJourneyStep';
import { UndoStep } from './UndoStep';
import { ValidationField } from './ValidationField';
import { HttpFile } from '../http/http';

export class Node {
    'type': TypeSubJourneyStep;
    /**
    * subJourney name passed to switch to specific screen.
    */
    'returnTo'?: string;
    'nodes'?: Array<Node>;
    'enablers'?: Array<Enabler>;
    /**
    * Values that should be preserved between journey steps. Key is dot-notation key and value is a template or simple value.
    */
    'outputData'?: { [key: string]: string; };
    /**
    * Used to ensure the data entered by a user is valid
    */
    'inputValidation'?: Array<ValidationField>;
    /**
    * Data passed to basket to correctly display information about items in the basket.
    */
    'basket': { [key: string]: string; };
    /**
    * Data passed to transaction when it's sent to backend.
    */
    'transaction': { [key: string]: string; };
    /**
    * Key name that holds basket item list.
    */
    'itemSelector'?: string;
    /**
    * Customer receipt to print
    */
    'customerReceipt'?: CompleteStepReceiptProperties;
    /**
    * Branch receipt to print
    */
    'branchReceipt'?: CompleteStepReceiptProperties;
    /**
    * Data passed to commit & fulfillment for processing of a fulfillment.
    */
    'fulfilment'?: { [key: string]: string; };
    /**
    * Displayed as header text
    */
    'title': string;
    /**
    * Displayed as normal text
    */
    'description': string;
    'visual'?: InfoStepVisualProperties;
    /**
    * Data used to display list of key/value pairs. Only used with \"list\" view.
    */
    'params'?: string | Array<InfoListData>;
    /**
    * Values that are used to pre-populate step with some initial data.
    */
    'defaultValues'?: { [key: string]: string; };
    'name': string;
    'alias'?: string;

    static readonly discriminator: string | undefined = "type";

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "type",
            "baseName": "type",
            "type": "TypeSubJourneyStep",
            "format": ""
        },
        {
            "name": "returnTo",
            "baseName": "returnTo",
            "type": "string",
            "format": ""
        },
        {
            "name": "nodes",
            "baseName": "nodes",
            "type": "Array<Node>",
            "format": ""
        },
        {
            "name": "enablers",
            "baseName": "enablers",
            "type": "Array<Enabler>",
            "format": ""
        },
        {
            "name": "outputData",
            "baseName": "outputData",
            "type": "{ [key: string]: string; }",
            "format": ""
        },
        {
            "name": "inputValidation",
            "baseName": "inputValidation",
            "type": "Array<ValidationField>",
            "format": ""
        },
        {
            "name": "basket",
            "baseName": "basket",
            "type": "{ [key: string]: string; }",
            "format": ""
        },
        {
            "name": "transaction",
            "baseName": "transaction",
            "type": "{ [key: string]: string; }",
            "format": ""
        },
        {
            "name": "itemSelector",
            "baseName": "itemSelector",
            "type": "string",
            "format": ""
        },
        {
            "name": "customerReceipt",
            "baseName": "customerReceipt",
            "type": "CompleteStepReceiptProperties",
            "format": ""
        },
        {
            "name": "branchReceipt",
            "baseName": "branchReceipt",
            "type": "CompleteStepReceiptProperties",
            "format": ""
        },
        {
            "name": "fulfilment",
            "baseName": "fulfilment",
            "type": "{ [key: string]: string; }",
            "format": ""
        },
        {
            "name": "title",
            "baseName": "title",
            "type": "string",
            "format": ""
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string",
            "format": ""
        },
        {
            "name": "visual",
            "baseName": "visual",
            "type": "InfoStepVisualProperties",
            "format": ""
        },
        {
            "name": "params",
            "baseName": "params",
            "type": "string | Array<InfoListData>",
            "format": ""
        },
        {
            "name": "defaultValues",
            "baseName": "defaultValues",
            "type": "{ [key: string]: string; }",
            "format": ""
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "alias",
            "baseName": "alias",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Node.attributeTypeMap;
    }

    public constructor() {}
}

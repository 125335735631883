import React, { useContext, useEffect, useRef, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import ClipLoader from "react-spinners/ClipLoader";
import { HiRefresh } from "react-icons/hi";
import { TextField } from "@mui/material";
import { toast } from "react-toastify";
import { SelectorValue } from "../../types/customTypes";
import { getJourneys } from "../../common/helpers/apihelpers";
import { GroupJourneys } from "../../common/helpers/apihelpers/types";
import { SubJourneyListActions } from "../../common/reducers/subJourneyListReducer";
import { JourneyContext } from "../../context";

interface Props {
  deviceId: string | null;
  onJourneySelected: (journeyName: string, immediateCommit: boolean) => void;
  defaultValue: SubJourney | null;
}

type FocusEventWithValue = React.FocusEvent & {
  target: { value: string };
};

export interface SubJourney extends SelectorValue {
  categoryTitle: string;
}

export const getSubJourneyList = (journeyList: GroupJourneys[]) => {
  const jList: SubJourney[] = [];
  journeyList.forEach((journeyType) => {
    journeyType.options.forEach((journey) => {
      jList.push({ categoryTitle: journey.categoryTitle, label: journey.name, value: journey.name });
    });
  });
  return jList;
};

const SubJourneyComponent: React.FC<Props> = ({ deviceId, onJourneySelected, defaultValue }) => {
  const [subJourneys, setSubJourneys] = useState<SubJourney[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedJourney, setSelectedJourney] = useState<SubJourney | null>(defaultValue);
  const renderCount = useRef(1);
  const { subJourneysList, subJourneysDispatch } = useContext(JourneyContext);

  useEffect(() => {
    if (renderCount.current === 1 && deviceId && !isLoading) {
      renderCount.current += 1;
      if (!subJourneysList[deviceId]) {
        loadJourneys(deviceId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadJourneys = (id: string | null) => {
    if (!id) {
      toast(
        <div>
          <p>Please click on preview and add device.</p>
        </div>
      );
      return;
    }
    setIsLoading(true);
    getJourneys(id, true)
      .then((journeysResponse) => {
        setIsLoading(false);
        if (journeysResponse && subJourneysDispatch) {
          subJourneysDispatch({
            type: SubJourneyListActions.UPDATE_SUB_JOURNEYS,
            payload: { deviceId: id, subJourneyList: journeysResponse },
          });
        }
      })
      .catch(() => {
        const errorMessage = subJourneysList[id] ? "can not update sub-journey list" : "failed to retrieve sub-journey";
        setIsLoading(false);
        toast(
          <div>
            <p>{errorMessage}</p>
          </div>
        );
      });
  };

  useEffect(() => {
    if (deviceId && subJourneysList[deviceId]) {
      setSubJourneys(getSubJourneyList(subJourneysList[deviceId]));
    }
  }, [subJourneysList, deviceId]);

  const onChange = (item: SubJourney | string | null, immediateCommit = false) => {
    let commit = immediateCommit;
    let jItem: SubJourney | null = null;
    if (item) {
      if (typeof item === "string") {
        jItem = { categoryTitle: "", label: item, value: item };
      } else {
        commit = true;
        jItem = item;
      }
    }
    setSelectedJourney(jItem);
    onJourneySelected(jItem ? jItem.label : "", commit);
  };

  const onBlur = (e: FocusEventWithValue) => {
    onChange(e.target.value, true);
  };

  return (
    <div>
      <h3 className="block text-gray-700 text-sm font-bold mb-2 capitalize">Name</h3>
      <div data-testid="journey-component-selector" className="flex flex-row">
        <Autocomplete
          value={selectedJourney}
          onChange={(_, newValue) => onChange(newValue, false)}
          onBlur={(e) => onBlur(e as unknown as FocusEventWithValue)}
          className="flex-1"
          groupBy={(Jou) => Jou.categoryTitle}
          options={subJourneys}
          freeSolo
          loading={isLoading}
          renderInput={(params) => <TextField {...params} size="small" placeholder="Select sub-journey" />}
        />
        <button
          type="button"
          className="mx-1 flex justify-center items-center border-2 border-grey-300 cursor-pointer"
          onClick={() => {
            loadJourneys(deviceId);
          }}
        >
          {isLoading ? (
            <ClipLoader data-testid="clip-loader" size={25} color="black" />
          ) : (
            <HiRefresh size={25} color="#34D399" />
          )}
        </button>
      </div>
    </div>
  );
};

export default SubJourneyComponent;

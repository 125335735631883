import React, { useState } from "react";
import { HiOutlineX, HiMenu } from "react-icons/hi";
import { ButtonToolProps } from "../types/customTypes";
import Modal from "./Modal";
import { modalSizes } from "../common/constants/modals/modalSizes";
import Button from "./Button";

export interface ButtonHolderProps {
  buttonTools: ButtonToolProps;
}

const ButtonHolder = ({ buttonTools }: ButtonHolderProps): JSX.Element => {
  const {
    saveCanvas,
    clearCanvas,
    openCanvas,
    isValidJourney,
    generateModal,
    modalChildren,
    modalVisibility,
    setModalVisibility,
  } = buttonTools;

  const [navBar, setNavBar] = useState<boolean>(false);

  return (
    <div data-testid="data_buttons_wrap" className="flex justify-end mr-3">
      {!navBar ? (
        <HiMenu
          onClick={() => setNavBar(!navBar)}
          xmlns="<http://www.w3.org/2000/svg>"
          className="h-8 w-8 cursor-pointer xl:hidden block my-3 mb-4"
          data-cy="hamburger"
        />
      ) : (
        <HiOutlineX
          className="h-8 w-8 cursor-pointer xl:hidden block my-3 mb-4"
          data-cy="menu-close"
          onClick={() => setNavBar(!navBar)}
        />
      )}
      <div
        className={`
        xl:flex
        xl:pt-0
        xl:relative
        xl:mt-0
        mt-12
        ${navBar ? "absolute z-10" : "hidden"}
        `}
      >
        <Button testID="btn-preview-canvas" value="Preview" onClick={generateModal} />
        <Button testID="btn-save-canvas" value="Save..." onClick={saveCanvas} />
        <Button testID="btn-clear-canvas" value="Clear" onClick={clearCanvas} />
        <Button testID="btn-import-canvas" value="Import..." onClick={generateModal} />
        <Button testID="btn-open-canvas" value="Open..." onClick={openCanvas} />

        <Button
          testID="btn-export-canvas"
          value="Export..."
          onClick={generateModal}
          disabled={!isValidJourney.result}
          disabledMessage={isValidJourney.message}
        />
        {modalVisibility === "on" && (
          <Modal
            showHeader
            close={() => setModalVisibility("off")}
            modalChildren={modalChildren}
            size={modalSizes.large}
          />
        )}
      </div>
    </div>
  );
};

export default ButtonHolder;

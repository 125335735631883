import React from "react";
import { HiOutlinePlus } from "react-icons/hi";
import ReactTooltip from "react-tooltip";
import { maxTabs } from "../common/constants/maxTabs";
import { AppState, TabToolProps } from "../types/customTypes";
import { Tab } from "./Tab";

export interface TabBarProps {
  state: AppState;
  tabTools: TabToolProps;
}

const TabBar = ({ state, tabTools }: TabBarProps): JSX.Element => {
  return (
    <div className="flex flex-1 items-center mx-4 ">
      {state.tabData.tabs.map((element, index: number) => {
        const isOnlyTab = state.tabData.tabs.length === 1;
        const startNode = element.nodes.find((node) => node.type === "journeystep");
        const key = element.canvasData.id;
        const title = startNode ? startNode.data.config.title : `Canvas_${index.toString()}`;
        return (
          <div data-testid={`tab-holder-${key}`} key={key} data-tip data-for={key}>
            <Tab
              index={index}
              title={title}
              handleCanvasChange={tabTools.onChange}
              currentCanvas={state.tabData.current}
              handleCanvasDelete={tabTools.onDelete}
              isOnlyTab={isOnlyTab}
            />
            <ReactTooltip className="w-fit" id={key} delayShow={400} globalEventOff="click" effect="solid">
              <span data-testid={`tab-tool-tip-${key}`}>{title}</span>
            </ReactTooltip>
          </div>
        );
      })}
      {state.tabData.tabs.length < maxTabs ? (
        <HiOutlinePlus
          data-cy="add-tab"
          data-testid="add-tab-icon"
          size={32}
          className="rounded-full p-2 mx-2 hover:bg-gray-500"
          onClick={() => tabTools.onAdd()}
        />
      ) : null}
    </div>
  );
};
export default TabBar;

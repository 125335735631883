import { Node, XYPosition } from "reactflow";
import { DataProps, MouseData } from "../../types/customTypes";

const offset: XYPosition = {
  x: 50,
  y: 50,
};
export const positioningAdjustment = (nodes: Node<DataProps>[], mouseData: MouseData, increment = 0): Node[] => {
  if (mouseData.mouseClick) {
    const firstPosition = nodes.reduce(
      (min, node) => (node.position.y < min.y ? node.position : min),
      nodes[0].position
    );
    return nodes.map((node) => {
      return {
        ...node,
        position: {
          x: node.position.x - firstPosition.x + mouseData.mousePosition.x + increment * offset.x,
          y: node.position.y - firstPosition.y + mouseData.mousePosition.y + increment * offset.y,
        },
      };
    });
  }
  const repositionedNodes = nodes.map((node) => {
    return {
      ...node,
      position: { x: node.position.x + increment * offset.x, y: node.position.y + increment * offset.y },
    };
  });

  return repositionedNodes;
};

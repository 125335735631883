import React, { useState } from "react";
import { Autocomplete, AutocompleteChangeReason, AutocompleteInputChangeReason, TextField } from "@mui/material";
import { JSONObject, SelectOption, UserFriendlyChangeFunction } from "../types/customTypes";
import { titleize } from "../common/helpers/string";

interface UserFriendlyDropdownProps {
  dropdownParam: string;
  config: JSONObject;
  enums: string[] | undefined;
  handleOnChange: UserFriendlyChangeFunction;
}

type FocusEventWithValue = React.FocusEvent & {
  target: { value: string };
};

const NONE = "None";

const UserFriendlyDropdown = ({
  dropdownParam,
  enums,
  handleOnChange,
  config,
}: UserFriendlyDropdownProps): JSX.Element => {
  if (enums === undefined) return <div />;
  const [selectedValue, setSelectedValue] = useState<SelectOption | null>({
    label: config[dropdownParam] as string,
    value: config[dropdownParam] as string,
  });
  const enumMap = [
    ...enums.map((option) => {
      return { label: titleize(option), value: option };
    }),
    { label: NONE, value: "" },
  ] as SelectOption[];

  const handleSelection = (e, option: string | SelectOption | null, reason: AutocompleteChangeReason) => {
    if (reason === "clear") {
      setSelectedValue({ label: "", value: "" });
      handleOnChange({ key: dropdownParam, value: "", immediateCommit: true });
    }
    if (option === null) return;
    if (reason === "createOption" && typeof option === "string") {
      setSelectedValue({ label: option, value: option });
      handleOnChange({ key: dropdownParam, value: option, immediateCommit: true });
    }
    if (typeof option === "string") return;
    if (reason === "selectOption") {
      setSelectedValue(option);
      handleOnChange({ key: dropdownParam, value: option.value, immediateCommit: true });
    }
  };

  const handleManualInput = (e, option: string | null, reason: AutocompleteInputChangeReason) => {
    if (reason === "input" && typeof option === "string") {
      setSelectedValue({ label: `${option} (custom)`, value: option });
    }
  };

  const getDefault = () => {
    if (config[dropdownParam] === undefined || config[dropdownParam] === "") return { label: NONE, value: "" };
    return (
      enumMap.find((item) => item.value === config[dropdownParam]) || {
        label: config[dropdownParam]?.toString() || "",
        value: config[dropdownParam] as string,
      }
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onBlur = (_: FocusEventWithValue) => {
    if (selectedValue !== null) {
      // TODO: Should we take/coalesce the incoming value rather than the state value here
      handleOnChange({ key: dropdownParam, value: selectedValue.value, immediateCommit: true });
    }
  };

  return (
    <div
      data-testid="enum-select-component"
      className="-p-2 ml-auto text-xs font-normal absolute flex justify-end flex-row w-2/3 -top-2 right-0"
    >
      <Autocomplete
        value={getDefault().label ? getDefault().label : ""}
        className="w-full"
        freeSolo
        selectOnFocus
        clearOnBlur
        data-testid={`${dropdownParam}-visual-combobox`}
        options={enumMap}
        onChange={(item, a, b) => handleSelection(item, a, b)}
        renderInput={(params) => <TextField {...params} size="small" />}
        onInputChange={(event, value, reason) => handleManualInput(event, value, reason)}
        onBlur={(e) => onBlur(e as unknown as FocusEventWithValue)}
      />
    </div>
  );
};

export default UserFriendlyDropdown;

import React from "react";
import { JSONObject, UserFriendlyChangeFunction } from "../types/customTypes";

interface UserFriendlyTextProps {
  textBoxParams: string;
  config: JSONObject;
  handleOnChange: UserFriendlyChangeFunction;
}
const UserFriendlyText = ({ textBoxParams, config, handleOnChange }: UserFriendlyTextProps): JSX.Element => {
  return (
    <div key={textBoxParams} className="mb-2 p-2" data-testid="String-Editor-container">
      <textarea
        data-testid={`${textBoxParams}-visual-text`}
        id={textBoxParams}
        placeholder="Enter Data"
        value={(config[textBoxParams] as string) || ""}
        className="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-2 block w-full appearance-none leading-normal text-xs font-normal"
        onChange={(e) => handleOnChange({ key: textBoxParams, value: e.target.value, immediateCommit: false })}
        onBlur={(e) => handleOnChange({ key: textBoxParams, value: e.target.value, immediateCommit: true })}
      />
    </div>
  );
};

export default UserFriendlyText;

export const titleize = (str: string): string => {
  if (typeof str !== "string") return "No type found";
  if (str.toUpperCase() === str) return str;
  const string = str
    .split(" ")
    .map((word) => word[0] && word[0].toUpperCase() + word.slice(1).replace(/([A-Z])/g, " $1"))
    .join(" ");
  let acronyms: string[] = [];
  let results = "";
  string.split(" ").forEach((word, index): string | void => {
    if (word.length === 1) {
      acronyms.push(word);
      if (acronyms.length > 0 && string.split(" ").length - 1 === index) {
        results = `${results} ${acronyms.join("")}`;
        acronyms = [];
        return;
      }
      return;
    }
    if (acronyms.length > 0 && word.length > 1) {
      results = `${results} ${acronyms.join("")} ${word}`;
      acronyms = [];
      return;
    }
    results += ` ${word}`;
  });

  return results.trim();
};

import React from "react";
import ModalTab from "./ModalTab";
import ModalTabLast from "./ModalTabLast";
import { nonCloseModalTitles } from "../common/constants/modals/modalData";

export interface ModalHeaderProps {
  tabTitles: string[];
  selectedTab: string;
  close: () => void;
  onSelect: (e) => void;
}

const ModalHeader = ({ tabTitles, selectedTab, onSelect, close }: ModalHeaderProps): JSX.Element => {
  const numOfTabs = tabTitles.length - 1;
  return (
    <div data-testid="modal-header" className="flex bg-gray-500 h-1/6 w-full">
      {tabTitles.map((title, tabNumber) => {
        const isSelected = title === selectedTab;
        const renderLastTab = tabNumber === numOfTabs && !nonCloseModalTitles.includes(title);
        return (
          <div key={`${title}`} className="w-full relative">
            {renderLastTab ? (
              <ModalTabLast title={title} onSelect={onSelect} isSelected={isSelected} close={close} />
            ) : (
              <ModalTab title={title} onSelect={onSelect} isSelected={isSelected} />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ModalHeader;

import { JourneyDownloadTypes } from "../../constants/journeyDownloadTypes";

interface InputCheckerResult {
  isValidInput: boolean;
  journey: string | null;
  inputType: string | null;
}

export const isValid = (fileContent: string, textAreaContent: string): boolean =>
  !((fileContent && textAreaContent) || (!fileContent && !textAreaContent));

export const inputChecker = (fileContent: string, textAreaContent: string): InputCheckerResult => {
  const isValidInput = isValid(fileContent, textAreaContent);
  if (textAreaContent.length > 0 && isValidInput) {
    return {
      isValidInput,
      journey: textAreaContent,
      inputType: JourneyDownloadTypes.TEXT,
    };
  }
  if (!textAreaContent.length && isValidInput) {
    return {
      isValidInput,
      journey: fileContent,
      inputType: JourneyDownloadTypes.FILE,
    };
  }
  return {
    isValidInput,
    journey: null,
    inputType: null,
  };
};

import React from "react";
import { CanvasKeyGuideItem } from "./CanvasKeyGuideItem";
import { keys } from "../../common/constants/KeyGuideKeys";

export const CanvasKeyGuide = (): JSX.Element => {
  return (
    <div
      data-testid="canvas-key-guide"
      className="text-xs absolute left-12 bottom-3 bg-white opacity-50 z-50 pointer-events-none rounded-md p-1"
    >
      {keys.map((item, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <CanvasKeyGuideItem key={idx} name={item.name} value={item.value} />
      ))}
    </div>
  );
};

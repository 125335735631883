import React, { useState } from "react";

interface FileUploaderProps {
  setFiles: React.Dispatch<React.SetStateAction<string>>;
  isLoading: boolean;
}

const FileUploader = ({ setFiles, isLoading }: FileUploaderProps): JSX.Element => {
  const [fileMeta, setFileMeta] = useState<File | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileReader = new FileReader();
    if (e.target && e.target.files && e.target.files[0] !== null) {
      setFileMeta(e.target.files[0]);
      fileReader.readAsText(e.target.files[0], "UTF-8");
      fileReader.onload = (event) => {
        if (event.target !== null && typeof event.target.result === "string") {
          setFiles(event.target.result);
        }
      };
    }
  };

  const removeFile = (): void => {
    const fileField = document.getElementById("file-upload-form") as HTMLFormElement;
    fileField.reset();
    setFiles("");
    setFileMeta(null);
  };

  return (
    <form
      className="flex flex-col items-center justify-center w-1/2 font-sans mx-4 border-2 rounded-t-sm border-dashed border-gray-300 bg-gray-100 text-gray-400 relative"
      id="file-upload-form"
    >
      <label htmlFor="file-upload">
        <p className="text-black font-medium">
          <span className="text-pol-red">*</span> Upload a journey
        </p>
        <input className="border border-gray-300 mt-2 w-52" id="file-upload" type="file" onChange={handleChange} />
      </label>
      {fileMeta !== null ? (
        <button
          type="button"
          className="px-4 py-2 text-sm text-white font-medium hover:bg-opacity-60 bg-pol-red mt-4 absolute bottom-4 disabled:bg-disabled-gray disabled:opacity-50"
          onClick={removeFile}
          data-test="auth-flow-btn"
          disabled={isLoading}
        >
          Remove file
        </button>
      ) : null}
    </form>
  );
};

export default FileUploader;

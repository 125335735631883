import { Node } from "reactflow";
import { getNodeData } from "../../actions/nodeMapper";
import { structuredClone } from "../../utils";
import { ConfigProps, DataProps } from "../../../types/customTypes";
import { TypeInputGroupStep } from "../../../openapi/api";

export const polyfillNodes = (nodes: Node<DataProps>[]): Node<DataProps>[] => {
  return nodes.map((node) => {
    const freshNode = getNodeData(node.id, node.type || "deprecated", node.position);
    const importedData = structuredClone(node);
    if (freshNode.type === "deprecated") return freshNode;
    const freshNodeConfig = freshNode.data.config;
    if (
      freshNodeConfig.type.toLowerCase() === TypeInputGroupStep.InputGroup.toLowerCase() &&
      freshNodeConfig.groupNodes !== undefined
    ) {
      const groupNodes = node.data.config.groupNodes as ConfigProps[];
      const newGroupNodes = groupNodes.map((groupNode) => {
        const freshGroupNode = getNodeData("groupNode", `${groupNode.type}step`, { x: 0, y: 0 });
        return { ...freshGroupNode.data.config, ...groupNode };
      });
      (importedData.data.config.groupNodes as ConfigProps[]) = structuredClone(newGroupNodes) as ConfigProps[];
    }
    return {
      ...freshNode,
      data: { ...freshNode.data, config: { ...freshNode.data.config, ...importedData.data.config } },
    } as Node<DataProps>;
  });
};

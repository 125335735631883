import React, { useCallback, useEffect, useReducer, useState } from "react";
import { Node } from "reactflow";
import { useFetchYamlData } from "../common/hooks/useFetchYamlData";
import { inputValidator } from "../common/helpers/string/inputValidator";
import { ElementActions, ValidElementActions } from "../common/reducers/elements";
import { initialAttributeNodeState, attributeNodeReducer, AttributeNodeActions } from "../common/reducers/attribute";
import { DataProps, ElementChangeProps } from "../types/customTypes";
import CommonNodeProperties from "./CommonNodeProperties";
import NonCommonNodeProperties from "./NonCommonNodeProperties";
import { getNodeType } from "../common/helpers/attributes/nodeType";
import { DeprecatedNodeType } from "../common/constants/deprecatedNode";
import GroupNodeEditor from "./GroupNodeEditor";
import { journeyTitleClean, equivalentNodes } from "../common/helpers/nodes";

export interface AttributeBarProps {
  setAttributeBarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  attributeBarOpen: boolean;
  selectedNode: Node<DataProps> | null;
  hasTopConnection: boolean;
  elementsDispatch: React.Dispatch<ValidElementActions>;
  canvas: number | null;
  deviceId: string | null;
}
const AttributeBar = ({
  setAttributeBarOpen,
  attributeBarOpen,
  selectedNode,
  hasTopConnection,
  elementsDispatch,
  canvas,
  deviceId,
}: AttributeBarProps): JSX.Element => {
  const [attNode, attributeDispatch] = useReducer(attributeNodeReducer, initialAttributeNodeState);
  const yamlData = useFetchYamlData();
  const [historyCommitDue, setHistoryCommitDue] = useState(false);

  useEffect(() => {
    if (selectedNode) {
      // TODO: Move NodeValidation.jsonErrors into CanvasNode to prevent requirement to calculate each load
      if (selectedNode?.id !== attNode.id) {
        attributeDispatch({ type: AttributeNodeActions.SWITCH_NODE, payload: { inputNode: selectedNode } });
      } else {
        attributeDispatch({
          type: AttributeNodeActions.SWITCH_NODE,
          payload: { inputNode: selectedNode, preserveValidationData: true },
        });
      }
    }
  }, [selectedNode, attNode.id]);

  useEffect(() => {
    if (selectedNode?.data.config.type) {
      const { type } = selectedNode.data.config;
      const typeTitle = `${getNodeType(type)}Step`;
      if (yamlData && yamlData[typeTitle]) {
        // TODO: remove per-node requiredFields. RequiredFields are known globally.
        attributeDispatch({
          type: AttributeNodeActions.SET_REQUIRED_FIELDS,
          payload: { requiredFields: yamlData[typeTitle].required },
        });
      }
    }
  }, [selectedNode, yamlData]);

  useEffect(() => {
    if (attNode && selectedNode) {
      if (!equivalentNodes(attNode, selectedNode) && attNode?.id) {
        elementsDispatch({ type: ElementActions.UPDATE_ELEMENT_DATA, payload: { node: attNode } });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attNode, canvas]);

  useEffect(() => {
    if (historyCommitDue) {
      elementsDispatch({ type: ElementActions.HISTORY_COMMIT });
      setHistoryCommitDue(false);
    }
  }, [historyCommitDue, elementsDispatch, setHistoryCommitDue]);

  const handleOnChange = useCallback(
    (change: ElementChangeProps) => {
      const { value, key, nodeType, isJSONEditor, jsonError, commitToHistory } = change;
      const shouldCommit = commitToHistory === undefined || commitToHistory;
      if (jsonError) {
        attributeDispatch({
          type: AttributeNodeActions.AMEND_ERROR_STORE,
          payload: { key, jsonError },
        });
      }
      let editValue = inputValidator(value, key, isJSONEditor);
      if (typeof editValue === "string") {
        editValue = journeyTitleClean(editValue, nodeType, key);
      }
      attributeDispatch({
        type: AttributeNodeActions.UPDATE_CONFIG_KEY,
        payload: { key, value: editValue },
      });
      if (shouldCommit) {
        setHistoryCommitDue(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const hasValidNode = attNode?.id && attNode.data.validationDatum.requiredFields?.length > 0;

  return (
    <aside className={`flex flex-col items-start h-full ${attributeBarOpen ? "overflow-y-auto" : "overflow-y-hidden"}`}>
      <button
        className="dragndrop-close-btn self-end my-2"
        onClick={() => setAttributeBarOpen(!attributeBarOpen)}
        type="button"
      >
        <span className="material-icons flex justify-center">
          {attributeBarOpen ? "keyboard_double_arrow_right" : "keyboard_double_arrow_left"}
        </span>
      </button>
      <div className={`${attributeBarOpen ? "bg-white w-full" : "hidden"}`}>
        {attNode.data.config.title !== DeprecatedNodeType && hasValidNode && (
          <CommonNodeProperties
            handleOnChange={handleOnChange}
            nodeData={attNode}
            hasTopConnection={hasTopConnection}
          />
        )}
        {attNode.data.config.groupNodes && (
          <GroupNodeEditor
            nodeData={attNode}
            onChange={handleOnChange}
            jsonFieldName="groupNodes"
            hasTopConnection={hasTopConnection}
          />
        )}
        {hasValidNode && (
          <NonCommonNodeProperties
            handleOnChange={handleOnChange}
            nodeData={attNode}
            yamlData={yamlData}
            deviceId={deviceId}
          />
        )}
      </div>
    </aside>
  );
};
export default AttributeBar;

import React from "react";
import { JSONObject, UserFriendlyChangeFunction } from "../types/customTypes";

interface UserFriendlyCheckboxProps {
  config: JSONObject;
  booleanParam: string;
  handleOnChange: UserFriendlyChangeFunction;
}
const UserFriendlyCheckbox = ({ booleanParam, handleOnChange, config }: UserFriendlyCheckboxProps): JSX.Element => {
  return (
    <div key={booleanParam} className="p-1 ml-auto absolute top-0 right-0 mb-2" data-testid="Boolean-Editor-container">
      <input
        type="checkbox"
        data-testid={`${booleanParam}-visual-checkbox`}
        checked={config[booleanParam] === true}
        id={booleanParam}
        className="bg-white ml-auto focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-2 block appearance-none leading-normal text-xs font-normal"
        onChange={(e) => handleOnChange({ key: booleanParam, value: e.target.checked, immediateCommit: true })}
      />
    </div>
  );
};

export default UserFriendlyCheckbox;

import React from "react";
import SideBarButton from "./SideBarButton";
import { modelNodeTypes } from "../common/helpers/openAPI/modelNodeTypes";
import { useFetchYamlData } from "../common/hooks/useFetchYamlData";
import { getNodeYamlData } from "../common/helpers/yaml/getNodeYamlData";
import { startNode } from "../common/constants/startNode";

const Sidebar = (): JSX.Element => {
  const nodeYamlData = useFetchYamlData();
  const nodesFromModels = modelNodeTypes;
  const nodesWithDescription = getNodeYamlData(nodeYamlData, nodesFromModels);

  return (
    <aside className="border-r-4 border-gray-200 ml-1 pr-1 flex-1 overflow-y-auto h-full">
      <div className="flex flex-col">
        {nodesWithDescription &&
          nodesWithDescription
            .filter((node) => node.name !== startNode)
            .map((node) => {
              return <SideBarButton node={node} key={node.name} />;
            })}
      </div>
    </aside>
  );
};
export default Sidebar;

import {
  NodeTraversalCollection,
  NodeHandle,
  EdgeConnectKey,
  EdgeConnectHandle,
  KeyActionMappings,
  InputKey,
  CanvasActionType,
  CanvasStep,
  SearchStep,
} from "../../../types/customTypes";

export const baseTraversals: NodeTraversalCollection = {
  parent: {
    sourceIDKey: EdgeConnectKey.TARGET,
    sourceHandleKey: EdgeConnectHandle.TARGET_HANDLE,
    sourceHandle: NodeHandle.TOP,
    targetHandleKey: EdgeConnectKey.SOURCE,
  },
  child: {
    sourceIDKey: EdgeConnectKey.SOURCE,
    sourceHandleKey: EdgeConnectHandle.SOURCE_HANDLE,
    sourceHandle: NodeHandle.BOTTOM,
    targetHandleKey: EdgeConnectKey.TARGET,
  },
  nextSibling: {
    sourceIDKey: EdgeConnectKey.SOURCE,
    sourceHandleKey: EdgeConnectHandle.SOURCE_HANDLE,
    sourceHandle: NodeHandle.RIGHT,
    targetHandleKey: EdgeConnectKey.TARGET,
  },
  prevSibling: {
    sourceIDKey: EdgeConnectKey.TARGET,
    sourceHandleKey: EdgeConnectHandle.TARGET_HANDLE,
    sourceHandle: NodeHandle.LEFT,
    targetHandleKey: EdgeConnectKey.SOURCE,
  },
};

export const keyActionMap: KeyActionMappings = {
  [InputKey.ArrowUp]: {
    default: {
      type: CanvasActionType.STEP,
      action: [CanvasStep.PARENT],
    },
    shift: {
      type: CanvasActionType.STEP,
      action: [CanvasStep.PARENT, CanvasStep.PREVIOUS_SIBLING],
    },
  },
  [InputKey.ArrowDown]: {
    default: {
      type: CanvasActionType.STEP,
      action: [CanvasStep.CHILD],
    },
    shift: {
      type: CanvasActionType.STEP,
      action: [CanvasStep.CHILD, CanvasStep.NEXT_SIBLING],
    },
  },
  [InputKey.ArrowLeft]: {
    default: {
      type: CanvasActionType.STEP,
      action: [CanvasStep.PREVIOUS_SIBLING],
    },
    shift: {
      type: CanvasActionType.STEP,
      action: [CanvasStep.PREVIOUS_SIBLING, CanvasStep.PARENT],
    },
  },
  [InputKey.ArrowRight]: {
    default: {
      type: CanvasActionType.STEP,
      action: [CanvasStep.NEXT_SIBLING],
    },
    shift: {
      type: CanvasActionType.STEP,
      action: [CanvasStep.NEXT_SIBLING, CanvasStep.CHILD],
    },
  },
  [InputKey.Enter]: {
    default: {
      type: CanvasActionType.SEARCH,
      action: SearchStep.NEXT_RESULT,
    },
    shift: {
      type: CanvasActionType.SEARCH,
      action: SearchStep.PREVIOUS_RESULT,
    },
  },
};

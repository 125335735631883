import React from "react";
import { UserFriendlyModes } from "../types/customTypes";

interface SwitchButtonProps {
  mode: string;
  setMode: React.Dispatch<React.SetStateAction<UserFriendlyModes>>;
}

const UserFriendlySwitchButton = ({ mode, setMode }: SwitchButtonProps) => {
  return (
    <div className="border-2 border-pol-purple rounded-md flex w-5/6 mx-auto mb-2">
      <button
        data-testid="basic-switch"
        type="button"
        onClick={() => setMode(UserFriendlyModes.BASIC)}
        className={`${
          mode === UserFriendlyModes.BASIC ? "text-white bg-pol-purple" : ""
        } w-1/2 rounded flex justify-center`}
      >
        basic
      </button>
      <button
        data-testid="advanced-switch"
        type="button"
        onClick={() => setMode(UserFriendlyModes.ADVANCED)}
        className={`${
          mode === UserFriendlyModes.ADVANCED ? "text-white bg-pol-purple" : ""
        } w-1/2 rounded flex justify-center`}
      >
        advanced
      </button>
    </div>
  );
};

export default UserFriendlySwitchButton;

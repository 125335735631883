/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  EnumFieldKeys,
  JsonFieldKeys,
  JSONValue,
  SelectOption,
  YAMLSchema,
  ElementChangeFunction,
  AttributeNode,
} from "../types/customTypes";
import { getFieldTypes } from "../common/helpers/openAPI";
import JsonEditor from "./JsonEditor";
import { TypeConditionStepComparisons, TypeInputStepInputMasks } from "../openapi/api";
import { isByPassedAttribute } from "../common/actions/nodeValidation";
import { preferredOrder } from "../fixtures/OrderChange";
import EnablerJsonEditor from "./EnablerJsonEditor";
import { mapEnumToObjectArray } from "../common/helpers/mapEnumToObjectArray";
import AttributeStringField from "./AttributeStringField";
import AttributeBooleanField from "./AttributeBooleanField";
import AttributeNumberField from "./AttributeNumberField";
import { DeprecatedNodeType } from "../common/constants/deprecatedNode";
import VisualEditor from "./VisualEditor";
import AttributeDropdownField from "./AttributeDropdownField";
import SubJourneyComponent, { SubJourney } from "./SubJourneyComponent";

interface NonCommonNodeProperties {
  nodeData: AttributeNode;
  handleOnChange: ElementChangeFunction;
  yamlData?: YAMLSchema | null;
  excludedFields?: string[];
  deviceId?: string | null;
}

enum CustomEditor {
  ENABLERS = "enablers",
  VISUAL = "visual",
}

const NOT_SUPPORTED_NODE_DESC =
  "This node is not supported in Journey Engine and Journey Builder. Please replace this with available nodes";
const NonCommonNodeProperties = ({
  nodeData,
  handleOnChange,
  yamlData,
  excludedFields,
  deviceId = null,
}: NonCommonNodeProperties): JSX.Element => {
  const [uncommonNodeKeys, setUncommonNodeKeys] = useState<string[]>([]);
  const [uncommonNumberNodeKeys, setUncommonNumberNodeKeys] = useState<string[]>([]);
  const [uncommonBooleanNodeKeys, setUncommonBooleanNodeKeys] = useState<string[]>([]);
  const [jsonEditorFields, setJsonEditorFields] = useState<JsonFieldKeys[]>([]);
  const [dropdownField, setDropdownFields] = useState<EnumFieldKeys[]>([]);

  /*
   * FIXME: The lists should not be all created ahead of being required.
   * comparisonList and inputMaskList are not needed for every component.
   */
  const comparisonList = mapEnumToObjectArray(
    TypeConditionStepComparisons,
    "label",
    "value"
  ) as unknown as SelectOption[];

  const inputMaskList = [
    { label: "None", value: "" },
    ...mapEnumToObjectArray(TypeInputStepInputMasks, "label", "value"),
  ] as unknown as SelectOption[];

  const getDataDescription = (configName: string): JSX.Element | null => {
    if (configName === DeprecatedNodeType) {
      return <h1 className="px-1 text-red-400">{NOT_SUPPORTED_NODE_DESC}</h1>;
    }
    return null;
  };

  const showError = (attr: string): boolean => {
    return (
      !isByPassedAttribute(attr) &&
      nodeData.data.validationDatum.requiredFields.includes(attr) &&
      String(nodeData.data.config[attr]).length === 0
    );
  };

  useEffect(() => {
    if (nodeData) {
      let { jsonFieldKeys } = getFieldTypes(`${nodeData.data.config.type}step`);
      const { stringKeys, numberKeys, booleanKeys, enumFieldKeys } = getFieldTypes(`${nodeData.data.config.type}step`);
      if (excludedFields && excludedFields.length > 0) {
        jsonFieldKeys = jsonFieldKeys.filter((field) => excludedFields.some((e) => e !== field.name));
      }
      setJsonEditorFields(jsonFieldKeys);
      setDropdownFields(enumFieldKeys);
      setUncommonNumberNodeKeys(numberKeys);
      setUncommonBooleanNodeKeys(booleanKeys);
      setUncommonNodeKeys(
        preferredOrder(nodeData.data.config.type === "condition" ? preferredOrder(stringKeys) : stringKeys)
      );
    }
  }, [excludedFields, nodeData]);

  const getJSONField = (jsonData: JSONValue, jsonFieldName: string) => {
    switch (jsonFieldName) {
      case CustomEditor.ENABLERS:
        return <EnablerJsonEditor nodeData={nodeData} onChange={handleOnChange} jsonFieldName={jsonFieldName} />;
      case CustomEditor.VISUAL:
        return (
          <VisualEditor
            nodeType={nodeData.data.config.type}
            handleOnChange={handleOnChange}
            jsonData={nodeData.data.config}
            jsonFieldName={jsonFieldName}
          />
        );
      default:
        return (
          <JsonEditor
            nodeType={nodeData.data.config.type}
            handleOnChange={handleOnChange}
            jsonData={jsonData}
            jsonFieldName={jsonFieldName}
          />
        );
    }
  };

  return (
    <div data-testid="non_common_attr_wrap">
      {nodeData && (
        <div>
          {getDataDescription(nodeData.data.config.title || "")}
          {uncommonNodeKeys.map((textBoxParams: string) => {
            if (textBoxParams === "name" && nodeData.data.config.type === "subjourney") {
              const { name } = nodeData.data.config;
              const defaultValue: SubJourney | null = name ? { label: name, categoryTitle: "", value: name } : null;
              return (
                <div
                  key={`${nodeData.id}_subjourney`}
                  className="flex-row mb-2 p-2 text-xs font-normal"
                  data-testid="SubJourney-Dropdown-container"
                >
                  <SubJourneyComponent
                    deviceId={deviceId}
                    defaultValue={defaultValue}
                    onJourneySelected={(journeyName: string, immediateCommit: boolean) => {
                      handleOnChange({
                        value: journeyName,
                        key: textBoxParams,
                        nodeType: nodeData.data.config.type,
                        commitToHistory: immediateCommit,
                      });
                    }}
                  />
                </div>
              );
            }
            switch (textBoxParams) {
              case "comparison":
                return (
                  <div
                    className="flex-row mb-2 p-2 text-xs font-normal"
                    key={textBoxParams}
                    data-testid="Comparison-Dropdown-container"
                  >
                    <h3 className="block text-gray-700 text-sm font-bold mb-2 capitalize">Comparison</h3>
                    <div data-testid="comparison-component" key={`${nodeData.id}_comparison`}>
                      <Select
                        placeholder="Select Comparison"
                        isSearchable={false}
                        defaultValue={comparisonList.find((item) => item.value === nodeData.data.config[textBoxParams])}
                        onChange={(item) =>
                          item?.value &&
                          handleOnChange({
                            value: item?.value,
                            key: textBoxParams,
                            nodeType: nodeData.data.config.type,
                            commitToHistory: true,
                          })
                        }
                        options={comparisonList}
                      />
                    </div>
                    {showError(textBoxParams) && (
                      <p style={{ fontSize: "10px" }} className="block text-red-700 font-normal capitalize">
                        Required field
                      </p>
                    )}
                  </div>
                );
              case "inputMask":
                return (
                  <div
                    className="flex-row mb-2 p-2 text-xs font-normal"
                    key={textBoxParams}
                    data-testid="InputMask-Dropdown-container"
                  >
                    <h3 className="block text-gray-700 text-sm font-bold mb-2 capitalize">Input Mask</h3>
                    <div data-testid="inputmask-component" key={`${nodeData.id}_inputmask`}>
                      <Select
                        placeholder="Select Mask"
                        defaultValue={inputMaskList.find((item) => item.value === nodeData.data.config[textBoxParams])}
                        onChange={(item) =>
                          handleOnChange({
                            value: item?.value as string,
                            key: textBoxParams,
                            nodeType: nodeData.data.config.type,
                            commitToHistory: true,
                          })
                        }
                        options={inputMaskList}
                      />
                    </div>
                  </div>
                );
              default:
                break;
            }
            return (
              <AttributeStringField
                key={`${nodeData.id}_${textBoxParams}`}
                textBoxParams={textBoxParams}
                config={nodeData.data.config}
                handleOnChange={handleOnChange}
                showError={showError}
              />
            );
          })}
          {uncommonNumberNodeKeys.map((numberParam: string) => {
            return (
              <AttributeNumberField
                key={`${nodeData.id}_${numberParam}`}
                numberParam={numberParam}
                config={nodeData.data.config}
                handleOnChange={handleOnChange}
                showError={showError}
              />
            );
          })}
          {uncommonBooleanNodeKeys.map((booleanParam: string) => {
            return (
              <AttributeBooleanField
                key={`${nodeData.id}_${booleanParam}`}
                booleanParam={booleanParam}
                config={nodeData.data.config}
                handleOnChange={handleOnChange}
              />
            );
          })}
          {yamlData &&
            dropdownField.map((enumParam: EnumFieldKeys) => {
              return (
                <div>
                  <AttributeDropdownField
                    key={`${nodeData.id}_${enumParam.name}`}
                    enumParam={enumParam}
                    enums={yamlData[enumParam.displayWith].enum}
                    config={nodeData.data.config}
                    handleOnChange={handleOnChange}
                    showError={showError}
                  />
                  {showError(enumParam.name) && (
                    <p style={{ fontSize: "10px" }} className="block text-red-700 font-normal capitalize">
                      Required field
                    </p>
                  )}
                </div>
              );
            })}
          {jsonEditorFields.map((jsonHolderProps) => {
            const jsonFieldName = jsonHolderProps.name;
            const jsonData = nodeData.data.config[jsonFieldName] as JSONValue;
            return (
              <div key={`${nodeData.id}_${jsonFieldName}`} data-testid="JSON-Editor-container">
                {getJSONField(jsonData, jsonFieldName)}

                {showError(jsonHolderProps.name) && (
                  <p style={{ fontSize: "10px" }} className="block text-red-700 font-normal capitalize">
                    Required field
                  </p>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default NonCommonNodeProperties;

import React, { useState, useEffect } from "react";
import Select, { ActionMeta, MultiValue } from "react-select";
import { JSONObject, SelectorValue, ElementChangeFunction, AttributeNode } from "../types/customTypes";
import JsonEditor from "./JsonEditor";
import { changeJsonData, equalJsonContent, dataTypeChecker } from "../common/helpers/string";
import { ENABLERS_LIST } from "../common/constants/EnablersMapping";

interface Props {
  nodeData: AttributeNode;
  onChange: ElementChangeFunction;
  jsonFieldName: string;
}

const EnablerJsonEditor = ({ nodeData, onChange: handleOnChange, jsonFieldName }: Props): JSX.Element => {
  const jsonKey = "enablers";
  const [jsonValue, setJsonValue] = useState<JSONObject[]>(nodeData.data.config[jsonKey] as JSONObject[]);

  const onEnablerSelected = (enabler: MultiValue<SelectorValue>, options: ActionMeta<SelectorValue>): void => {
    if (jsonValue) {
      const value = options.action === "select-option" ? options.option?.value : options?.removedValue?.value;
      if (value) {
        const changedData = changeJsonData(options.action, value, nodeData.data.config[jsonKey] as JSONObject[]);
        setJsonValue(changedData);
        handleOnChange({
          value: dataTypeChecker(changedData),
          key: jsonFieldName,
          nodeType: nodeData.data.config.type,
          isJSONEditor: true,
          commitToHistory: true,
        });
      }
    }
  };

  const getDefaultValues = (data: JSONObject[]) => {
    const enablerListNew: SelectorValue[] = [];
    if (data && typeof data === "object") {
      data.forEach((element) => {
        const selectedEnabler = ENABLERS_LIST.find((item) => {
          const value = item.value === "customEnabler" ? "" : item.value;
          return value === element.id;
        });
        if (selectedEnabler) enablerListNew.push(selectedEnabler);
      });
    }
    return enablerListNew;
  };

  useEffect(() => {
    const { config } = nodeData.data;
    if (config[jsonFieldName]) {
      const data = config[jsonFieldName] as JSONObject[];
      if (!equalJsonContent(JSON.stringify(data), JSON.stringify(jsonValue))) {
        setJsonValue(data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeData]);

  const updateAndCommit = () => {
    if (jsonValue !== null) {
      handleOnChange({
        value: dataTypeChecker(jsonValue),
        key: jsonFieldName,
        nodeType: nodeData.data.config.type,
        isJSONEditor: true,
        commitToHistory: true,
      });
    }
  };

  return (
    <div>
      <div data-testid="enabler-select-component" className="flex-row p-2 text-xs font-normal">
        <Select
          placeholder="Select Enabler"
          isMulti
          onChange={onEnablerSelected}
          onBlur={updateAndCommit}
          value={getDefaultValues(nodeData.data.config[jsonKey] as JSONObject[])}
          formatOptionLabel={(item: SelectorValue) => {
            return (
              <div className="flex bg-white -m-2 p-2">
                <div>
                  <span className="text-gray-700">{item.label}</span>
                </div>
              </div>
            );
          }}
          options={ENABLERS_LIST}
        />
      </div>
      <JsonEditor
        nodeType={nodeData.data.config.type}
        handleOnChange={handleOnChange}
        jsonData={dataTypeChecker(jsonValue, "[]") as unknown as JSONObject}
        jsonFieldName={jsonFieldName}
      />
    </div>
  );
};
export default EnablerJsonEditor;

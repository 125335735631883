import { Node } from "reactflow";
import { DataProps } from "../../../types/customTypes";
import { JBuilderNode } from "../../../files/types";
import { structuredClone } from "../../utils";

export const cleanUpConfigNodes = (nodes: JBuilderNode[]) => {
  const resultNodes = nodes.map((callNode: JBuilderNode) => {
    const configNodes = (callNode.canvasNode as Node<DataProps>).data.config.nodes;
    if (configNodes && configNodes.length) {
      const callNodeCopy: JBuilderNode = structuredClone(callNode);
      (callNodeCopy.canvasNode as Node<DataProps>).data.config.nodes = [];
      return callNodeCopy;
    }
    return callNode;
  });
  return resultNodes;
};

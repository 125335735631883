export enum ComponentText {
  // App Name
  APP_NAME = "Journey Builder",
  // Header
  LOG_IN = "Log In",
  LOG_OUT = "Log Out",

  // HeaderWarning
  VERIFICATION_CODE_MSG = " It can take up to 10 minutes to receive a verification code using a Post Office " +
    "email address",
  PLEASE_NOTE = "Please Note:",

  // PublishJourney
  DRAFT_JOURNEY = "Draft Journey",
  JOURNEY = "Journey",
  PREVIEW = "Preview",
  PUBLISH = "Publish",

  // UploadJourney
  IMPORTING = "Importing",
  IMPORT = "Import",
}

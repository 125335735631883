import React from "react";
import { SearchDetails, SelectorNode } from "../../types/customTypes";
import { SearchErrorStatus, errorStatusMessages } from "./types";

interface SearchElementsListProps {
  elements: SelectorNode[] | undefined;
  onNodeChange: (ele: SelectorNode) => void;
  additionalStyling: string;
  searchDetails: SearchDetails | null | undefined;
}

export const SearchElementsList = ({
  elements,
  onNodeChange,
  additionalStyling,
  searchDetails,
}: SearchElementsListProps) => {
  const errorStatus = searchDetails?.error;
  if (!(errorStatus || (elements && elements?.length > 0))) {
    return <></>;
  }

  const getErrorMessage = (err: SearchErrorStatus | null | undefined) => {
    if (err) {
      return errorStatusMessages[err];
    }
    return null;
  };

  const showError = () => {
    return (
      <div className="box-border border-2 border-solid bg-gray-100 text-gray-700 p-1">
        <p>{getErrorMessage(errorStatus)}</p>
      </div>
    );
  };
  const getElementsList = () => {
    const selectedElement = searchDetails?.match.selected?.value.id;
    return (
      <div className="max-h-56 bg-white overflow-y-scroll border-2 ">
        {elements &&
          elements.length > 0 &&
          elements.map((ele) => {
            const selected = selectedElement === ele.value.id ? "selected" : "";
            return (
              <>
                <div
                  key={`list-btn-${ele.value.id}`}
                  data-testid={`list-${ele.value.id}`}
                  className={`search_list_item ${selected}`}
                >
                  <button
                    type="button"
                    data-testid={`list-btn-${ele.value.id}`}
                    onClick={() => {
                      onNodeChange(ele);
                    }}
                  >
                    {`${ele.label}`}
                  </button>
                </div>
                <hr className="rounded border-1 border-solid " />
              </>
            );
          })}
      </div>
    );
  };

  return (
    <div
      data-testid="search-panel"
      className={`absolute z-10 bottom-2 top-12 w-auto box-border inset-0 right-2 ${additionalStyling}`}
    >
      {errorStatus ? showError() : getElementsList()}
    </div>
  );
};

import React, { useMemo, useState } from "react";
import { getDeviceID } from "../common/helpers/devices/getDeviceID";
import { ButtonToolProps, AppState, ExportCheckMessage } from "../types/customTypes";
import PublishJourney from "../components/PublishJourney";
import UploadJourney from "../components/UploadJourney";
import LibraryJourney from "../components/LibraryJourney";
import DownloadJourney from "../components/DownloadJourney";
import { convertToJson } from "../common/mappers/mapToCanvasType";
import { saveCanvasFile } from "../common/services/FileManagement/saveCanvasFile";
import { openCanvasFile } from "../common/services/FileManagement/openCanvasFile";
import { oldStyleImport } from "../files/import/journeyImport";
import { ElementActions, ValidElementActions } from "../common/reducers/elements";
import { isCompletedJourney } from "../common/actions/ruleValidation";
import { polyfillNodes } from "../common/helpers/nodes/polyfillNodes";
import { ModalVisibility } from "../common/constants/modals/modalVisibility";
import { OpenPreViewLink } from "../files/types";

export interface ButtonToolBagProps {
  state: AppState;
  elementsDispatch: React.Dispatch<ValidElementActions>;
  setAttributeBar: React.Dispatch<React.SetStateAction<boolean>>;
  openPreviewLink: OpenPreViewLink;
}

const ButtonToolBag = ({ state, elementsDispatch, openPreviewLink }: ButtonToolBagProps): ButtonToolProps => {
  const [modalVisibility, setModalVisibility] = useState<string>(ModalVisibility.OFF);
  const [modalChildren, setModalChildren] = useState<Record<string, JSX.Element>>({});
  const [centerPoint, setCenterPoint] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

  const isValidJourney: ExportCheckMessage = useMemo(() => {
    if (state.tabData.current === null) {
      return {
        result: false,
        message: ["No canvas selected"],
      };
    }
    return isCompletedJourney(
      state.tabData.tabs[state.tabData.current].nodes,
      state.tabData.tabs[state.tabData.current].edges
    );
  }, [state]);

  const saveCanvas = async () => {
    await saveCanvasFile(state);
  };

  const clearCanvas = () => {
    elementsDispatch({ type: ElementActions.CLEAR, payload: {} });
  };

  const addDeviceID = (deviceID: string) => {
    elementsDispatch({
      type: ElementActions.UPDATE_DEVICE_ID,
      payload: { canvas: state.tabData.current as number, deviceID },
    });
  };

  const existingDeviceID = getDeviceID(state, state.tabData.current as number);

  const importJourney = (imported: string, inputType: string, canvas = state.tabData.current as number) => {
    const { result, center } = oldStyleImport(imported, inputType);
    const { nodes, edges } = result;
    elementsDispatch({
      type: ElementActions.DROPJOURNEY,
      payload: { importedNodesArray: nodes, importedEdgesArray: edges, canvas },
    });
    setCenterPoint(center);
  };

  const openCanvas = async () => {
    const result = await openCanvasFile();
    if (result) {
      result.nodes = polyfillNodes(result.nodes);
      elementsDispatch({
        type: ElementActions.DROPJOURNEY,
        payload: {
          importedNodesArray: result.nodes,
          importedEdgesArray: result.edges,
          canvas: state.tabData.current as number,
        },
      });
    }
  };

  const exportJourney = (): string => {
    if (state.tabData.current === null) return "";
    return convertToJson(state.tabData.tabs, state.tabData.current);
  };

  const enum ModalContentOptions {
    PREVIEW = "Preview",
    IMPORT = "Import...",
    EXPORT = "Export...",
  }

  const getModalContent = (event: Event): Record<string, JSX.Element> => {
    const target = event.target as HTMLButtonElement;
    switch (target.value) {
      case ModalContentOptions.PREVIEW:
        return {
          Preview: (
            <PublishJourney
              exportJourney={exportJourney}
              openPreviewLink={openPreviewLink}
              isDraftJourney
              canvasIndex={state.tabData.current as number}
              addDeviceID={addDeviceID}
              existingDeviceID={existingDeviceID}
              setModalVisibility={setModalVisibility}
            />
          ),
        };
      case ModalContentOptions.IMPORT:
        return {
          Upload: <UploadJourney importJourney={importJourney} close={() => setModalVisibility(ModalVisibility.OFF)} />,
          Library: <LibraryJourney importJourney={importJourney} />,
        };
      case ModalContentOptions.EXPORT:
        return {
          Download: <DownloadJourney exportJourney={exportJourney} />,
          Publish: (
            <PublishJourney
              exportJourney={exportJourney}
              openPreviewLink={openPreviewLink}
              isDraftJourney={false}
              canvasIndex={state.tabData.current as number}
              addDeviceID={addDeviceID}
              existingDeviceID={existingDeviceID}
              setModalVisibility={setModalVisibility}
            />
          ),
        };
      default:
        return {};
    }
  };

  const generateModal = (e: Event) => {
    setModalVisibility(ModalVisibility.ON);
    setModalChildren(getModalContent(e));
  };

  const buttonLogicHolder: ButtonToolProps = {
    saveCanvas,
    clearCanvas,
    openCanvas,
    isValidJourney,
    generateModal,
    modalChildren,
    modalVisibility,
    setModalVisibility,
    importJourney,
    exportJourney,
    centerPoint,
  };
  return buttonLogicHolder;
};

export default ButtonToolBag;

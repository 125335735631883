import { Node, XYPosition } from "reactflow";
import { ConfigProps, DataProps } from "../../types/customTypes";
import * as types from "../../api/models/all";
import { INTERCEPT_ENUMS_KEY, INTERCEPT_JSON_ARRAY } from "../constants/AttributeInterceptKeys";

type Format = {
  name: string;
  baseName: string;
  type: string;
  format: string;
};

export const getNodeData = (nodeId: string, type: string, position: XYPosition): Node<DataProps> => {
  const typesLibrary = Object.entries(types);
  const construct = typesLibrary.find((tuple) => type === tuple[0].toLowerCase());
  if (construct === undefined) {
    return {
      id: nodeId,
      type: "deprecated",
      position,
      selected: false,
      dragging: false,
      data: {
        label: "deprecated",
        config: { type: "deprecated", title: "deprecated" },
      },
    } as Node<DataProps>;
  }
  const data = construct[1].attributeTypeMap;
  const config = {} as ConfigProps;
  data.forEach((format: Format) => {
    if (format.name === "type" && format.type !== "TypeJourneyStep") {
      config.sjName = "";
    }
    if (INTERCEPT_JSON_ARRAY.includes(format.name)) {
      config[`${format.name}`] = [];
    } else if (INTERCEPT_ENUMS_KEY.includes(format.name)) {
      config[`${format.name}`] = "";
    } else if (format.type === "number") {
      config[`${format.name}`] = 0;
    } else if (format.type === "boolean") {
      config[`${format.name}`] = false;
    } else if (
      (format.type.split(" ").includes("string") && !format.type.includes("|")) ||
      format.type.search("Type") !== -1 ||
      format.type.includes("Array<string") ||
      format.type.includes("Array<any>")
    ) {
      config[`${format.name}`] = "";
    } else if (format.type.includes("{ [key: string]:")) {
      config[`${format.name}`] = {};
    } else if (format.type.includes("Array")) {
      config[`${format.name}`] = [];
    } else {
      config[`${format.name}`] = {};
    }
    if (format.name === "type") {
      config.type = type.slice(0, -4);
    }
    if (format.name === "title") {
      config.title = "title";
    }
  });
  return {
    id: nodeId,
    type,
    position,
    selected: false,
    dragging: false,
    data: {
      label: `${type}`,
      valid: true,
      errorCodes: [],
      config,
    },
  } as Node<DataProps>;
};

import { Node as CanvasNode } from "reactflow";
import {
  Canvas,
  DataProps,
  NodeTraversal,
  CanvasKeyAction,
  KeyActionMapping,
  KeyActionMappings,
  TraverseAction,
  CanvasActionType,
  CanvasStep,
  SearchStep,
  TraversalTarget,
} from "../../../types/customTypes";
import { baseTraversals, keyActionMap } from "./config";
import { stepSearchResults } from "../../../components/searchComponent/helpers";

export const linkedNode = (
  canvas: Canvas,
  startNode: CanvasNode<DataProps> | null,
  traversal: NodeTraversal | NodeTraversal[]
): CanvasNode<DataProps> | null => {
  if (!startNode) return null;
  const { edges, nodes } = canvas;
  const traversals: NodeTraversal[] = Array.isArray(traversal) ? [...traversal] : [traversal];
  let nextNode: CanvasNode<DataProps> | null | undefined = null;

  // !TODO: Consider maintaining a hashmap of all edges to speed up retrieval & traversal
  while (!nextNode && traversals.length > 0) {
    const t = traversals.shift();
    if (t) {
      nextNode = nodes.find(
        (n) =>
          n.id ===
          edges.find((e) => e[t.sourceIDKey] === startNode.id && e[t.sourceHandleKey] === t.sourceHandle)?.[
            t.targetHandleKey
          ]
      );
    }
  }
  if (!nextNode) return null;
  return nextNode;
};

const matchKey = (key: string, keyList: KeyActionMappings): KeyActionMapping | undefined => {
  if (!key || !keyList[key]) return undefined;
  return keyList[key];
};

export const modifierSelectedAction = (
  canvasKey: CanvasKeyAction,
  mapping: KeyActionMapping | undefined
): TraverseAction | null => {
  if (!mapping || !canvasKey) return null;
  const traverseAction = Object.keys(mapping).reduce((memo, key) => {
    if (memo || key === "default") return memo;
    if (!canvasKey[key] || !mapping[key]) return memo;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return mapping[key] || memo;
  }, null);
  return traverseAction || mapping.default;
};

const mapStepToTraversal = (action: CanvasStep[] | null): NodeTraversal[] => {
  if (!action) return [];
  const traversal = action.map((n) => {
    return baseTraversals[n];
  });
  return traversal;
};

const searchedNode = (canvas: Canvas, searchStep: SearchStep): CanvasNode<DataProps> | null => {
  if (!canvas.canvasData.search?.match) return null;
  const stepIncrement = searchStep === SearchStep.NEXT_RESULT ? 1 : -1;
  const targetNode = canvas.nodes.find(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    (n) => n.id === stepSearchResults(canvas.canvasData.search!.match, stepIncrement)?.value.id
  );
  return targetNode || null;
};

export const traversalTarget = (
  canvas: Canvas,
  selectedNode: CanvasNode<DataProps> | null,
  keyAction: CanvasKeyAction
): TraversalTarget | null => {
  const modifiedAction = modifierSelectedAction(keyAction, matchKey(keyAction.action, keyActionMap));
  if (!modifiedAction?.type) return null;
  let node: CanvasNode<DataProps> | null = null;
  switch (modifiedAction.type) {
    case CanvasActionType.STEP:
      node = linkedNode(canvas, selectedNode, mapStepToTraversal(modifiedAction.action));
      break;
    case CanvasActionType.SEARCH:
      node = searchedNode(canvas, modifiedAction.action);
      break;
    default:
      node = null;
      break;
  }
  return node
    ? {
        type: modifiedAction.type,
        node,
      }
    : null;
};

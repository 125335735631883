import { NodeValidationCode } from "../types/customTypes";

type ErrorCodeMap = {
  [key in NodeValidationCode]: string;
};

export const nodeValidationMessages: ErrorCodeMap = {
  [NodeValidationCode.REQUIRED]: "Missing required attribute field.",
  [NodeValidationCode.JSON]: "JSON editor contains error.",
  [NodeValidationCode.ENDNODE]: "Journey missing an end node.",
  [NodeValidationCode.SIBLINGS]: "Please connect node to another node laterally.",
};

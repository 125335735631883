import { v4 } from "uuid";
import { CanvasNode, AppState, CanvasWithHistory, ElementSet } from "../types/customTypes";

const initID = v4();

const newJourneyNode = (id: string): CanvasNode => {
  return {
    id,
    type: "journeystep",
    position: { x: 50, y: 25 },
    data: {
      config: {
        type: "journey",
        title: "Journey",
        engineVersion: "",
        subJourneys: {},
        nodes: [],
      },
      label: "journey node",
      valid: true,
      errorCodes: [],
    },
    selected: false,
    dragging: false,
  };
};

export const initialStartElement: CanvasNode = {
  ...newJourneyNode(initID),
};

export const blankTab = (id: string): CanvasWithHistory => {
  const nodes = [newJourneyNode(id)];
  const edges = [];
  const present: ElementSet = { nodes, edges };
  return {
    ...present,
    canvasData: { viewport: { x: 0, y: 0, zoom: 1 }, id, deviceID: "" },
    history: { past: [], present, future: [] },
  };
};
export const blankState = (id: string): AppState => {
  return {
    tabData: {
      current: 0,
      tabs: [blankTab(id)],
    },
  };
};

// TODO: Remove side effects [v4()] as this is currently consumed in reducers which should be pure.
export const initialState: AppState = {
  tabData: {
    current: 0,
    tabs: [blankTab(initID)],
  },
};

import React, { useReducer, useState, useEffect, useRef } from "react";
import { Node } from "reactflow";
import { getCanvas } from "../common/helpers/elements/getElements";
import { initialState } from "../fixtures/startElement";
import { initialSubJourneysListState, subJourneysListReducer } from "../common/reducers/subJourneyListReducer";
import { canvasReducer } from "../common/reducers/elements";
import ButtonToolBag from "../tools/ButtonToolBag";
import TabToolBag from "../tools/TabTools";
import ButtonHolder from "./ButtonHolder";
import Sidebar from "./SideBar";
import TabBar from "./TabBar";
import TabCanvas from "./TabCanvas";
import { dbStore } from "../dataBase/dbStore";
import { useDebounce } from "../common/hooks/useDebounce";
import { DataProps, FocusTools } from "../types/customTypes";
import { CanvasTools } from "../types/collections";
import SearchComponent from "./searchComponent";
import { baseURL } from "../common/helpers/url/sandBox";
import { OpenPreViewLink, UrlRefList } from "../files/types";

import { JourneyContext } from "../context";

const Dashboard = (): JSX.Element => {
  const [state, elementsDispatch] = useReducer(canvasReducer, initialState);
  const [subJourneysList, subJourneysDispatch] = useReducer(subJourneysListReducer, initialSubJourneysListState);
  const [isAttributeBar, setAttributeBar] = useState<boolean>(false);
  const [focusedNode, setFocusedNode] = useState<Node<DataProps> | undefined>();
  const tabTools = TabToolBag({ elementsDispatch, setAttributeBar });
  const urlRefList = useRef<UrlRefList>({ refs: {} });
  const ref = useRef<Window | null>();

  const openPreviewLink:OpenPreViewLink = (deviceID: string, fileName: string, index: number, openPreviewInOwnTab = false) => {
    let keyRef: string | undefined = "keyRef";
    if (openPreviewInOwnTab) {
      const startNode = state.tabData.tabs[index].nodes.find((node) => node.type === "journeystep");
      keyRef = startNode?.data.config.title;
    }
    if (keyRef) {
      const currentRef = urlRefList.current.refs[keyRef];
      if (currentRef) {
        currentRef.current?.close();
      }
      ref.current = window.open(`${baseURL}?deviceId=${deviceID}&journeyName=${fileName}`, index.toString());
      urlRefList.current.refs[keyRef] = ref;
    }
  };

  const buttonTools = ButtonToolBag({ state, elementsDispatch, setAttributeBar, openPreviewLink });
  const canvasTools: CanvasTools = {
    canvas: getCanvas(state),
    state,
    elementsDispatch,
    isAttributeBar,
    setAttributeBar,
  };

  // TODO: Switch this over to reducer and dispatch focus event through that instead. This is clunky.
  const focusTools: FocusTools = {
    focusedNode,
    setFocusedNode,
  };

  const debouncedElements = useDebounce(state, 100);

  useEffect(() => {
    dbStore.Put(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedElements]);
  return (
    <JourneyContext.Provider value={{ subJourneysList, subJourneysDispatch }}>
      <div className="h-full flex flex-col">
        <div className="flex items-end w-screen flex-between">
          <TabBar state={state} tabTools={tabTools} />
          <div className="flex">
            <SearchComponent canvasTools={canvasTools} setSearchedNode={setFocusedNode} />
            <ButtonHolder buttonTools={buttonTools} />
          </div>
        </div>
        <div className="flex h-full m-4 mt-0 bg-white overflow-hidden">
          <div className="overflow-y-auto">
            <Sidebar />
          </div>
          <TabCanvas canvasTools={canvasTools} buttonTools={buttonTools} focusTools={focusTools} tabTools={tabTools} />
        </div>
      </div>
    </JourneyContext.Provider>
  );
};

export default Dashboard;

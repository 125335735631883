import { HashCanvas } from "../../../files/types";

export const getNodesWithConvergence = (edges: HashCanvas["edges"]): string[] => {
  const holder: Record<string, boolean> = {};
  const mySet = new Set<string>();
  edges.forEach((edge) => {
    if (holder[edge.target]) {
      mySet.add(edge.target);
    } else {
      holder[edge.target] = true;
    }
  });
  return Array.from(mySet);
};

import Dexie, { Table } from "dexie";
import { AppState } from "../types/customTypes";

export interface JourneyCanvasData {
  id?: number;
  unSavedCanvas: AppState;
}

export class MySubClassedDexie extends Dexie {
  journeys!: Table<JourneyCanvasData>;

  constructor() {
    super("myDatabase");
    this.version(1).stores({
      journeys: "++id, unSavedCanvas",
    });
  }
}

export const db = new MySubClassedDexie();

import React from "react";

export interface ModalTabProps {
  title: string;
  isSelected: boolean;
  onSelect: (e) => void;
}
const ModalTab = ({ title, onSelect, isSelected }: ModalTabProps): JSX.Element => {
  const styles = isSelected ? "bg-white" : "bg-gray-500 text-white";
  return (
    <button
      data-testid="modal-tab"
      className={`${styles} w-full text-lg font-semibold h-full p-2`}
      type="button"
      value={title}
      onClick={onSelect}
    >
      <h1>{title}</h1>
    </button>
  );
};

export default ModalTab;

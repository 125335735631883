/**
 * SPM User Journey APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 7.2.0
 * Contact: tbc@postoffice.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ChoiceStepVisualProperties } from './ChoiceStepVisualProperties';
import { Enabler } from './Enabler';
import { Node } from './Node';
import { NodeWithChildren } from './NodeWithChildren';
import { NodeWithEnablers } from './NodeWithEnablers';
import { NodeWithOutputData } from './NodeWithOutputData';
import { TypeChoiceStep } from './TypeChoiceStep';
import { HttpFile } from '../http/http';

/**
* Used to allow a user to choose between multiple possible options. It can be a list of buttons or a radio button group.
*/
export class ChoiceStep {
    'type': TypeChoiceStep;
    'title': string;
    'visual'?: ChoiceStepVisualProperties;
    'nodes'?: Array<Node>;
    'enablers'?: Array<Enabler>;
    /**
    * Values that should be preserved between journey steps. Key is dot-notation key and value is a template or simple value.
    */
    'outputData'?: { [key: string]: string; };

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "type",
            "baseName": "type",
            "type": "TypeChoiceStep",
            "format": ""
        },
        {
            "name": "title",
            "baseName": "title",
            "type": "string",
            "format": ""
        },
        {
            "name": "visual",
            "baseName": "visual",
            "type": "ChoiceStepVisualProperties",
            "format": ""
        },
        {
            "name": "nodes",
            "baseName": "nodes",
            "type": "Array<Node>",
            "format": ""
        },
        {
            "name": "enablers",
            "baseName": "enablers",
            "type": "Array<Enabler>",
            "format": ""
        },
        {
            "name": "outputData",
            "baseName": "outputData",
            "type": "{ [key: string]: string; }",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return ChoiceStep.attributeTypeMap;
    }

    public constructor() {
    }
}

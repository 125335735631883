import { HashCanvas } from "../types";
import { JourneyStep } from "../../openapi/api";
import { prepareCanvas } from "../../common/mappers/prepareCanvas";
import { fastJourneyBuilder } from "./builderFast";

export const exportJourneyJSON = (canvas: HashCanvas): JourneyStep => {
  const detachedCanvas = prepareCanvas(canvas);
  // find duplication
  // build subjourneys for ^
  // find duplicate siblings
  // build subjourneys for ^
  const journey = fastJourneyBuilder(detachedCanvas);
  return journey;
};

/**
 * SPM User Journey APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 7.2.0
 * Contact: tbc@postoffice.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Enabler } from './Enabler';
import { Node } from './Node';
import { NodeWithChildren } from './NodeWithChildren';
import { NodeWithEnablers } from './NodeWithEnablers';
import { NodeWithOutputData } from './NodeWithOutputData';
import { PromptActionList } from './PromptActionList';
import { PromptStepVisualProperties } from './PromptStepVisualProperties';
import { TypePromptStep } from './TypePromptStep';
import { HttpFile } from '../http/http';

/**
* Prompts the user for action
*/
export class PromptStep {
    'type': TypePromptStep;
    'title'?: string;
    'description': string;
    /**
    * Is used to show message number
    */
    'messageNumber'?: string;
    'actions'?: Array<PromptActionList>;
    'visual'?: PromptStepVisualProperties;
    'nodes'?: Array<Node>;
    'enablers'?: Array<Enabler>;
    /**
    * Values that should be preserved between journey steps. Key is dot-notation key and value is a template or simple value.
    */
    'outputData'?: { [key: string]: string; };

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "type",
            "baseName": "type",
            "type": "TypePromptStep",
            "format": ""
        },
        {
            "name": "title",
            "baseName": "title",
            "type": "string",
            "format": ""
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string",
            "format": ""
        },
        {
            "name": "messageNumber",
            "baseName": "messageNumber",
            "type": "string",
            "format": ""
        },
        {
            "name": "actions",
            "baseName": "actions",
            "type": "Array<PromptActionList>",
            "format": ""
        },
        {
            "name": "visual",
            "baseName": "visual",
            "type": "PromptStepVisualProperties",
            "format": ""
        },
        {
            "name": "nodes",
            "baseName": "nodes",
            "type": "Array<Node>",
            "format": ""
        },
        {
            "name": "enablers",
            "baseName": "enablers",
            "type": "Array<Enabler>",
            "format": ""
        },
        {
            "name": "outputData",
            "baseName": "outputData",
            "type": "{ [key: string]: string; }",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return PromptStep.attributeTypeMap;
    }

    public constructor() {
    }
}

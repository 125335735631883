import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import { YAMLNode } from "../types/customTypes";
import { titleize } from "../common/helpers/string/titleize";
import { nodeIconMapping } from "../common/helpers/nodeIcon/nodeIconMapping";

export interface SideBarButtonProps {
  node: YAMLNode;
}

const SideBarButton = ({ node }: SideBarButtonProps): JSX.Element => {
  const [isBeingDragged, setIsBeingDragged] = useState<boolean>(false);
  const { name, data } = node;
  const formattedName = titleize(name.slice(0, -4));

  /* eslint no-param-reassign: "error" */
  const onDragStart = (event: React.DragEvent<HTMLDivElement>, nodeType: string) => {
    const formattedNodeType = nodeType.toLocaleLowerCase();
    event.dataTransfer.setData("application/reactflow", formattedNodeType);
    event.dataTransfer.effectAllowed = "move";
    setIsBeingDragged(true);
  };

  const onDragEnd = () => {
    setIsBeingDragged(false);
  };

  return (
    <div>
      <div
        className="cursor-ew-resize dragndrop-btn w-22 p-1"
        data-cy={`${name}-draganddrop-button`}
        data-testid={`${formattedName}-side-bar-button`}
        key={name}
        onDragStart={(event) => onDragStart(event, name)}
        onDragEnd={onDragEnd}
        draggable
        data-tip
        data-for={name}
        data-offset="{'right': 20}"
      >
        <div className="h-6" data-testid={nodeIconMapping[name]?.testId || "unknown-test-id"}>
          <span className="material-icons">{nodeIconMapping[name]?.icon || "question_mark"}</span>
        </div>
        <p className="text-[10px] font-bold">{formattedName}</p>
      </div>
      {!isBeingDragged && (
        <ReactTooltip className="w-1/5" id={name} delayShow={400} globalEventOff="click" effect="solid">
          <span>
            <span className="font-extrabold underline">{formattedName}</span>
            {` - ${data.description}`}
          </span>
        </ReactTooltip>
      )}
    </div>
  );
};

export default SideBarButton;

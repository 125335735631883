import React from "react";
import { DataProps } from "../types/customTypes";

export interface NodeTitleProps {
  data: DataProps;
}

export type LabelChunk = {
  text: string;
  label: string;
  type: LabelChunkType;
  scope: string;
};

export enum LabelChunkType {
  TEXT = "text",
  DATA = "data",
}

const classMappings = {
  text: "lblCondText",
  data: "lblCondData",
};

const emptyLabel = "?";
const scopedClassPrefix = "lblCondScope_";

const replaceableScopes = ["outputData", "appData"];

const SCOPES_REGEX = /^[\w-]+\.{1}/;

const TAG_CHUNK = /\{\{\s*[^{}()]+\s*\}\}/;

// TODO: Move this to common consumption from JE / JB to reduce the duplication.
const TAG_CHUNK_SPLIT = /\{\{\s*[^{}()]+\s*\}\}|(?:(?!\{\{\s*[^{}()]+\s*\}\}).)*/gi;

// eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
const parseTags = (text: string): string[] => text.match(TAG_CHUNK) || [];
const parseChunks = (text: string): string[] => text.match(TAG_CHUNK_SPLIT) || [];
// eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
const parseScopes = (text: string): string[] => text.match(SCOPES_REGEX) || [];

export const labelChunk = (text: string): LabelChunk => {
  let label = text;
  let type = LabelChunkType.TEXT;
  let scope = "";

  if (label.trim() === emptyLabel) return { text, label: emptyLabel, type: LabelChunkType.TEXT, scope };

  const match = parseTags(text);
  if (match.length) {
    label = match[0].replace("{{", "").replace("}}", "").trim();
    type = LabelChunkType.DATA;

    const scopeMatches = parseScopes(label);

    if (scopeMatches.length > 0) {
      scope = scopeMatches[0].replace(".", "");
    }
    if (replaceableScopes.includes(scope)) {
      label = label.replace(`${scope}.`, "");
    }
  }

  return { text, label, type, scope };
};

export const chunkClass = (chunk: LabelChunk): string => {
  const scopeClass = chunk.scope.length > 0 ? `${scopedClassPrefix}${chunk.scope}` : "";
  return `${classMappings[chunk.type]} ${scopeClass}`;
};

// eslint-disable-next-line react/require-default-props
export const DisplayChunk = (props: { text: string; key?: string }): JSX.Element => {
  const { text, key } = props;
  const lc = labelChunk(text);
  const styleClass = chunkClass(lc);
  return (
    <span key={key} className={styleClass} title={lc.text}>
      {lc.label}
    </span>
  );
};

export const injectDataMarkup = (text: string): JSX.Element => {
  const labelChunks = parseChunks(text);
  const output: JSX.Element[] = labelChunks.map((chunk, index) => {
    const displayChunk = DisplayChunk({ text: chunk, key: `${index}` });
    return displayChunk;
  });
  return <span className="nodeLabelLine">{output}</span>;
};

export const conditionLabel = (props: { data: DataProps }): JSX.Element => {
  const { data } = props;
  if (!(data?.config?.type === "condition")) return <></>;
  const { config } = data;
  const given = config.given || "";
  const comparison = config.comparison || emptyLabel;
  const expected = config.expected || "";
  if (comparison.toLocaleLowerCase() === "eq" && given === expected && given !== emptyLabel) {
    return <span className="lblCatchAll">&darr;</span>;
  }
  const givenElement = injectDataMarkup(`${given}`);
  const expectedElement = injectDataMarkup(`${expected}`);
  return (
    <span className="nodeLabelCondCollection">
      {givenElement} <span className="lblCondition nodeLabelLine">{comparison}</span> {expectedElement}
    </span>
  );
};

const NodeLabel = ({ data }: NodeTitleProps): JSX.Element => {
  if (!data.config) return <></>;
  const { config } = data;
  const customActions = {
    condition: conditionLabel,
  };
  const sjName: string | undefined = config.name;
  const nodeTitle = config.title || "";
  const title = sjName || nodeTitle;
  const childElements = injectDataMarkup(title);

  if (Object.prototype.hasOwnProperty.call(customActions, config.type)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    return customActions[config.type]({ data }) as JSX.Element;
  }
  return <span className="nodeLabelCollection">{childElements}</span>;
};

export default NodeLabel;

import React, { useEffect, useState, useMemo } from "react";
import Editor, { loader } from "@monaco-editor/react";
import * as monaco from "monaco-editor";
import {
  Fullscreen,
  FullscreenExit,
  FormatAlignJustify,
  FormatAlignLeft,
  KeyboardDoubleArrowUp,
  KeyboardDoubleArrowDown,
} from "@mui/icons-material";
import { InputNodeForm } from "../types/customTypes";
import { dataTypeChecker } from "../common/helpers/string/dataTypeChecker";
import { jsonFormatter } from "../common/helpers/string/jsonFormatting";
import { titleize, equalJsonContent } from "../common/helpers/string";
import { debounce } from "../common/utils";

loader.config({ paths: { vs: "../js/monaco-editor/min/vs" } });
const blockJsonErrorsForKeys = ["data"];

const JsonEditor = ({ nodeType, handleOnChange, jsonData, jsonFieldName }: InputNodeForm): JSX.Element => {
  const [jsonValue, setJsonValue] = useState<string | null>(null);
  const [showJson, setShowJson] = useState<boolean>(true);
  const [error, setError] = useState<monaco.editor.IMarker[]>([]);
  const [expand, setExpand] = useState(false);

  useEffect(() => {
    setJsonValue(jsonFormatter(dataTypeChecker(jsonData)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const input = dataTypeChecker(jsonData);
    if (!equalJsonContent(jsonValue, input)) {
      setJsonValue(jsonFormatter(input));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jsonData]);

  const filteredErrors = (errors: monaco.editor.IMarker[]): monaco.editor.IMarker[] => {
    if (blockJsonErrorsForKeys.some((bk) => bk.toLowerCase() === jsonFieldName.toLowerCase())) return [];
    return errors;
  };

  const updateAndCommit = () => {
    if (jsonValue !== null) {
      handleOnChange({
        value: jsonValue,
        key: jsonFieldName,
        nodeType,
        isJSONEditor: true,
        jsonError: filteredErrors(error),
        commitToHistory: true,
      });
    }
  };

  const formatData = () => {
    setJsonValue(jsonFormatter(dataTypeChecker(jsonData)));
  };

  const unFormatData = () => {
    setJsonValue(dataTypeChecker(jsonData));
  };

  const toggleJSON = () => {
    if (expand) {
      setExpand(false);
      setShowJson(false);
    }
    setShowJson(!showJson);
  };

  const debouncedUpdate = useMemo<(change, err) => void>(() => {
    return debounce((input: string) => {
      handleOnChange({
        value: dataTypeChecker(input),
        key: jsonFieldName,
        nodeType,
        isJSONEditor: true,
        commitToHistory: false,
      });
    }, 500);
  }, [handleOnChange, jsonFieldName, nodeType]);

  useEffect(() => {
    if (jsonValue !== null) {
      handleOnChange({
        value: jsonValue,
        key: jsonFieldName,
        nodeType,
        isJSONEditor: true,
        jsonError: filteredErrors(error),
        commitToHistory: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const handleChange = (input: string | undefined | null) => {
    const checkedInput = dataTypeChecker(input);
    setJsonValue(checkedInput);
    debouncedUpdate(checkedInput, error);
  };

  return (
    <div>
      <div
        data-testid="json_Editor_Wrap"
        data-cy={`${jsonFieldName}-editor`}
        className={`flex flex-col border-2 border-black mb-2 mx-2 bg-white ${
          expand ? "absolute inset-x-1/4 inset-y-20 w-1/2 h-5/6 z-20" : "relative"
        }`}
      >
        <button
          aria-label="backdrop"
          type="button"
          className={`${
            expand ? "fixed bg-opacity-50" : "hidden bg-opacity-0"
          } w-screen h-screen bg-slate-400 top-0 left-0 `}
          onClick={() => setExpand(!expand)}
        />

        <div className="flex z-0">
          <button
            className="flex justify-center items-center bg-pol-purple w-10 text-white border-double border-4 hover:bg-purple-900"
            type="button"
            aria-label={showJson ? "hide" : "show"}
            onClick={() => toggleJSON()}
          >
            {showJson ? <KeyboardDoubleArrowUp /> : <KeyboardDoubleArrowDown />}
          </button>
          <label
            htmlFor={jsonFieldName}
            className="flex capitalize text-white text-sm font-bold p-2 w-full justify-center bg-pol-purple truncate"
          >
            {titleize(jsonFieldName)}
          </label>
          <div className="flex mx-auto">
            <button
              aria-label="format"
              className="flex justify-center items-center  bg-pol-purple w-10 text-white border-double border-4 hover:bg-purple-900"
              type="button"
              onClick={formatData}
            >
              <FormatAlignLeft />
            </button>
            <button
              aria-label="un-format"
              className="flex justify-center items-center bg-pol-purple w-10 text-white border-double border-4 hover:bg-purple-900"
              type="button"
              onClick={unFormatData}
            >
              <FormatAlignJustify />
            </button>
            <button
              aria-label={expand ? "unexpand" : "expand"}
              className="flex justify-center items-center  bg-pol-purple w-10 text-white border-double border-4 hover:bg-purple-900"
              type="button"
              onClick={() => setExpand(!expand)}
            >
              {expand ? <FullscreenExit /> : <Fullscreen />}
            </button>
          </div>
        </div>

        {(expand || showJson) && (
          <div data-testid="editor" className="h-full" onBlur={updateAndCommit}>
            <Editor
              data-testid="monaco-editor"
              defaultLanguage="json"
              height={`${expand ? "100%" : "45vh"}`}
              value={jsonValue === null ? "" : jsonValue}
              onChange={handleChange}
              options={{
                minimap: { enabled: false },
                wordWrap: "on",
                formatOnPaste: true,
                formatOnType: true,
                scrollbar: { alwaysConsumeMouseWheel: false },
              }}
              onValidate={(m) => setError(m)}
            />
          </div>
        )}
      </div>
      {expand && (
        <div
          className="mb-2 mx-2 bg-gray-200 flex items-center justify-center h-24"
          data-testid={`${jsonFieldName}-expand-placeholder`}
        >
          <h1>{titleize(jsonFieldName)}</h1>
        </div>
      )}
    </div>
  );
};

export default JsonEditor;

import React, { useState, useReducer, useMemo, useCallback } from "react";
import { ReactFlowInstance, Node } from "reactflow";
import { CacheNodeActions, cacheReducer } from "../reducers/cache";
import {
  DataProps,
  MouseData,
  ToastReturn,
  CacheStoreProps,
  DataPropsWithIdAndPosition,
  NodesAndEdges,
} from "../../types/customTypes";
import { getCanvasMousePosition } from "../helpers/position/mousePosition";
import { getClipEventData, setClipboard } from "../helpers/clipboard";
import { isValidJsonObject } from "../helpers/string/isValidJsonObject";
import { removeOrphanedEdges } from "../helpers/nodes/connectedEdges";

interface EditHook {
  editSelectionHandler: (event: NodesAndEdges) => void;
  editMouseHandler: (event: React.MouseEvent) => void;
  editEventHandler: (event: ClipboardEvent) => void;
  data: DataReturn;
}
interface TypeAndNotificationData {
  notification: ToastReturn;
  type: EditAction;
}
interface DataReturn {
  notification: ToastReturn;
  action: ActionReturn;
}

interface ActionReturn {
  type: EditAction;
  selection: NodesAndEdges;
  selectionData: CacheStoreProps;
  mouseData: MouseData;
  pasteIncrement: number;
}

interface EncodedCopyData {
  data: CacheStoreProps;
  id: string;
}

const clipboardIdentifier = "JBCBID";

export enum EditAction {
  COPY = "copy",
  PASTE = "paste",
  CUT = "cut",
  SELECTION = "selection",
  MOUSE = "mouse",
  DEFAULT = "default",
  ERROR = "error",
}

const initialCopyStore: CacheStoreProps = {
  nodes: [],
  edges: [],
};

const initialCopyCache: NodesAndEdges = {
  nodes: [],
  edges: [],
};
const buildEditMouseHandler = (handler: (event: React.MouseEvent) => void): EditHook["editMouseHandler"] => {
  return (event) => {
    handler(event);
  };
};

const initialNotification: ToastReturn = { message: "Initial notification", status: "default" };
const initialMouseData: MouseData = { mouseClick: false, mouseButton: 0, mousePosition: { x: 0, y: 0 } };
const initialData: TypeAndNotificationData = { notification: initialNotification, type: EditAction.DEFAULT };

export const encodeCopyCache = (data: CacheStoreProps): string => {
  const copiedDataWrap: EncodedCopyData = {
    data,
    id: clipboardIdentifier,
  };
  return encodeURIComponent(JSON.stringify(copiedDataWrap));
};

export const decodeCopyCache = (encodedData: string | null): CacheStoreProps | undefined => {
  if (encodedData === null) return undefined;
  let decodedRaw: string;
  try {
    decodedRaw = decodeURIComponent(encodedData);
  } catch (e) {
    return undefined;
  }
  if (!isValidJsonObject(decodedRaw)) {
    return undefined;
  }
  try {
    const parsedData: EncodedCopyData = JSON.parse(decodedRaw) as EncodedCopyData;
    if (parsedData?.id === clipboardIdentifier) {
      return parsedData.data;
    }
  } catch (e) {
    return undefined;
  }

  return undefined;
};

const shouldInterceptEvent = (
  evt: ClipboardEvent,
  allowedElements: string[] = ["TEXTAREA", "INPUT", "SELECT"]
): boolean => {
  const targetElement: string | null = (evt.target as HTMLElement).tagName;
  if (allowedElements.find((n) => n === targetElement)) {
    return false;
  }
  return true;
};

export const useCopyCutPaste = (
  reactFlowInstance: ReactFlowInstance | null,
  reactFlowWrapper: React.MutableRefObject<HTMLDivElement>
): EditHook => {
  const [cacheStore, cacheDispatch] = useReducer(cacheReducer, initialCopyStore);
  const [typeAndNote, setTypeAndNote] = useState<TypeAndNotificationData>(initialData);
  const [copyCache, setCopyCache] = useState<NodesAndEdges>(initialCopyCache);
  const [cacheStoreElements, setCacheStoreElements] = useState<NodesAndEdges>(initialCopyCache);
  const [mouseData, setMouseData] = useState<MouseData>(initialMouseData);
  const [pasteIncrement, setPasteIncrement] = useState(0);

  const onMouseClick = useCallback(
    (event: React.MouseEvent): void => {
      if (reactFlowInstance === null) return;
      const position = getCanvasMousePosition(event, reactFlowWrapper, reactFlowInstance);
      setMouseData({ mouseClick: true, mouseButton: event.button, mousePosition: position });
      setTypeAndNote({
        notification: { message: "Mouse Click", status: "default" },
        type: EditAction.MOUSE,
      });
      setPasteIncrement(0);
    },
    [reactFlowInstance, reactFlowWrapper]
  );

  const editMouseHandler = useMemo(() => {
    return buildEditMouseHandler(onMouseClick);
  }, [onMouseClick]);

  const editSelectionHandler = useCallback((event: NodesAndEdges) => {
    setCopyCache(event);
    setTypeAndNote({
      notification: { message: "Selected", status: "default" },
      type: EditAction.SELECTION,
    });
  }, []);

  const copy = useCallback(
    async (evt: ClipboardEvent): Promise<void> => {
      setPasteIncrement(0);
      if (!shouldInterceptEvent(evt)) return;
      if (copyCache.nodes.length === 0) {
        await setClipboard("");
        setTypeAndNote({
          notification: { message: "Cleared copy cache.", status: "success" },
          type: EditAction.COPY,
        });
        return;
      }
      const cleansedCopyCache = removeOrphanedEdges(copyCache);
      const selectedNodes: DataPropsWithIdAndPosition[] = cleansedCopyCache.nodes.map((node: Node<DataProps>) => {
        return { ...node.data, id: node.id, position: node.position };
      });

      setCacheStoreElements(cleansedCopyCache);
      await setClipboard(encodeCopyCache({ nodes: [...selectedNodes], edges: [...cleansedCopyCache.edges] }));

      cacheDispatch({
        type: CacheNodeActions.COPY_NODE,
        payload: { selectedElement: { nodes: [...selectedNodes], edges: [...copyCache.edges] } },
      });
      setTypeAndNote({
        notification: { message: "Copied!", status: "success" },
        type: EditAction.COPY,
      });
      setMouseData({ ...mouseData, mouseClick: false } as MouseData);
    },
    [copyCache, mouseData]
  );

  const cut = useCallback(
    async (evt: ClipboardEvent) => {
      setPasteIncrement(0);
      if (!shouldInterceptEvent(evt)) return;
      if (copyCache.nodes.length === 0) {
        setTypeAndNote({
          notification: { message: "No selected elements.", status: "error" },
          type: EditAction.ERROR,
        });
        return;
      }
      const cleansedCopyCache = removeOrphanedEdges(copyCache);
      if (cleansedCopyCache.nodes.find((element) => element.type === "journeystep")) {
        setTypeAndNote({
          notification: { message: "Unable to delete journey node.", status: "error" },
          type: EditAction.ERROR,
        });

        return;
      }

      const selectedNodes: DataPropsWithIdAndPosition[] = cleansedCopyCache.nodes.map((node: Node<DataProps>) => {
        return { ...node.data, id: node.id, position: node.position };
      });
      await setClipboard(encodeCopyCache({ nodes: [...selectedNodes], edges: [...cleansedCopyCache.edges] }));

      setCacheStoreElements(cleansedCopyCache);

      cacheDispatch({
        type: CacheNodeActions.COPY_NODE,
        payload: { selectedElement: { nodes: [...selectedNodes], edges: [...cleansedCopyCache.edges] } },
      });
      setTypeAndNote({
        notification: { message: "Cut!", status: "success" },
        type: EditAction.CUT,
      });
      setMouseData({ ...mouseData, mouseClick: false } as MouseData);
    },
    [copyCache, mouseData]
  );

  const paste = useCallback(
    (evt: ClipboardEvent) => {
      const decodedClipboard = decodeCopyCache(getClipEventData(evt));
      if (!shouldInterceptEvent(evt)) {
        if (decodedClipboard !== undefined) {
          evt.preventDefault();
        }
        return;
      }

      if (decodedClipboard === undefined || decodedClipboard.nodes.length === 0) {
        setTypeAndNote({
          notification: { message: "No copied elements.", status: "error" },
          type: EditAction.ERROR,
        });
        return;
      }

      cacheDispatch({
        type: CacheNodeActions.COPY_NODE,
        payload: { selectedElement: { ...decodedClipboard } },
      });

      setPasteIncrement(pasteIncrement + 1);

      setTypeAndNote({
        notification: { message: "Pasted!", status: "success" },
        type: EditAction.PASTE,
      });
    },
    [pasteIncrement]
  );

  const editEventHandler = useCallback(
    (ev: ClipboardEvent): void => {
      const actions = { copy, cut, paste };
      if (Object.prototype.hasOwnProperty.call(actions, ev.type)) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        actions[ev.type](ev);
      }
    },
    [cut, copy, paste]
  );

  return {
    editSelectionHandler,
    editMouseHandler,
    editEventHandler,
    data: {
      notification: typeAndNote.notification,
      action: {
        type: typeAndNote.type,
        selection: cacheStoreElements,
        selectionData: cacheStore,
        mouseData,
        pasteIncrement,
      },
    },
  };
};

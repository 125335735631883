import { produce, Draft } from "immer";
import { CacheStoreProps } from "../../../types/customTypes";
import { CopyNodeActions, CacheNodeActions } from "./actions";
import { structuredClone } from "../../utils";

export const cacheReducer = (cacheStore: CacheStoreProps, action: CopyNodeActions): CacheStoreProps => {
  const newState = produce(cacheStore, (draft): Draft<CacheStoreProps> => {
    switch (action.type) {
      case CacheNodeActions.COPY_NODE:
        return structuredClone(action.payload.selectedElement);
      default:
        break;
    }
    return draft;
  });
  return newState;
};

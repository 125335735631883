import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

interface ButtonWithLoaderProps {
  buttonTitle: string;
  handleOnClick: () => void;
  isDisabled: boolean;
  isLoading: boolean;
}

const ButtonWithLoader = ({
  buttonTitle,
  handleOnClick,
  isDisabled,
  isLoading,
}: ButtonWithLoaderProps): JSX.Element => {
  return (
    <button
      type="submit"
      onClick={handleOnClick}
      className={`${
        !isDisabled ? "bg-pol-red" : "bg-disabled-gray cursor-not-allowed opacity-50"
      } w-40 p-2 text-white rounded-sm relative`}
      disabled={isDisabled || isLoading}
    >
      <div className="flex justify-center items-center relative">
        <div className="flex justify-center items-center absolute left-0">
          {isLoading && <ClipLoader data-testid="clip-loader" size={25} color="white" />}
        </div>
        <p>{buttonTitle}</p>
      </div>
    </button>
  );
};

export default ButtonWithLoader;

import { useState, useEffect } from "react";
import yaml from "js-yaml";
import yamlData from "../../fixtures/openapi.yaml";
import { YAMLData, YAMLSchema } from "../../types/customTypes";

export const useFetchYamlData = (): YAMLSchema | null => {
  const [convertedYamlData, setConvertedYamlData] = useState<YAMLSchema | null>(null);

  useEffect(() => {
    fetch(yamlData as RequestInfo)
      .then((res) => res.text())
      .then((text) => {
        const convertedYaml = yaml.load(text) as YAMLData;
        const yamlSchema = convertedYaml.components.schemas;
        setConvertedYamlData(yamlSchema);
      })
      .catch(() => {
        setConvertedYamlData(null);
      });
  }, []);
  return convertedYamlData;
};

import React from "react";

export interface IMessageView {
  message: string;
}

export const MESSAGE_VIEW_TEST_ID = "messsage_view_test_id";
const MessageView = ({message}: IMessageView):JSX.Element => {
  return (
    <div>
      {message.split(",").map((element, index) => {
        const testID = `${MESSAGE_VIEW_TEST_ID}_${index}`;
        return (
          <div data-testid={testID} key={testID}>
            {element}
            <br />
          </div>
        );
      })}
    </div>
  );
};
export default MessageView;

/**
 * SPM User Journey APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 7.2.0
 * Contact: tbc@postoffice.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ColumnsData } from './ColumnsData';
import { Enabler } from './Enabler';
import { Node } from './Node';
import { NodeWithChildren } from './NodeWithChildren';
import { NodeWithEnablers } from './NodeWithEnablers';
import { NodeWithOutputData } from './NodeWithOutputData';
import { NodeWithValidations } from './NodeWithValidations';
import { SelectionTableStepVisualProperties } from './SelectionTableStepVisualProperties';
import { TypeSelectionTableStep } from './TypeSelectionTableStep';
import { ValidationField } from './ValidationField';
import { HttpFile } from '../http/http';

/**
* Used to statically or dynamically generate a list. It allows user to select/choose.
*/
export class SelectionTableStep {
    'type': TypeSelectionTableStep;
    'title'?: string;
    'columns': Array<ColumnsData>;
    'table': string | Array<any>;
    'visual'?: SelectionTableStepVisualProperties;
    /**
    * Additional parameters for functionality of specific table variants
    */
    'params'?: string | any;
    'nodes'?: Array<Node>;
    'enablers'?: Array<Enabler>;
    /**
    * Values that should be preserved between journey steps. Key is dot-notation key and value is a template or simple value.
    */
    'outputData'?: { [key: string]: string; };
    /**
    * Used to ensure the data entered by a user is valid
    */
    'inputValidation'?: Array<ValidationField>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "type",
            "baseName": "type",
            "type": "TypeSelectionTableStep",
            "format": ""
        },
        {
            "name": "title",
            "baseName": "title",
            "type": "string",
            "format": ""
        },
        {
            "name": "columns",
            "baseName": "columns",
            "type": "Array<ColumnsData>",
            "format": ""
        },
        {
            "name": "table",
            "baseName": "table",
            "type": "string | Array<any>",
            "format": ""
        },
        {
            "name": "visual",
            "baseName": "visual",
            "type": "SelectionTableStepVisualProperties",
            "format": ""
        },
        {
            "name": "params",
            "baseName": "params",
            "type": "string | any",
            "format": ""
        },
        {
            "name": "nodes",
            "baseName": "nodes",
            "type": "Array<Node>",
            "format": ""
        },
        {
            "name": "enablers",
            "baseName": "enablers",
            "type": "Array<Enabler>",
            "format": ""
        },
        {
            "name": "outputData",
            "baseName": "outputData",
            "type": "{ [key: string]: string; }",
            "format": ""
        },
        {
            "name": "inputValidation",
            "baseName": "inputValidation",
            "type": "Array<ValidationField>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return SelectionTableStep.attributeTypeMap;
    }

    public constructor() {
    }
}

/**
 * SPM User Journey APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 7.2.0
 * Contact: tbc@postoffice.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Enabler } from './Enabler';
import { InfoListData } from './InfoListData';
import { InfoStep } from './InfoStep';
import { InfoStepVisualProperties } from './InfoStepVisualProperties';
import { InputStep } from './InputStep';
import { Node } from './Node';
import { OptionsStep } from './OptionsStep';
import { SelectionStep } from './SelectionStep';
import { TypeInfoStep } from './TypeInfoStep';
import { ValidationField } from './ValidationField';
import { HttpFile } from '../http/http';

export class InputGroupNode {
    'nodes'?: Array<Node>;
    'enablers'?: Array<Enabler>;
    /**
    * Values that should be preserved between journey steps. Key is dot-notation key and value is a template or simple value.
    */
    'outputData'?: { [key: string]: string; };
    /**
    * Used to ensure the data entered by a user is valid
    */
    'inputValidation'?: Array<ValidationField>;
    /**
    * Values that are used to pre-populate step with some initial data.
    */
    'defaultValues'?: { [key: string]: string; };
    'type': TypeInfoStep;
    /**
    * Displayed as header text
    */
    'title': string;
    /**
    * Displayed as normal text
    */
    'description'?: string;
    'visual'?: InfoStepVisualProperties;
    /**
    * Data used to display list of key/value pairs. Only used with \"list\" view.
    */
    'params'?: string | Array<InfoListData>;

    static readonly discriminator: string | undefined = "type";

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "nodes",
            "baseName": "nodes",
            "type": "Array<Node>",
            "format": ""
        },
        {
            "name": "enablers",
            "baseName": "enablers",
            "type": "Array<Enabler>",
            "format": ""
        },
        {
            "name": "outputData",
            "baseName": "outputData",
            "type": "{ [key: string]: string; }",
            "format": ""
        },
        {
            "name": "inputValidation",
            "baseName": "inputValidation",
            "type": "Array<ValidationField>",
            "format": ""
        },
        {
            "name": "defaultValues",
            "baseName": "defaultValues",
            "type": "{ [key: string]: string; }",
            "format": ""
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "TypeInfoStep",
            "format": ""
        },
        {
            "name": "title",
            "baseName": "title",
            "type": "string",
            "format": ""
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string",
            "format": ""
        },
        {
            "name": "visual",
            "baseName": "visual",
            "type": "InfoStepVisualProperties",
            "format": ""
        },
        {
            "name": "params",
            "baseName": "params",
            "type": "string | Array<InfoListData>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return InputGroupNode.attributeTypeMap;
    }

    public constructor() {}
}

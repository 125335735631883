/**
 * SPM User Journey APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 7.2.0
 * Contact: tbc@postoffice.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Enabler } from './Enabler';
import { NodeWithEnablers } from './NodeWithEnablers';
import { NodeWithOutputData } from './NodeWithOutputData';
import { TypeUndoStep } from './TypeUndoStep';
import { HttpFile } from '../http/http';

/**
* Undo the number of journey steps.
*/
export class UndoStep {
    'type': TypeUndoStep;
    /**
    * Number of steps to undo.
    */
    'numberOfSteps'?: number;
    /**
    * Values that should be preserved between journey steps. Key is dot-notation key and value is a template or simple value.
    */
    'outputData'?: { [key: string]: string; };
    'enablers'?: Array<Enabler>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "type",
            "baseName": "type",
            "type": "TypeUndoStep",
            "format": ""
        },
        {
            "name": "numberOfSteps",
            "baseName": "numberOfSteps",
            "type": "number",
            "format": ""
        },
        {
            "name": "outputData",
            "baseName": "outputData",
            "type": "{ [key: string]: string; }",
            "format": ""
        },
        {
            "name": "enablers",
            "baseName": "enablers",
            "type": "Array<Enabler>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return UndoStep.attributeTypeMap;
    }

    public constructor() {
    }
}

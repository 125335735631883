import { Node as CanvasNode, Edge, MarkerType } from "reactflow";
import { v4 } from "uuid";
import { Node, JourneyStep, TypeSubJourneyStep } from "../../openapi/api";
import { getNodeData } from "../../common/actions/nodeMapper";
import {
  NodeWithPosition,
  NodeWithSubJourneyRef,
  WithSubJourneyRef,
  NodePosition,
  JBuilderNode,
  ImportedCanvas as Canvas,
} from "../types";
import { DataProps, ImportStatus, JourneyImportStatus, JSONObject } from "../../types/customTypes";
import { toastMessages } from "../../common/constants/toastMessages/UploadJourneyToast";
import { JourneyDownloadTypes } from "../../common/constants/journeyDownloadTypes";
import { cleanUpConfigNodes } from "../../common/helpers/nodeHelpers/cleanUpConfigNodes";
import { structuredClone } from "../../common/utils";
import { getNodesWithSjName } from "../../common/helpers/nodes";

const XSpacing = 150;
const XPadding = 50;
const YSpacing = 100;
const YPadding = 25;

/*
 Simple buffer to store calculated JBuilderNodes based on identity nodes.
 Buffer stores only JBuilderNodes that can be re-used.
 It also maintains sub-journey lookup as one is never used without the other, so why not one struct.
*/
export interface SubJourneyBuffer {
  get: (identityNodes: WithSubJourneyRef[]) => JBuilderNode[] | null;
  set: (identityNodes: WithSubJourneyRef[], nodesToCache: JBuilderNode[]) => boolean;
  getNodes: (subJourneyName: string) => Node[];
  nodesID: (identityNodes: WithSubJourneyRef[]) => { id: string; unique: boolean };
}

/*
 Creates new SubJourney buffer that is used to deal with sub-journeys by:
  * caching computed JBuilderNodes
  * calculating IDs of sets of Journey Nodes
  * accessing sub-journeys by name
*/
export const newSubJourneyBuffer = (givenSubJourneys: JourneyStep["subJourneys"]): SubJourneyBuffer => {
  const subJourneys = givenSubJourneys || {};
  const jbNodesCache: Record<string, JBuilderNode[]> = {};
  const emptyKey = "empty";

  const nodesID = (identityNodes: WithSubJourneyRef[]): { id: string; unique: boolean } => {
    if (identityNodes.length === 0) {
      return { id: emptyKey, unique: false };
    }
    if (identityNodes.every((n) => n.subJourneyRef !== null)) {
      const id = identityNodes.map((n) => n.subJourneyRef).join("|");
      return { id, unique: false };
    }
    return { id: v4(), unique: true };
  };

  const set = (identityNodes: WithSubJourneyRef[], nodesToCache: JBuilderNode[]): boolean => {
    const { id, unique } = nodesID(identityNodes);
    if (!unique) {
      jbNodesCache[id] = nodesToCache;
      return true;
    }
    return false;
  };

  const get = (identityNodes: WithSubJourneyRef[]): JBuilderNode[] | null => {
    const { id } = nodesID(identityNodes);
    return jbNodesCache[id] || null;
  };

  const getNodes = (name: string): Node[] => {
    if (!subJourneys[name]) {
      return [];
    }
    return subJourneys[name];
  };

  return Object.freeze({
    get,
    set,
    getNodes,
    nodesID,
  });
};

export const nodeToCanvasData = (node: NodeWithPosition, id: string): CanvasNode<DataProps> => {
  const newNode = getNodeData(id, `${node.type.toLowerCase()}step`, { x: 0, y: canvasPositionY(node.position) });
  const tuples = Object.entries(node);
  const { config }: DataProps = newNode.data;
  const keys: string[] = Object.keys(config);
  tuples.forEach(([key, value]: [string, string | unknown]) => {
    if (keys.includes(key)) {
      newNode.data.config[`${key}`] = value;
    }
  });
  /*
   * FIXME: Canvas Node should work with correct case types. e.g. multiBarcode vs multibarcode
   * Temporary fix to make types lower case to match current workings.
   */
  newNode.data.config.type = newNode.data.config.type.toLowerCase();

  return { ...newNode };
};

interface IdentifiablePosition {
  id: string;
  position: NodePosition;
}

interface AlignedPosition extends IdentifiablePosition {
  multiplier: number;
}
export const calculateNodePositioning = (positioningArray: IdentifiablePosition[]): AlignedPosition[] => {
  const positionGroups = positioningArray.reduce((memo, ip) => {
    if (!memo[ip.position.length]) {
      return { ...memo, [ip.position.length]: [ip] };
    }
    return { ...memo, [ip.position.length]: [...memo[ip.position.length], ip] };
  }, {} as { [key: number]: IdentifiablePosition[] });

  const maxRowLength = Object.entries(positionGroups).reduce((memo, positionGroup) => {
    return positionGroup[1].length > memo ? positionGroup[1].length : memo;
  }, 0);

  const middle = Math.floor(maxRowLength / 2);

  const result: AlignedPosition[] = [];

  Object.entries(positionGroups).forEach((positionGroup) => {
    const halfGroupSize = Math.floor(positionGroup[1].length / 2);
    const lowerBound = middle - halfGroupSize;
    const upperBound = middle + halfGroupSize;

    let counter = 0;
    for (let i = lowerBound; i <= upperBound; i += 1) {
      if (!(i === middle && positionGroup[1].length % 2 === 0)) {
        result.push({ ...positionGroup[1][counter], multiplier: i });
        counter += 1;
      }
    }
  });

  return result;
};

/*
  Calculate X position in canvas based on position in journey tree
*/

/*
  Calculate Y position in canvas based on position in journey tree
*/

const canvasPositionY = (nodePosition: NodePosition): number => {
  const yMult = nodePosition.length;
  const result = yMult * YSpacing + YPadding;
  return result;
};

export const journeyNodeToJBuilderNode = (node: NodeWithPosition & WithSubJourneyRef): JBuilderNode => {
  const nodeID = v4();
  const jbNode = {
    id: nodeID,
    journeyNode: node,
    canvasNode: {
      ...nodeToCanvasData(node, nodeID),
    },
  } as JBuilderNode;

  return jbNode;
};

/*
  Map all the journey nodes to JBuilder nodes
  while expanding each sub-journey to the nodes it contains.
*/

/*
sjNodesGenerator looks for the root node (index 0) of the SJ
In addition to the subJourneyRef, it also adds sjName, which we eventually pass into node config
If not 0 index then returns sjNodes as before.
*/
export const sjNodesGenerator = (internalSJs: Node[], sjName: string) => {
  const sjNodes = internalSJs.map((sjNode, idx) => {
    if (idx === 0) return { ...sjNode, subJourneyRef: sjName, sjName };
    return { ...sjNode, subJourneyRef: sjName };
  });
  return sjNodes as NodeWithSubJourneyRef[] | undefined;
};

export const expandNodes = (
  nodes: Node[],
  parentPosition: NodePosition,
  subJourneyBuffer: SubJourneyBuffer // TODO: should we get rid of this buffer here? Not used for anything but accessing sub-journey
): JBuilderNode[] => {
  const nodesToExpand: (Node | NodeWithSubJourneyRef)[] = [...nodes];
  const expandedNodes: NodeWithSubJourneyRef[] = [];

  // Expand all sub-journeys to the non sub-journey nodes.
  while (nodesToExpand.length > 0) {
    const cNode = nodesToExpand.shift() as Node;
    if (cNode.type === TypeSubJourneyStep.SubJourney) {
      const sjName = cNode.name;
      const internalSJs = subJourneyBuffer.getNodes(sjName);
      const sjNodes = sjNodesGenerator(internalSJs, sjName);
      if (internalSJs.length && sjNodes) {
        nodesToExpand.unshift(...sjNodes);
      } else {
        expandedNodes.push({ subJourneyRef: null, ...cNode });
      }
    } else {
      expandedNodes.push({ subJourneyRef: null, ...cNode });
    }
  }

  const mappedNodes = expandedNodes.map((node, counter) => {
    return journeyNodeToJBuilderNode({ ...node, position: [...parentPosition, counter] });
  }, [] as JBuilderNode[]);

  return mappedNodes;
};

export const edgeID = (edge: Edge): string => {
  const { source, sourceHandle, target, targetHandle } = edge;
  return `reactflow__edge-${source}${String(sourceHandle)}-${target}${String(targetHandle)}`;
};

/*
  Create connection from given parent node's bottom to first child node's top.
*/
export const parentToChildrenEdge = (parentNode: JBuilderNode, childNodes: JBuilderNode[]): Edge | null => {
  if (childNodes.length === 0) {
    return null;
  }
  const newEdge: Edge = {
    source: parentNode.id,
    sourceHandle: "B",
    target: childNodes[0].id,
    targetHandle: "T",
    id: "unknown",
    markerEnd: { type: MarkerType.Arrow },
    style: { strokeWidth: ".10rem" },
  };
  newEdge.id = edgeID(newEdge);
  return newEdge;
};

/*
  Create connections for all given nodes from left nodes right to right nodes left.
*/
export const childToChildEdges = (nodes: JBuilderNode[]): Edge[] => {
  const edgeHolder: Edge[] = [];
  let previousNode = nodes[0];
  for (let i = 1; i < nodes.length; i += 1) {
    const newEdge: Edge = {
      source: previousNode.id,
      sourceHandle: "R",
      target: nodes[i].id,
      targetHandle: "L",
      id: "unknown",
      markerEnd: { type: MarkerType.Arrow },
      style: { strokeWidth: ".10rem" },
    };
    newEdge.id = edgeID(newEdge);
    edgeHolder.push(newEdge);
    previousNode = nodes[i];
  }
  return edgeHolder;
};

export const importErrorHandler = (inputType: string): ImportStatus => {
  const result = {
    nodes: [],
    edges: [],
  };
  const center = { x: 0, y: 0 };
  switch (inputType) {
    case JourneyDownloadTypes.FILE:
      return {
        importStatus: JourneyImportStatus.ERROR_FILE_INPUT,
        responseMsg: toastMessages.errorFile,
        result,
        center,
      };
    case JourneyDownloadTypes.TEXT:
      return {
        importStatus: JourneyImportStatus.ERROR_TEXT_INPUT,
        responseMsg: toastMessages.errorTextField,
        result,
        center,
      };
    case JourneyDownloadTypes.CLOUD:
      return {
        importStatus: JourneyImportStatus.ERROR_CLOUD_INPUT,
        responseMsg: toastMessages.errorDatabase,
        result,
        center,
      };
    default:
      return {
        importStatus: JourneyImportStatus.ERROR_DEFAULT,
        responseMsg: toastMessages.errorDefault,
        result,
        center,
      };
  }
};

const balanceNodes = (canvas: Canvas): Canvas => {
  const positions = canvas.nodes.map((n: JBuilderNode) => ({ position: n.journeyNode.position, id: n.id }));
  const sortedPositions = positions.sort((a, b) => {
    const aj = a.position.join("-");
    const bj = b.position.join("-");
    if (aj > bj) {
      return 1;
    }
    if (aj < bj) {
      return -1;
    }
    return 0;
  });

  const multi = calculateNodePositioning(sortedPositions);

  canvas.nodes.forEach((n) => {
    const nmulti = multi.find((m) => m.id === n.id);
    const xPosition = (nmulti?.multiplier || 0) * XSpacing + XPadding;
    // eslint-disable-next-line no-param-reassign
    n.canvasNode.position.x = xPosition;
  });
  return canvas;
};

export const removeUsedSjNames = (balancedCanvas: Canvas, usedSjNames: string[]) => {
  const { nodes, edges } = balancedCanvas;
  const { canvasNode } = nodes[0];
  const canvasData = canvasNode.data as DataProps;
  const sjObj = canvasData.config.subJourneys;
  if (!sjObj) return { nodes, edges };
  const res = Object.entries(sjObj).reduce((acc: JSONObject, entry) => {
    const [key, val] = entry;
    return usedSjNames.includes(key) ? { ...acc } : { ...acc, [key]: val };
  }, {});
  (canvasNode.data as DataProps).config.subJourneys = res;
  return { nodes, edges };
};

/*
  Traverses given journey and builds canvas edges and nodes.
*/
export const createCanvas = (journey: JourneyStep): Canvas => {
  const buffer = newSubJourneyBuffer(journey.subJourneys);
  const rootNode = journeyNodeToJBuilderNode({ ...journey, position: [], subJourneyRef: null });
  const canvasRootNode = structuredClone(cleanUpConfigNodes([rootNode]));
  const canvas = { nodes: canvasRootNode, edges: [] as Edge[] };
  const stack: JBuilderNode[] = [rootNode];
  while (stack.length) {
    let newNodes: JBuilderNode[] = [];
    let newEdges: (Edge | null)[] = [];
    const node = stack.pop() as JBuilderNode;
    const childNodes: Node[] = "nodes" in node.journeyNode ? node.journeyNode.nodes || [] : [];
    // Return cached re-usable JBuilder nodes when possible
    const expandedNodes = expandNodes(childNodes, node.journeyNode.position, buffer);
    const identityNodes = expandedNodes.map((en) => en.journeyNode);
    const existingSJNodes = buffer.get(identityNodes);

    if (existingSJNodes) {
      newEdges = [parentToChildrenEdge(node, existingSJNodes)];
    } else {
      newNodes = expandedNodes;
      newEdges = [parentToChildrenEdge(node, newNodes), ...childToChildEdges(newNodes)];
      // Writes to buffer are safe as internally it decided to cache or not
      buffer.set(identityNodes, newNodes);
    }
    canvas.nodes.push(...cleanUpConfigNodes(structuredClone(newNodes)));
    canvas.edges.push(...(newEdges.filter((e) => e !== null) as Edge[]));
    stack.push(...newNodes);
  }
  const balancedCanvas = balanceNodes(canvas);
  const nodesWithSjName = Object.values(getNodesWithSjName(balancedCanvas.nodes));
  const sanitisedCanvas = removeUsedSjNames(balancedCanvas, nodesWithSjName);
  return sanitisedCanvas;
};

/*
   Returns object that allows to import journey JSON data as string
  */

export const getJourneyCenter = (nodesArray: JBuilderNode[]): { x: number; y: number } => {
  const x = Math.max(...nodesArray.map((node) => node.canvasNode.position.x)) / 2;
  const y = Math.max(...nodesArray.map((node) => node.canvasNode.position.y)) / 2;
  return { x, y };
};

export const oldStyleImport = (jsonInput: string, inputType: string): ImportStatus => {
  try {
    const canvas = createCanvas(JSON.parse(jsonInput) as JourneyStep);
    const { nodes, edges } = canvas;
    const center = getJourneyCenter(nodes);
    return {
      importStatus: JourneyImportStatus.SUCCESS,
      responseMsg: toastMessages.success,
      result: {
        nodes: nodes.map((node) => node.canvasNode),
        edges,
      },
      center,
    };
  } catch (error) {
    return importErrorHandler(inputType);
  }
};

export const importJourneyJSON = (json: string): Canvas => createCanvas(JSON.parse(json) as JourneyStep);

import { osKey, BaseKeyString as Key } from "../helpers/platform";
import { flowKeyName, FlowAction } from "../helpers/modifierKeys";
import { titleize } from "../helpers/string/titleize";

type KeyNameValue = {
  name: string;
  value: string;
};

// !TODO: Look into ways to link other keys automatically rather than manually.

export const keys: KeyNameValue[] = [
  {
    name: "Zoom",
    value: "[<Spacebar> + Scroll] or [Trackpad Pinch].",
  },
  {
    name: "Pan",
    value: "[Drag canvas] or [<Spacebar> + drag] (reduces accidental node interaction).",
  },
  {
    name: "Node Multi Select",
    value: `[<${titleize(flowKeyName(FlowAction.TOGGLE_SELECT))}> + click] or [<${titleize(
      flowKeyName(FlowAction.AREA_SELECT)
    )}> + drag]`,
  },
  {
    name: "Node Navigation",
    value: `Parent/Child [↑/↓], Siblings[←/→] [hold <${titleize(osKey(Key.SHIFT))}> for
      auto-switch]`,
  },
  {
    name: "Search Navigation",
    value: `Next/Previous [<Enter> / <${titleize(osKey(Key.SHIFT))}>+<Enter>]`,
  },
];

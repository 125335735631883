import { SortableItemProps, SortableList } from "@thaddeusjiang/react-sortable-list";
import React from "react";
import { SortableItemPropsWithConfig } from "../types/customTypes";
import GroupNodeSortableItem from "./GroupNodeSortableItem";

interface Props {
  groupNodes: SortableItemPropsWithConfig[];
  setGroupNodes: React.Dispatch<React.SetStateAction<SortableItemPropsWithConfig[] | null>>;
  setSelected: React.Dispatch<React.SetStateAction<SortableItemPropsWithConfig | null>>;
  handleDelete: (id: string) => void;
  selected: SortableItemPropsWithConfig | null;
}

const GroupNodeSortableList = ({
  groupNodes,
  setGroupNodes,
  setSelected,
  handleDelete,
  selected,
}: Props): JSX.Element => {
  return (
    <div className="h-5/6 overflow-auto">
      <SortableList
        items={groupNodes as SortableItemProps[]}
        setItems={setGroupNodes as React.Dispatch<React.SetStateAction<SortableItemProps[]>>}
      >
        {({ items }): JSX.Element => (
          <div className="space-y-4 flex flex-col">
            {(items as SortableItemPropsWithConfig[]).map((item: SortableItemPropsWithConfig): JSX.Element => {
              if (item === null) {
                return <div />;
              }
              return (
                <GroupNodeSortableItem
                  setSelected={setSelected}
                  handleDelete={handleDelete}
                  item={item}
                  key={item.id}
                  selected={selected}
                />
              );
            })}
          </div>
        )}
      </SortableList>
    </div>
  );
};

export default GroupNodeSortableList;

interface componentToIconMapping {
  [key: string]: {
    icon: string;
    testId: string;
  };
}

export const nodeIconMapping: componentToIconMapping = {
  AbortStep: { icon: "logout", testId: "logout-icon" },
  BarcodeStep: { icon: "qr_code", testId: "qr-code-icon" },
  ChoiceStep: { icon: "format_list_bulleted", testId: "format-list-bulleted-icon" },
  CompleteStep: { icon: "check_circle_outline", testId: "check-circle-outline-icon" },
  ConditionStep: { icon: "alt_route", testId: "alt-route-icon" },
  CountryStep: { icon: "public", testId: "public-icon" },
  InfoStep: { icon: "info", testId: "info-icon" },
  InputGroupStep: { icon: "featured_play_list", testId: "featured-play-list-icon" },
  InputStep: { icon: "subtitles", testId: "subtitles-icon" },
  MultiBarcodeStep: { icon: "content_copy", testId: "content-copy-icon" },
  OptionsStep: { icon: "tune", testId: "tune-icon" },
  SelectionTableStep: { icon: "grid_on", testId: "grid-on-icon" },
  SelectionStep: { icon: "check_box", testId: "check-box-icon" },
  PromptStep: { icon: "announcement", testId: "announcement-icon" },
  SubJourneyStep: { icon: "subdirectory_arrow_right", testId: "subdirectory-arrow-right-icon" },
  UndoStep: { icon: "undo", testId: "undo-icon" },
};

import React from "react";
import { AttributeConfigProps, ElementChangeFunction } from "../types/customTypes";
import { titleize } from "../common/helpers/string/titleize";

interface AttributeStringFieldProps {
  textBoxParams: string;
  config: AttributeConfigProps;
  handleOnChange: ElementChangeFunction;
  showError: (attri: string) => boolean;
}
const AttributeStringField = ({
  textBoxParams,
  config,
  handleOnChange,
  showError,
}: AttributeStringFieldProps): JSX.Element => {
  return (
    <div key={textBoxParams} className="mb-2 p-2" data-testid="String-Editor-container">
      <label htmlFor={textBoxParams} className="block text-gray-700 text-sm font-bold mb-2 capitalize">
        {titleize(textBoxParams)}
      </label>
      <textarea
        data-cy={`${textBoxParams}-editor`}
        data-testid={`${textBoxParams}-editor`}
        id={textBoxParams}
        placeholder="Enter Data"
        value={config[textBoxParams] as string}
        className="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-2 block w-full appearance-none leading-normal text-xs font-normal"
        onChange={(e) =>
          handleOnChange({ value: e.target.value, key: textBoxParams, nodeType: config.type, commitToHistory: false })
        }
        onBlur={(e) =>
          handleOnChange({ value: e.target.value, key: textBoxParams, nodeType: config.type, commitToHistory: true })
        }
      />
      {showError(textBoxParams) && (
        <p style={{ fontSize: "10px" }} className="block text-red-700 font-normal capitalize">
          Required field
        </p>
      )}
    </div>
  );
};

export default AttributeStringField;

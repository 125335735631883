import React from "react";
import ReactTooltip from "react-tooltip";

export interface ButtonProps {
  testID?: string;
  value: string;
  onClick: (e) => void;
  disabled?: boolean;
  disabledMessage?: string[];
}
const Button = ({ disabled, value, onClick, disabledMessage, testID }: ButtonProps): JSX.Element => {
  const allDisabledErrors = disabledMessage
    ? disabledMessage.reduce((acc: string, mess: string) => acc + mess, "")
    : "";
  return (
    <div key={allDisabledErrors} data-tip data-for={allDisabledErrors} data-offset="{'bottom': 32}">
      <button
        data-testid={testID}
        className={`header-btn flex group py-2 px-2 flex-col items-center justify-center${
          !disabled ? "" : " header-btn-disable"
        }`}
        value={value}
        type="button"
        onClick={onClick}
        disabled={disabled}
      >
        {value}
      </button>
      {disabled && (
        <ReactTooltip
          className="w-fit bg-pol-red"
          id={allDisabledErrors}
          delayShow={400}
          effect="float"
          place="bottom"
          backgroundColor="#d00f39"
        >
          <span>{allDisabledErrors}</span>
        </ReactTooltip>
      )}
    </div>
  );
};

Button.defaultProps = {
  testID: "testID",
  disabled: false,
  disabledMessage: "",
};

export default Button;

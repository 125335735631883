/**
 * SPM User Journey APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 7.2.0
 * Contact: tbc@postoffice.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class ValidationFieldFormat {
    /**
    * Message to display when field is not valid.
    */
    'message': string;
    /**
    * Regular expression field should match
    */
    'match'?: string;
    /**
    * Regular expression field should not match
    */
    'notMatch'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "message",
            "baseName": "message",
            "type": "string",
            "format": ""
        },
        {
            "name": "match",
            "baseName": "match",
            "type": "string",
            "format": ""
        },
        {
            "name": "notMatch",
            "baseName": "notMatch",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return ValidationFieldFormat.attributeTypeMap;
    }

    public constructor() {
    }
}

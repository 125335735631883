import React from "react";
import { v4 } from "uuid";
import { Node, ReactFlowInstance } from "reactflow";
import { getNodeData } from "./nodeMapper";

export const dropAction = (
  event: React.DragEvent<HTMLDivElement>,
  reactFlowWrapper: React.MutableRefObject<HTMLDivElement>,
  instance: ReactFlowInstance
): Node => {
  const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
  const type = event.dataTransfer.getData("application/reactflow");
  const position = instance.project({
    x: event.clientX - reactFlowBounds.left,
    y: event.clientY - reactFlowBounds.top,
  });
  const newNode = getNodeData(v4(), type, position);
  return newNode;
};

import * as monaco from "monaco-editor";
import { Node } from "reactflow";
import { JSONEditorOutput, DataProps } from "../../../types/customTypes";

export enum AttributeNodeActions {
  SWITCH_NODE = "switch_node",
  UPDATE_CONFIG_KEY = "update_config_key",
  AMEND_ERROR_STORE = "amend_error_store",
  SET_REQUIRED_FIELDS = "set_required_fields",
}

export interface SwitchNode {
  type: AttributeNodeActions.SWITCH_NODE;
  payload: { inputNode: Node<DataProps>; preserveValidationData?: boolean };
}

export interface UpdateConfigKey {
  type: AttributeNodeActions.UPDATE_CONFIG_KEY;
  payload: { key: string; value: JSONEditorOutput };
}

export interface AmendErrorStore {
  type: AttributeNodeActions.AMEND_ERROR_STORE;
  payload: { key: string; jsonError: monaco.editor.IMarker[] };
}

export interface SetRequiredFields {
  type: AttributeNodeActions.SET_REQUIRED_FIELDS;
  payload: { requiredFields: string[] };
}

export type ValidAttributeNodeActions = SwitchNode | UpdateConfigKey | AmendErrorStore | SetRequiredFields;

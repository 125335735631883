import { Edge, Connection, NodeChange, EdgeChange, Viewport } from "reactflow";
import {
  CanvasNode,
  AttributeNode,
  MouseData,
  CacheStoreProps,
  SelectorNode,
  Canvas,
} from "../../../types/customTypes";

export enum ElementActions {
  DROP = "drop",
  REMOVE_NODE = "remove_node",
  REMOVE_EDGE = "remove_edge",
  CONNECT = "connect",
  CHANGE = "change",
  DROPJOURNEY = "dropjourney",
  CLEAR = "clear",
  PASTE = "paste",
  MOVENODE = "movenode",
  APPLY_NODE_CHANGE = "apply_node_change",
  APPLY_EDGE_CHANGE = "apply_edge_change",
  ADD_CANVAS = "add_canvas",
  DELETE_CANVAS = "delete_canvas",
  CLEAR_ALL_TABS = "clear_all_tabs",
  RECOVER_TABS = "recover_tabs",
  UPDATE_VIEWPORT = "update_viewport",
  UPDATE_NODE = "UPDATE_NODE",
  SELECT_NODE = "SELECT_NODE",
  UPDATE_DEVICE_ID = "update_device_id",
  UPDATE_SEARCH = "update_search",
  NEXT_SEARCH_RESULT = "next_search_result",
  PREV_SEARCH_RESULT = "prev_search_result",
  UPDATE_SEARCH_SELECTION = "update_search_selection",
  UPDATE_SEARCH_SELECTION_BY_ID = "update_search_selection_by_id",
  SELECT_EDGES_FROM_NODES = "select_edges_from_nodes",
  SHIFT_FOCUS_NODE = "shift_focus_node",
  UPDATE_ELEMENT_DATA = "update_element_data",
  HISTORY_UNDO = "history_undo",
  HISTORY_REDO = "history_redo",
  HISTORY_COMMIT = "history_commit",
  SET_CURRENT_TAB = "set_current_tab",
  SELECT_ALL = "select_all",
  REMOVE_SELECTION = "remove_selection",
}
export interface UpdateDeviceID {
  type: ElementActions.UPDATE_DEVICE_ID;
  payload: { canvas?: number; deviceID: string };
}
export interface DropElement {
  type: ElementActions.DROP;
  payload: { newNode: CanvasNode; canvas?: number };
}
export interface ChangeElement {
  type: ElementActions.CHANGE;
  payload: { inputNode: CanvasNode; canvas?: number };
}

export interface UpdateElementData {
  type: ElementActions.UPDATE_ELEMENT_DATA;
  payload: { node: AttributeNode; canvas?: number };
}

export interface ClearElement {
  type: ElementActions.CLEAR;
  payload: { canvas?: number };
}

export interface UpdateSearch {
  type: ElementActions.UPDATE_SEARCH;
  payload: { canvas?: number; matchedAgainst: string; retainSelectedElement?: boolean };
}

export interface NextSearchResult {
  type: ElementActions.NEXT_SEARCH_RESULT;
  payload: { canvas?: number };
}
export interface PrevSearchResult {
  type: ElementActions.PREV_SEARCH_RESULT;
  payload: { canvas?: number };
}

export interface UpdateSearchSelection {
  type: ElementActions.UPDATE_SEARCH_SELECTION;
  payload: { canvas?: number; node: SelectorNode | null };
}

export interface UpdateSearchSelectionById {
  type: ElementActions.UPDATE_SEARCH_SELECTION_BY_ID;
  payload: { canvas?: number; nodeId: string | null };
}

export interface DropJourney {
  type: ElementActions.DROPJOURNEY;
  payload: { importedNodesArray: CanvasNode[]; importedEdgesArray: Edge[]; canvas?: number };
}

export interface ConnectElement {
  type: ElementActions.CONNECT;
  payload: { params: Connection | Edge; canvas?: number };
}
export interface RemoveNode {
  type: ElementActions.REMOVE_NODE;
  payload: { nodesToRemove: CanvasNode[]; canvas?: number };
}

export interface SelectNode {
  type: ElementActions.SELECT_NODE;
  payload: { selectedNode: CanvasNode; canvas?: number };
}
export interface RemoveEdge {
  type: ElementActions.REMOVE_EDGE;
  payload: { edgesToRemove: Edge[]; canvas?: number };
}

export interface PasteElement {
  type: ElementActions.PASTE;
  payload: { copyCache: CacheStoreProps; mouseData: MouseData; canvas?: number; pasteIncrement?: number };
}
export interface MoveSelectionElement {
  type: ElementActions.MOVENODE;
  payload: { inputNodes: CanvasNode[]; canvas?: number };
}
export interface ApplyNodeChange {
  type: ElementActions.APPLY_NODE_CHANGE;
  payload: { change: NodeChange[]; canvas?: number };
}
export interface ApplyEdgeChange {
  type: ElementActions.APPLY_EDGE_CHANGE;
  payload: { change: EdgeChange[]; canvas?: number };
}

export interface AddCanvas {
  type: ElementActions.ADD_CANVAS;
}

export interface DeleteCanvas {
  type: ElementActions.DELETE_CANVAS;
  payload: { canvas?: number };
}

export interface ClearAllTabs {
  type: ElementActions.CLEAR_ALL_TABS;
}
export interface RecoverTabs {
  type: ElementActions.RECOVER_TABS;
  payload: { recoveredData: Canvas[] };
}
export interface UpdateViewport {
  type: ElementActions.UPDATE_VIEWPORT;
  payload: { viewport: Viewport; canvas?: number };
}
export interface ShiftFocusNode {
  type: ElementActions.SHIFT_FOCUS_NODE;
  payload: { node: CanvasNode; canvas?: number };
}
export interface HistoryUndo {
  type: ElementActions.HISTORY_UNDO;
  payload?: { canvas?: number };
}
export interface HistoryRedo {
  type: ElementActions.HISTORY_REDO;
  payload?: { canvas?: number };
}
export interface HistoryCommit {
  type: ElementActions.HISTORY_COMMIT;
  payload?: { canvas?: number };
}
export interface SetCurrentTab {
  type: ElementActions.SET_CURRENT_TAB;
  payload: { canvas: number | null };
}

export interface SelectAll {
  type: ElementActions.SELECT_ALL;
  payload?: { canvas?: number };
}

export interface RemoveSelection {
  type: ElementActions.REMOVE_SELECTION;
  payload?: { canvas?: number };
}

export type ValidElementActions =
  | DropElement
  | ChangeElement
  | DropJourney
  | ConnectElement
  | RemoveNode
  | RemoveEdge
  | ClearElement
  | PasteElement
  | MoveSelectionElement
  | RemoveEdge
  | ApplyNodeChange
  | ApplyEdgeChange
  | AddCanvas
  | DeleteCanvas
  | RecoverTabs
  | ClearAllTabs
  | UpdateViewport
  | SelectNode
  | ShiftFocusNode
  | UpdateSearch
  | NextSearchResult
  | PrevSearchResult
  | UpdateSearchSelection
  | UpdateSearchSelectionById
  | UpdateElementData
  | SetCurrentTab
  | SelectAll
  | RemoveSelection
  | HistoryUndo
  | HistoryRedo
  | HistoryCommit
  | UpdateDeviceID;

import { Node, Edge } from "reactflow";

export enum SELECT {
  ALL = "all",
}

export const setSelectionById = <T extends (Node | Edge)[]>(items: T, selection: string[] | SELECT): T => {
  if (selection === SELECT.ALL) {
    return items.map((e) => ({ ...e, selected: true })) as T;
  }
  return items.map((e) => ({ ...e, selected: selection.includes(e.id) })) as T;
};

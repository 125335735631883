import React from "react";

export interface ModalBodyRestoreDeleteProps {
  modalMessage: string;
  modalPrimaryButtonTitle: string;
  modalSecondaryButtonTitle: string;
  modalPrimaryFunction: () => void;
  modalSecondaryFunction: () => void;
}

const ModalBodyRestoreDelete = ({
  modalMessage,
  modalPrimaryButtonTitle,
  modalSecondaryButtonTitle,
  modalPrimaryFunction,
  modalSecondaryFunction,
}: ModalBodyRestoreDeleteProps): JSX.Element => {
  return (
    <div className="h-full w-full flex flex-col justify-center items-center relative">
      <div className="flex h-full justify-center items-center">
        <p className="text-center p-4">{modalMessage}</p>
      </div>
      <div className="flex p-4 w-full justify-center">
        <button
          className="bg-pol-red w-24 p-1 btn text-white rounded-sm hover:bg-red-800 mr-2"
          type="button"
          onClick={modalSecondaryFunction}
        >
          {modalSecondaryButtonTitle}
        </button>
        <button
          className="bg-success-green w-24 p-1 btn text-white rounded-sm hover:bg-green-800 ml-2"
          type="button"
          onClick={modalPrimaryFunction}
        >
          {modalPrimaryButtonTitle}
        </button>
      </div>
    </div>
  );
};

export default ModalBodyRestoreDelete;

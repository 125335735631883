import React from "react";
import { getCommonNodeConfigKeys } from "../common/helpers/nodes/getCommonNodeConfigKeys";
import { AttributeNode, ElementChangeFunction } from "../types/customTypes";
import AttributeBarTextArea from "./AttributeBarTextArea";

interface CommonNodePropertiesProps {
  handleOnChange: ElementChangeFunction;
  nodeData: AttributeNode;
  hasTopConnection: boolean;
}

const CommonNodeProperties = ({
  handleOnChange,
  nodeData,
  hasTopConnection,
}: CommonNodePropertiesProps): JSX.Element => {
  const commonConfigKeys = getCommonNodeConfigKeys(nodeData);

  return (
    <div data-testid="common_attr_wrap">
      {commonConfigKeys.map((commonConfigKey) => {
        return (
          <div
            key={`${nodeData.id}_${commonConfigKey}`}
            className="mx-2 mb-6"
            data-testid={`text_area_${commonConfigKey}`}
          >
            <div key={`${nodeData.id}_${commonConfigKey}`}>
              <AttributeBarTextArea
                handleOnChange={handleOnChange}
                configKey={commonConfigKey}
                nodeData={nodeData}
                hasTopConnection={hasTopConnection}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CommonNodeProperties;

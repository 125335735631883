/**
 * SPM User Journey APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 7.2.0
 * Contact: tbc@postoffice.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { InfoListData } from './InfoListData';
import { InfoStepVisualProperties } from './InfoStepVisualProperties';
import { TypeInfoStep } from './TypeInfoStep';
import { HttpFile } from '../http/http';

/**
* Display text and does not expect any user input.
*/
export class InfoStep {
    'type': TypeInfoStep;
    /**
    * Displayed as header text
    */
    'title'?: string;
    /**
    * Displayed as normal text
    */
    'description'?: string;
    'visual'?: InfoStepVisualProperties;
    /**
    * Data used to display list of key/value pairs. Only used with \"list\" view.
    */
    'params'?: string | Array<InfoListData>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "type",
            "baseName": "type",
            "type": "TypeInfoStep",
            "format": ""
        },
        {
            "name": "title",
            "baseName": "title",
            "type": "string",
            "format": ""
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string",
            "format": ""
        },
        {
            "name": "visual",
            "baseName": "visual",
            "type": "InfoStepVisualProperties",
            "format": ""
        },
        {
            "name": "params",
            "baseName": "params",
            "type": "string | Array<InfoListData>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return InfoStep.attributeTypeMap;
    }

    public constructor() {
    }
}

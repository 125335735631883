/**
 * SPM User Journey APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 7.2.0
 * Contact: tbc@postoffice.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* Error in product journey
*/
export class JourneyError {
    /**
    * breadcrumb of sub-journeys to the error the step
    */
    'breadcrumb'?: string;
    /**
    * name of the sub-journey(if any)
    */
    'subJourney'?: string;
    /**
    * node position in expanded product journey
    */
    'position'?: string;
    /**
    * node position in sub-journey(if any)
    */
    'positionInSubJourney'?: string;
    /**
    * error in product journey
    */
    'error'?: string;
    /**
    * sub-journey names which are potentially creating a loop. Grey set gives the name of the sub-journeys which need to be checked and fixed in order to avoid the loop creation.
    */
    'loopGreySet'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "breadcrumb",
            "baseName": "breadcrumb",
            "type": "string",
            "format": ""
        },
        {
            "name": "subJourney",
            "baseName": "subJourney",
            "type": "string",
            "format": ""
        },
        {
            "name": "position",
            "baseName": "position",
            "type": "string",
            "format": ""
        },
        {
            "name": "positionInSubJourney",
            "baseName": "positionInSubJourney",
            "type": "string",
            "format": ""
        },
        {
            "name": "error",
            "baseName": "error",
            "type": "string",
            "format": ""
        },
        {
            "name": "loopGreySet",
            "baseName": "loopGreySet",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return JourneyError.attributeTypeMap;
    }

    public constructor() {
    }
}

import { useState, useEffect } from "react";
import { AppState } from "../types/customTypes";
import { db } from "./db";

export const dbStore = {
  Put: (appState: AppState): void => {
    db.journeys
      .put({
        id: 1,
        unSavedCanvas: appState,
      })
      .catch(() => {
        throw new Error("Failed to save to database");
      });
  },

  Get: (): AppState | undefined => {
    const [result, setResult] = useState<AppState | undefined>({ tabData: { current: 0, tabs: [] } });
    useEffect(() => {
      const getJourneys = () => {
        db.journeys
          .get(1)
          .then((res) => setResult(res?.unSavedCanvas))
          .catch(() => {
            throw new Error("Failed to retrieve from database");
          });
      };
      getJourneys();
    }, []);
    return result;
  },
};

import React from "react";

interface NodeTooltipKeyValueProps {
  objectKey: string;
  objectValue: string;
}

const NodeTooltipKeyValue = ({ objectKey, objectValue }: NodeTooltipKeyValueProps): JSX.Element => {
  return (
    <p data-testid={`${objectKey}-${objectValue}`}>
      <span className="font-semibold">{objectKey}</span>:{objectValue}
    </p>
  );
};

export default NodeTooltipKeyValue;

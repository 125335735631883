import { Node as CanvasNode } from "reactflow";
import { DataProps, Canvas as CanvasPage } from "../../types/customTypes";
import { exportJourneyJSON } from "../../files/export";
import { HashCanvas } from "../../files/types";
import { Node } from "../../openapi/api";

export const mapToTypeCanvas = (elements: CanvasPage): HashCanvas => {
  const { nodes, edges } = elements;
  const newNodesArray = nodes.map((node: CanvasNode<DataProps>) => {
    return {
      id: node.id,
      hashID: "",
      journeyNode: node.data.config as Node,
    };
  });
  const newEdgesArray = edges.map((edge) => {
    return {
      source: edge.source,
      sourceHandle: edge.sourceHandle,
      target: edge.target,
      targetHandle: edge.targetHandle,
    };
  });
  return { nodes: newNodesArray, edges: newEdgesArray };
};

export const convertToJson = (elementsArray: CanvasPage[], page: number): string => {
  const arrayOfTypecanvas = mapToTypeCanvas(elementsArray[page]);
  const jsonOutput = exportJourneyJSON(arrayOfTypecanvas);
  return JSON.stringify(jsonOutput);
};

import { Auth } from "aws-amplify";
import axios, { AxiosResponse } from "axios";
import { baseURL, REQUEST_METHODS } from "../../constants/apiConst";

export async function getToken(): Promise<string> {
  return (await Auth.currentSession())?.getIdToken().getJwtToken();
}
interface ApiConfig {
  fullUrl?: string;
  method?: REQUEST_METHODS;
  authToken?: string;
  headers?: Record<string, string>;
  params?: Record<string, string> | FormData;
}
export const axiosTimeout = 20 * 1000; // 20 seconds
async function api<T>(
  path: string,
  { fullUrl = "", method = REQUEST_METHODS.GET, params = {}, authToken, headers = {} }: ApiConfig
): Promise<AxiosResponse<T>> {
  const axiosUrl = fullUrl || `${baseURL}${path}`;
  const accessToken = authToken || (await getToken());
  const authHeader = accessToken && { Authorization: `Bearer ${accessToken}` };
  const axiosHeader = { ...headers, ...authHeader };

  return new Promise((resolve, reject) => {
    axios
      .request({
        method,
        url: axiosUrl,
        data: params,
        headers: axiosHeader,
        timeout: axiosTimeout,
      })
      .then((response) => {
        resolve(response as unknown as Promise<AxiosResponse<T>>);
      })
      .catch((error: Error) => {
        reject(error);
      });
  });
}

export default api;

import React from "react";
import { JSONObject } from "../types/customTypes";
import NodeTooltipKeyValue from "./NodeTooltipKeyValue";

interface NodeTooltipContentObjectProps {
  nestedTooltipItem: JSONObject;
  objectKey: string;
}

const NodeTooltipContentObject = ({ nestedTooltipItem, objectKey }: NodeTooltipContentObjectProps): JSX.Element => {
  const nestedData = nestedTooltipItem[`${objectKey}`] as JSONObject;
  return (
    <div key={objectKey} data-testid={objectKey}>
      <p>
        <span className="font-semibold">{objectKey}</span>:
      </p>
      {nestedData &&
        Object.keys(nestedData).map((nestedKey) => {
          if (typeof nestedData[`${nestedKey}`] === "object") {
            return (
              <NodeTooltipContentObject
                key={nestedKey}
                nestedTooltipItem={nestedData[nestedKey] as JSONObject}
                objectKey={nestedKey}
              />
            );
          }
          return (
            <div key={nestedKey} className="ml-6">
              <NodeTooltipKeyValue objectKey={nestedKey} objectValue={String(nestedData[`${nestedKey}`])} />
            </div>
          );
        })}
    </div>
  );
};

export default NodeTooltipContentObject;

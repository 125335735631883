import { v4 } from "uuid";
import { Edge, Node, MarkerType, applyNodeChanges, applyEdgeChanges } from "reactflow";
import { produce, Draft } from "immer";
import { positioningAdjustment } from "../../actions/pastePositioning";
import { AppState, TabState, SearchDetails, SelectorNode, Canvas } from "../../../types/customTypes";
import { copyIdInject } from "../../actions/copyIdInject";
import { initialState, initialStartElement } from "../../../fixtures/startElement";
import { findNodesFromCanvas, stepSearchResults } from "../../../components/searchComponent/helpers";
import {
  selectConnectedEdges,
  filterChange,
  injectCanvasHistory,
  canvasWithHistory,
  recordHistory,
  historyUndo,
  historyRedo,
  setSelectionById,
} from "../../helpers/nodes";
import { ElementActions, ValidElementActions } from "./actions";
import { SearchErrorStatus, SEARCH_MIN_CHAR } from "../../../components/searchComponent/types";

const pickCanvas = (tabData: TabState, canvasId?: number | null): number | null => {
  if (!(canvasId === undefined || canvasId === null)) {
    if (canvasId >= tabData.tabs.length || canvasId < 0) {
      return null;
    }
    return canvasId;
  }
  return tabData.current;
};

export const canvasReducer = (state: AppState, action: ValidElementActions): AppState => {
  let commitToTabHistory: number | null = null;
  const newElements = produce(state, (draft): Draft<AppState> => {
    switch (action.type) {
      case ElementActions.UPDATE_DEVICE_ID: {
        const targetCanvas = pickCanvas(state.tabData, action.payload.canvas);
        if (targetCanvas === null) return draft;
        const { deviceID } = action.payload;
        draft.tabData.tabs[targetCanvas].canvasData.deviceID = deviceID;
        return draft;
      }

      case ElementActions.REMOVE_NODE: {
        const targetCanvas = pickCanvas(state.tabData, action.payload.canvas);
        if (targetCanvas === null) return draft;
        const { nodesToRemove } = action.payload;
        if (nodesToRemove.length > 0) {
          const nodeIds = nodesToRemove.map((el) => el.id);
          draft.tabData.tabs[targetCanvas].nodes = state.tabData.tabs[targetCanvas].nodes.filter(
            (el) => !nodeIds.includes(el.id)
          );
          draft.tabData.tabs[targetCanvas].edges = state.tabData.tabs[targetCanvas].edges.filter(
            (edge) => !nodeIds.includes(edge.source) && !nodeIds.includes(edge.target)
          );
        }
        commitToTabHistory = targetCanvas;
        return draft;
      }

      case ElementActions.REMOVE_EDGE: {
        const targetCanvas = pickCanvas(state.tabData, action.payload.canvas);
        if (targetCanvas === null) return draft;
        const { edgesToRemove } = action.payload;
        if (edgesToRemove.length > 0) {
          draft.tabData.tabs[targetCanvas].edges = state.tabData.tabs[targetCanvas].edges.filter(
            (edge) => !edgesToRemove.some((edge2) => edge.id === edge2.id)
          );
        }
        commitToTabHistory = targetCanvas;
        return draft;
      }

      case ElementActions.SELECT_NODE: {
        const targetCanvas = pickCanvas(state.tabData, action.payload.canvas);
        if (targetCanvas === null) return draft;
        const { selectedNode } = action.payload;
        draft.tabData.tabs[targetCanvas].nodes = state.tabData.tabs[targetCanvas].nodes.map((n) => {
          return { ...n, selected: n.id === selectedNode.id };
        });
        return draft;
      }

      case ElementActions.SELECT_ALL: {
        const targetCanvas = pickCanvas(state.tabData, action?.payload?.canvas);
        if (targetCanvas === null) return draft;
        draft.tabData.tabs[targetCanvas].nodes = state.tabData.tabs[targetCanvas].nodes.map((n) => ({
          ...n,
          selected: true,
        }));
        draft.tabData.tabs[targetCanvas].edges = state.tabData.tabs[targetCanvas].edges.map((n) => ({
          ...n,
          selected: true,
        }));
        commitToTabHistory = targetCanvas;
        return draft;
      }

      case ElementActions.REMOVE_SELECTION: {
        const targetCanvas = pickCanvas(state.tabData, action?.payload?.canvas);
        if (targetCanvas === null) return draft;
        draft.tabData.tabs[targetCanvas].nodes = state.tabData.tabs[targetCanvas].nodes.filter((n) => !n.selected);
        draft.tabData.tabs[targetCanvas].edges = state.tabData.tabs[targetCanvas].edges.filter((n) => !n.selected);
        commitToTabHistory = targetCanvas;
        return draft;
      }

      case ElementActions.SHIFT_FOCUS_NODE: {
        const targetCanvas = pickCanvas(state.tabData, action.payload.canvas);
        if (targetCanvas === null) return draft;
        const { node } = action.payload;
        const { nodes, edges } = state.tabData.tabs[targetCanvas];
        draft.tabData.tabs[targetCanvas].nodes = nodes.map((n) => {
          return { ...n, selected: n.id === node.id };
        });
        draft.tabData.tabs[targetCanvas].edges = selectConnectedEdges({
          nodes: [{ ...node, selected: true }],
          edges,
        }).edges;
        return draft;
      }

      case ElementActions.CONNECT: {
        const targetCanvas = pickCanvas(state.tabData, action.payload.canvas);
        if (targetCanvas === null) return draft;
        const { params } = action.payload;
        const { source, sourceHandle, target, targetHandle } = params;
        if (source && sourceHandle && target && targetHandle) {
          draft.tabData.tabs[targetCanvas].edges.push({
            id: v4(),
            source,
            sourceHandle,
            target,
            targetHandle,
            markerEnd: { type: MarkerType.Arrow },
            style: { strokeWidth: ".10rem" },
          });
        }
        commitToTabHistory = targetCanvas;
        return draft;
      }

      case ElementActions.DROP: {
        const targetCanvas = pickCanvas(state.tabData, action.payload.canvas);
        if (targetCanvas === null) return draft;
        const { newNode } = action.payload;
        draft.tabData.tabs[targetCanvas].nodes = setSelectionById(draft.tabData.tabs[targetCanvas].nodes, []);
        draft.tabData.tabs[targetCanvas].edges = setSelectionById(draft.tabData.tabs[targetCanvas].edges as Edge[], []);
        draft.tabData.tabs[targetCanvas].nodes.push({ ...newNode, selected: true });
        commitToTabHistory = targetCanvas;
        return draft;
      }

      case ElementActions.DROPJOURNEY: {
        const targetCanvas = pickCanvas(state.tabData, action.payload.canvas);
        if (targetCanvas === null) return draft;
        const { importedNodesArray, importedEdgesArray } = action.payload;
        draft.tabData.tabs[targetCanvas].nodes = [...importedNodesArray];
        draft.tabData.tabs[targetCanvas].edges = [...importedEdgesArray];
        commitToTabHistory = targetCanvas;
        return draft;
      }

      case ElementActions.CHANGE: {
        const targetCanvas = pickCanvas(state.tabData, action.payload.canvas);
        if (targetCanvas === null) return draft;
        const { inputNode } = action.payload;
        if (inputNode === undefined) return draft;
        draft.tabData.tabs[targetCanvas].nodes = [
          ...state.tabData.tabs[targetCanvas].nodes.filter((el) => el.id !== inputNode.id),
          inputNode,
        ];
        commitToTabHistory = targetCanvas;
        return draft;
      }

      case ElementActions.UPDATE_ELEMENT_DATA: {
        const targetCanvas = pickCanvas(state.tabData, action.payload.canvas);
        if (targetCanvas === null) return draft;
        const { node } = action.payload;
        if (node === undefined) break;
        const index = state.tabData.tabs[targetCanvas].nodes.findIndex((n) => n.id === node.id);
        if (index >= 0) {
          const { valid, label, errorCodes, config } = node.data;
          draft.tabData.tabs[targetCanvas].nodes[index].data.config = config;
          draft.tabData.tabs[targetCanvas].nodes[index].data.valid = valid;
          draft.tabData.tabs[targetCanvas].nodes[index].data.errorCodes = errorCodes;
          draft.tabData.tabs[targetCanvas].nodes[index].data.label = label;
        }
        return draft;
      }

      case ElementActions.CLEAR: {
        const targetCanvas = pickCanvas(state.tabData, action.payload.canvas);
        if (targetCanvas === null) return draft;
        const blankCanvas: Canvas = {
          nodes: [initialStartElement],
          edges: [],
          canvasData: {
            id: state.tabData.tabs[targetCanvas].canvasData.id,
            viewport: { x: 0, y: 0, zoom: 1 },
            deviceID: state.tabData.tabs[targetCanvas].canvasData.deviceID,
            search: null,
          },
        };
        draft.tabData.tabs[targetCanvas] = canvasWithHistory(blankCanvas);
        commitToTabHistory = targetCanvas;
        return draft;
      }

      case ElementActions.PASTE: {
        const targetCanvas = pickCanvas(state.tabData, action.payload.canvas);
        if (targetCanvas === null) return draft;
        const { copyCache, mouseData, pasteIncrement } = action.payload;
        const { nodesWithNewIDs, edgesWithNewIDs }: { nodesWithNewIDs: Node[]; edgesWithNewIDs: Edge[] } =
          copyIdInject(copyCache);
        const nodesWithPositioning = positioningAdjustment(nodesWithNewIDs, mouseData, pasteIncrement);
        const deselectNodes = draft.tabData.tabs[targetCanvas].nodes.map((node) => {
          return { ...node, selected: false };
        });
        const deselectEdges = draft.tabData.tabs[targetCanvas].edges.map((edge) => {
          return { ...edge, selected: false };
        });
        draft.tabData.tabs[targetCanvas].nodes = [...deselectNodes, ...nodesWithPositioning];
        draft.tabData.tabs[targetCanvas].edges = [...deselectEdges, ...edgesWithNewIDs];
        commitToTabHistory = targetCanvas;
        return draft;
      }

      case ElementActions.MOVENODE: {
        const targetCanvas = pickCanvas(state.tabData, action.payload.canvas);
        if (targetCanvas === null) return draft;
        const { inputNodes } = action.payload;
        if (inputNodes === undefined) return draft;
        const nodeIds = inputNodes.map((el) => el.id);
        const newArray = state.tabData.tabs[targetCanvas].nodes.filter((el) => !nodeIds.includes(el.id));
        draft.tabData.tabs[targetCanvas].nodes = [...newArray, ...inputNodes];
        commitToTabHistory = targetCanvas;
        return draft;
      }

      case ElementActions.APPLY_NODE_CHANGE: {
        const targetCanvas = pickCanvas(state.tabData, action.payload.canvas);
        if (targetCanvas === null) return draft;
        const { change } = action.payload;

        // !TODO: fix root cause of canvas data/index de-syncing. This is a TEMPORARY FIX ONLY.
        const filteredChange = filterChange(change);
        if (!filteredChange.length) return draft;

        const { nodes, edges } = state.tabData.tabs[targetCanvas];
        const alteredNodes = applyNodeChanges(filteredChange, nodes);
        let alteredEdges = edges;
        if (alteredNodes.some((n) => n.selected === true)) {
          alteredEdges = selectConnectedEdges({ nodes: alteredNodes, edges }).edges;
        }
        draft.tabData.tabs[targetCanvas].nodes = alteredNodes;
        draft.tabData.tabs[targetCanvas].edges = alteredEdges;

        const blockHistory = filteredChange.some(
          (c) => (c.type === "position" && c.dragging === true) || c.type === "select"
        );
        if (!blockHistory) {
          commitToTabHistory = targetCanvas;
        }
        return draft;
      }

      case ElementActions.UPDATE_SEARCH: {
        const targetCanvas = pickCanvas(state.tabData, action.payload.canvas);
        let errMsg: SearchErrorStatus | null = null;
        if (targetCanvas === null) return draft;
        const { matchedAgainst } = action.payload;
        if (matchedAgainst.length > 0 && matchedAgainst.length < SEARCH_MIN_CHAR) {
          errMsg = SearchErrorStatus.TOO_SHORT;
        }
        let searchedNodesResults: SelectorNode[] = [];
        if (matchedAgainst.length >= SEARCH_MIN_CHAR) {
          searchedNodesResults = findNodesFromCanvas(state.tabData.tabs[targetCanvas].nodes, matchedAgainst);
          if (searchedNodesResults.length === 0) {
            errMsg = SearchErrorStatus.NO_RESULT;
          }
        }
        const selectedNode = action.payload.retainSelectedElement
          ? draft.tabData.tabs[targetCanvas].canvasData.search?.match.selected
          : null;
        const searchDetails: SearchDetails = {
          match: { matchedAgainst, elements: searchedNodesResults, selected: selectedNode },
          searchString: matchedAgainst,
          error: errMsg,
        };
        draft.tabData.tabs[targetCanvas].canvasData.search = searchDetails;
        return draft;
      }

      case ElementActions.NEXT_SEARCH_RESULT: {
        const targetCanvas = pickCanvas(state.tabData, action.payload.canvas);
        if (targetCanvas === null) return draft;
        const { search } = state.tabData.tabs[targetCanvas].canvasData;
        if (!search?.match.elements.length) return draft;
        draft.tabData.tabs[targetCanvas].canvasData.search = {
          ...search,
          match: { ...search.match, selected: stepSearchResults(search.match, 1) },
        };
        return draft;
      }

      case ElementActions.PREV_SEARCH_RESULT: {
        const targetCanvas = pickCanvas(state.tabData, action.payload.canvas);
        if (targetCanvas === null) return draft;
        const { search } = state.tabData.tabs[targetCanvas].canvasData;
        if (!search?.match.elements.length) return draft;
        draft.tabData.tabs[targetCanvas].canvasData.search = {
          ...search,
          match: { ...search.match, selected: stepSearchResults(search.match, -1) },
        };
        return draft;
      }

      case ElementActions.UPDATE_SEARCH_SELECTION: {
        const targetCanvas = pickCanvas(state.tabData, action.payload.canvas);
        if (targetCanvas === null) return draft;
        const { node } = action.payload;
        const { search } = state.tabData.tabs[targetCanvas].canvasData;
        if (!search?.match) return draft;
        draft.tabData.tabs[targetCanvas].canvasData.search = {
          ...search,
          match: { ...search.match, selected: node },
        };
        return draft;
      }

      case ElementActions.UPDATE_SEARCH_SELECTION_BY_ID: {
        const targetCanvas = pickCanvas(state.tabData, action.payload.canvas);
        if (targetCanvas === null) return draft;
        const { nodeId } = action.payload;
        const { search } = state.tabData.tabs[targetCanvas].canvasData;
        if (!search?.match) return draft;
        const newSelection = search.match.elements.find((e) => e.value.id === nodeId);
        draft.tabData.tabs[targetCanvas].canvasData.search = {
          ...search,
          match: { ...search.match, selected: newSelection },
        };
        return draft;
      }

      case ElementActions.APPLY_EDGE_CHANGE: {
        const targetCanvas = pickCanvas(state.tabData, action.payload.canvas);
        if (targetCanvas === null) return draft;
        const { change } = action.payload;
        const { nodes, edges } = state.tabData.tabs[targetCanvas];
        let alteredEdges = applyEdgeChanges(change, edges);
        if (nodes.some((n) => n.selected === true)) {
          alteredEdges = selectConnectedEdges({ nodes, edges: alteredEdges }).edges;
        }
        draft.tabData.tabs[targetCanvas].edges = alteredEdges;
        commitToTabHistory = targetCanvas;
        return draft;
      }

      case ElementActions.ADD_CANVAS: {
        draft.tabData.tabs.push({
          nodes: [initialStartElement],
          edges: [],
          canvasData: { viewport: { x: 0, y: 0, zoom: 1 }, id: v4(), deviceID: "" },
          history: {
            past: [
              {
                nodes: [initialStartElement],
                edges: [],
              },
            ],
            present: {
              nodes: [initialStartElement],
              edges: [],
            },
            future: [],
          },
        });
        const newIndex = draft.tabData.tabs.length - 1;
        draft.tabData.current = newIndex;
        commitToTabHistory = newIndex;
        return draft;
      }

      case ElementActions.DELETE_CANVAS: {
        const targetCanvas = pickCanvas(state.tabData, action.payload.canvas);
        if (targetCanvas === null) return draft;
        draft.tabData.tabs.splice(targetCanvas, 1);
        if (draft.tabData.tabs.length > 0 && state.tabData.current !== null) {
          if (targetCanvas < state.tabData.current) {
            draft.tabData.current = state.tabData.current - 1;
          }
        } else {
          draft.tabData.current = null;
        }
        if (draft.tabData.current !== null) {
          if (draft.tabData.current >= draft.tabData.tabs.length) {
            draft.tabData.current = draft.tabData.tabs.length - 1;
          } else if (draft.tabData.current < 0) {
            draft.tabData.current = 0;
          }
        }
        commitToTabHistory = targetCanvas;
        return draft;
      }

      case ElementActions.RECOVER_TABS: {
        draft.tabData.tabs = injectCanvasHistory(action.payload.recoveredData);
        return draft;
      }

      case ElementActions.CLEAR_ALL_TABS: {
        return initialState;
      }

      case ElementActions.SET_CURRENT_TAB: {
        draft.tabData.current = action.payload.canvas;
        return draft;
      }

      case ElementActions.UPDATE_VIEWPORT: {
        const targetCanvas = pickCanvas(state.tabData, action.payload.canvas);
        if (targetCanvas === null) return draft;
        const { viewport } = action.payload;
        draft.tabData.tabs[targetCanvas].canvasData.viewport = { ...viewport };
        return draft;
      }

      case ElementActions.HISTORY_UNDO: {
        const targetCanvas = pickCanvas(state.tabData, action.payload?.canvas);
        if (targetCanvas === null) return draft;
        if (!state.tabData.tabs[targetCanvas].history.past.length) return draft;
        draft.tabData.tabs[targetCanvas] = historyUndo(state.tabData.tabs[targetCanvas]);
        return draft;
      }

      case ElementActions.HISTORY_REDO: {
        const targetCanvas = pickCanvas(state.tabData, action.payload?.canvas);
        if (targetCanvas === null) return draft;
        if (!state.tabData.tabs[targetCanvas].history.future.length) return draft;
        draft.tabData.tabs[targetCanvas] = historyRedo(state.tabData.tabs[targetCanvas]);
        return draft;
      }

      case ElementActions.HISTORY_COMMIT: {
        commitToTabHistory = pickCanvas(state.tabData, action?.payload?.canvas);
        return draft;
      }

      default: {
        return draft;
      }
    }
    return draft;
  });

  return recordHistory(newElements, commitToTabHistory);
};

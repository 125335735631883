import React, { useEffect, useMemo, useRef, useState } from "react";
import { Node as ReactNode } from "reactflow";
import { SingleValue } from "react-select";
import { HiX, HiSearch } from "react-icons/hi";
import { ElementActions } from "../../common/reducers/elements";
import { SelectorNode, DataProps } from "../../types/customTypes";
import { CanvasTools } from "../../types/collections";
import { debounce } from "../../common/utils";
import { SearchElementsList } from "./SearchElementsList";

interface SearchComponentProps {
  setSearchedNode: React.Dispatch<React.SetStateAction<ReactNode<DataProps> | undefined>>;
  canvasTools: CanvasTools;
}

const SearchComponent = ({ canvasTools, setSearchedNode }: SearchComponentProps): JSX.Element => {
  const [searchedInput, setSearchedInput] = useState<string>("");
  const [showSearchPanel, setShowSearchPanel] = useState(false);
  const { elementsDispatch } = canvasTools;
  const elements = canvasTools.canvas.canvasData.search?.match.elements;
  const windowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (windowRef.current && !windowRef.current.contains(e.target as Node)) {
        if (showSearchPanel) {
          setShowSearchPanel(false);
        }
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showSearchPanel]);

  useEffect(() => {
    const { searchString } = canvasTools.canvas.canvasData.search || {};
    if (!searchString) {
      setSearchedInput("");
    } else if (searchString && searchString !== searchedInput) setSearchedInput(searchString);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasTools.canvas.canvasData.search?.searchString]);

  const onNodeChange = (selectedNode: SingleValue<SelectorNode>) => {
    if (selectedNode) {
      elementsDispatch({
        type: ElementActions.UPDATE_SEARCH_SELECTION,
        payload: { node: selectedNode },
      });
      setSearchedNode({ ...selectedNode.value });
      setShowSearchPanel(false);
    }
  };

  const debounceFn = useMemo(
    () =>
      debounce((searchText: string) => {
        canvasTools.elementsDispatch({
          type: ElementActions.UPDATE_SEARCH,
          payload: { matchedAgainst: searchText },
        });
      }, 300),
    [canvasTools]
  );

  const onChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchedInput(e.target.value);
    debounceFn(e.target.value);
  };

  const onFocus = () => {
    setShowSearchPanel(true);
    canvasTools.elementsDispatch({
      type: ElementActions.UPDATE_SEARCH,
      payload: { matchedAgainst: searchedInput, retainSelectedElement: true },
    });
  };
  return (
    <div className="flex-col flex relative items-center justify-center " ref={windowRef}>
      <div className="relative">
        <div className="icon-panel-left">
          <HiSearch color="#696969" />
        </div>
        <input
          className="py-1 px-6 mt-2 mr-2 rounded-full bg-white mb-1 shadow-inner focus:outline-none focus:shadow-outline border placeholder-gray-400 text-gray-700"
          type="text"
          value={searchedInput}
          autoComplete="off"
          data-testid="focus-search-string"
          onFocus={() => onFocus()}
          placeholder="Search nodes"
          onChange={onChangeText}
        />
        <div className="icon-panel-right">
          {searchedInput.length > 0 && (
            <HiX
              color="#696969"
              data-testid="cross-icon"
              onClick={() => {
                canvasTools.elementsDispatch({
                  type: ElementActions.UPDATE_SEARCH,
                  payload: { matchedAgainst: "" },
                });
              }}
            />
          )}
        </div>
        <SearchElementsList
          additionalStyling={showSearchPanel ? "" : "hidden"}
          elements={elements}
          onNodeChange={onNodeChange}
          searchDetails={canvasTools.canvas.canvasData.search}
        />
      </div>
    </div>
  );
};

export default SearchComponent;

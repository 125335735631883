import React, { useCallback, useState } from "react";
import { titleize } from "../common/helpers/string/titleize";
import {
  JSONObject,
  UserFriendlyModes,
  YAMLProperty,
  InputNodeForm,
  ConfigProps,
  UserFriendlyChangeProps,
} from "../types/customTypes";
import JsonEditor from "./JsonEditor";
import UserFriendlyCheckbox from "./UserFriendlyCheckbox";
import UserFriendlyDropdown from "./UserFriendlyDropdown";
import UserFriendlyText from "./UserFriendlyText";
import UserFriendlyTooltip from "./UserFriendlyTooltip";
import UserFriendlySwitchButton from "./UserFriendlySwitchButton";
import { inputValidator } from "../common/helpers/string/inputValidator";
import { useFetchYamlData } from "../common/hooks/useFetchYamlData";
import { updateCache } from "../common/helpers/objects/updateCache";

const VisualEditor = ({ nodeType, jsonData, handleOnChange, jsonFieldName }: InputNodeForm): JSX.Element => {
  const jData = jsonData as unknown as ConfigProps;
  const yaml = useFetchYamlData()?.[`${titleize(jData.type)}StepVisualProperties`];

  if (jsonData === null) return <div />;
  const [mode, setMode] = useState<UserFriendlyModes>(UserFriendlyModes.BASIC);
  const visualData = inputValidator(jData.visual as unknown as string, "Visual", true) as JSONObject;

  const handleUserFriendlyChange = useCallback(
    (change: UserFriendlyChangeProps) => {
      const { key, value, immediateCommit } = change;
      const cache = updateCache(visualData || {}, key, value);
      handleOnChange({
        value: JSON.stringify(cache),
        key: "visual",
        nodeType,
        isJSONEditor: true,
        commitToHistory: immediateCommit,
      });
    },
    [handleOnChange, nodeType, visualData]
  );

  const getInputTypes = (key: string, properties: YAMLProperty | undefined): JSX.Element => {
    if (properties === undefined) return <div />;
    if (Object.keys(properties).includes("enum")) {
      return (
        <UserFriendlyDropdown
          dropdownParam={key}
          enums={properties.enum}
          handleOnChange={handleUserFriendlyChange}
          config={visualData}
        />
      );
    }
    if (properties.type === "string") {
      return <UserFriendlyText textBoxParams={key} handleOnChange={handleUserFriendlyChange} config={visualData} />;
    }
    if (properties.type === "boolean") {
      return <UserFriendlyCheckbox booleanParam={key} handleOnChange={handleUserFriendlyChange} config={visualData} />;
    }
    return <div />;
  };

  return (
    <div data-testid="visual-userfriendly-editor-container">
      <UserFriendlySwitchButton mode={mode} setMode={setMode} />
      {mode === UserFriendlyModes.BASIC && <h1 className="flex justify-center underline">{titleize(jsonFieldName)}</h1>}
      {mode === UserFriendlyModes.BASIC ? (
        <ul data-testid="basic-view">
          {yaml && yaml.properties ? (
            Object.keys(yaml.properties).map((key) => {
              return (
                <ul key={key}>
                  <div className="relative my-4">
                    <div className="flex flex-rows  items-center mx-2">
                      <strong>{titleize(key)}</strong>
                      <UserFriendlyTooltip
                        attribute={key}
                        description={(yaml.properties && yaml.properties[key].description) || ""}
                        example={(yaml.properties && yaml.properties[key].example) || ""}
                      />
                    </div>
                    {getInputTypes(key, yaml.properties && yaml.properties[key])}
                  </div>
                </ul>
              );
            })
          ) : (
            <p>No presets found</p>
          )}
        </ul>
      ) : (
        <JsonEditor
          nodeType={nodeType}
          handleOnChange={handleOnChange}
          jsonData={visualData}
          jsonFieldName={jsonFieldName}
        />
      )}
    </div>
  );
};

export default VisualEditor;

import React, { useEffect, useState } from "react";
import { v4 } from "uuid";
import { HiOutlineX } from "react-icons/hi";
import { ConfigProps, SortableItemPropsWithConfig, ElementChangeProps } from "../types/customTypes";
import { getNodeData } from "../common/actions/nodeMapper";
import GroupNodeAttributes from "./GroupNodeAttributes";
import { inputValidator } from "../common/helpers/string/inputValidator";
import GroupNodeSortableList from "./GroupNodeSortableList";

interface Props {
  config: ConfigProps;
  onChange: (change: ElementChangeProps) => void;
  jsonFieldName: string;
  close: () => void;
  hasTopConnection: boolean;
  deviceId?: string | null;
}

const GroupNodeEditor = ({
  config,
  onChange,
  jsonFieldName,
  close,
  hasTopConnection,
  deviceId = null,
}: Props): JSX.Element => {
  const [groupNodes, setGroupNodes] = useState<SortableItemPropsWithConfig[] | null>(null);
  const [selected, setSelected] = useState<SortableItemPropsWithConfig | null>(null);

  useEffect(() => {
    if (config.groupNodes) {
      const sortableify = config.groupNodes.map((groupNode) => {
        return { id: v4(), config: { ...groupNode } };
      });
      setGroupNodes(sortableify);
    } else {
      setGroupNodes([] as SortableItemPropsWithConfig[]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addNode = (type: string) => {
    const { data } = getNodeData("id", type, { x: 0, y: 0 });
    if (groupNodes === null) return;
    setGroupNodes([
      ...groupNodes,
      {
        id: v4(),
        config: data.config,
      } as SortableItemPropsWithConfig,
    ]);
  };

  const handleChange = (id: string, key: string, value: string | number | boolean) => {
    if (groupNodes === null) return;
    const results = groupNodes.map((groupNode) => {
      if (groupNode.id === id) {
        const change = { [`${key}`]: inputValidator(value, key, true) };
        return { ...groupNode, config: { ...groupNode.config, ...change } };
      }
      return groupNode;
    });
    setGroupNodes(results);
  };

  const handleDelete = (id: string) => {
    if (groupNodes === null) return;
    if (selected && selected.id === id) {
      setSelected(null);
    }
    setGroupNodes([...groupNodes.filter((node) => node.id !== id)]);
  };

  const save = (commitToHistory = false) => {
    if (groupNodes !== null) {
      const cleanedData = JSON.stringify(groupNodes.map((node) => node.config));
      onChange({
        value: cleanedData,
        key: jsonFieldName,
        nodeType: "inputgroup",
        isJSONEditor: true,
        commitToHistory,
      });
    }
  };

  const saveCommitAndClose = () => {
    save(true);
    close();
  };

  useEffect(() => {
    save();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupNodes]);

  if (groupNodes === null) return <div />;

  return (
    <div className="h-full w-full bg-white" data-testid="groupnodes-modal">
      <div className="h-full w-full bg-pink-300 flex">
        <div className="w-3/5 bg-gray-300">
          <GroupNodeSortableList
            groupNodes={groupNodes}
            setGroupNodes={setGroupNodes}
            handleDelete={handleDelete}
            setSelected={setSelected}
            selected={selected}
          />
          <div className="h-1/6 bg-gray-300 flex justify-around items-center">
            <button
              type="button"
              className="dragndrop-btn w-1/6"
              data-testid="groupnodes-add-country"
              onClick={() => addNode("countrystep")}
            >
              Country
            </button>
            <button
              type="button"
              className="dragndrop-btn w-1/6"
              data-testid="groupnodes-add-info"
              onClick={() => addNode("infostep")}
            >
              Info
            </button>
            <button
              type="button"
              className="dragndrop-btn w-1/6"
              data-testid="groupnodes-add-input"
              onClick={() => addNode("inputstep")}
            >
              Input
            </button>
            <button
              type="button"
              className="dragndrop-btn w-1/6"
              data-testid="groupnodes-add-options"
              onClick={() => addNode("optionsstep")}
            >
              Options
            </button>
          </div>
        </div>
        <div className="w-2/5 bg-white ">
          <div className=" h-10 flex justify-end items-start absolute  w-2/5 right-0 top-0 mr-4 mt-1">
            <button
              data-testid="groupnodes-modal-close"
              type="button"
              className=""
              onClick={() => saveCommitAndClose()}
            >
              <HiOutlineX size="20" />
            </button>
          </div>
          {selected && (
            <GroupNodeAttributes
              selected={selected}
              onChange={handleChange}
              hasTopConnection={hasTopConnection}
              deviceId={deviceId}
            />
          )}
        </div>
      </div>
    </div>
  );
};

GroupNodeEditor.defaultProps = {
  deviceId: null,
};

export default GroupNodeEditor;

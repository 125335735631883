import { YAMLNode, YAMLSchema } from "../../../types/customTypes";

export const getNodeYamlData = (yamlData: YAMLSchema | null, modelNodeTypes: string[]): YAMLNode[] | null => {
  if (!yamlData) return null;
  const allYamlNodes = Object.entries(yamlData);
  const result = allYamlNodes.reduce((acc: YAMLNode[], [name, data]) => {
    if (modelNodeTypes.includes(name)) return [...acc, { name, data }];
    return acc;
  }, []);
  return result;
};

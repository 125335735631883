import React from "react";
import { AttributeConfigProps, ElementChangeFunction } from "../types/customTypes";
import { titleize } from "../common/helpers/string/titleize";

interface AttributeNumberFieldProps {
  numberParam: string;
  config: AttributeConfigProps;
  handleOnChange: ElementChangeFunction;
  showError: (attribute: string) => boolean;
}
const AttributeNumberField = ({
  numberParam,
  config,
  handleOnChange,
  showError,
}: AttributeNumberFieldProps): JSX.Element => {
  return (
    <div key={numberParam} className="mb-2 p-2 flex" data-testid="Number-Editor-container">
      <label htmlFor={numberParam} className="block text-gray-700 text-sm font-bold mb-2 capitalize">
        {titleize(numberParam)}
      </label>
      <input
        type="number"
        min={0}
        data-cy={`${numberParam}-editor`}
        data-testid={`${numberParam}-editor`}
        id={numberParam}
        value={config[numberParam] as number}
        className="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-2 block ml-auto appearance-none leading-normal text-xs font-normal"
        onChange={(e) =>
          handleOnChange({
            value: parseInt(e.target.value, 10).toString(),
            key: numberParam,
            nodeType: config.type,
            commitToHistory: false,
          })
        }
        onBlur={(e) =>
          handleOnChange({
            value: parseInt(e.target.value, 10).toString(),
            key: numberParam,
            nodeType: config.type,
            commitToHistory: true,
          })
        }
      />
      {showError(numberParam) && (
        <p style={{ fontSize: "10px" }} className="block text-red-700 font-normal capitalize">
          Required field
        </p>
      )}
    </div>
  );
};

export default AttributeNumberField;

import { TypeJourneyStep, TypeSubJourneyStep } from "../../../openapi/api";
import { removeWhitespace } from "../string/removeWhitespace";

export const journeyTitleClean = (str: string, type: string, attribute: string): string => {
  if (
    (type === TypeJourneyStep.Journey && attribute === "title") ||
    (type === TypeSubJourneyStep.SubJourney.toLowerCase() && attribute === "name")
  ) {
    return removeWhitespace(str);
  }
  return str;
};

import { NodeGroup, SanitiseRule, SanitiseTrigger } from "../types";
import { getSteps } from "../../common/helpers/openAPI";
import * as types from "../../api/models/all";
import { Node } from "../../openapi/api";
import { sanitiseRules } from "../../config/exportSanitiserRules";

const typesMapping = getSteps(types as never).reduce((acc, node) => {
  const key = node.slice(0, -4);
  return { ...acc, [key.toLowerCase()]: key[0].toLowerCase() + key.slice(1) };
}, {});

/*
 * Current workaround to overcome discrepancies between optional typing behaviours for front/back end.
 * Frontend components export empty strings for undefined elements. This is incompatible with JE requirements/parsing.
 * Allows a list of actions to be exectuted until we can do this automatically via interpretation of the openApi spec file.
 */

export const remove = (input: Node, rule: SanitiseRule): Node => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { [rule.key]: _, ...output } = input as unknown as Record<string, unknown>;
  return output as unknown as Node;
};

export const matchesCondition = (node: Node, rule: SanitiseRule): boolean => {
  switch (rule.trigger) {
    case SanitiseTrigger.EMPTY_STRING_OR_UNDEFINED:
      if (node[rule.key] === undefined || node[rule.key] === "") {
        return true;
      }
      break;
    case SanitiseTrigger.EMPTY_STRING:
      if (node[rule.key] === "") {
        return true;
      }
      break;
    default:
      return false;
  }
  return false;
};

export const processRuleAction = (node: Node, rule: SanitiseRule): Node => {
  let outputNode = node;
  if (matchesCondition(node, rule)) {
    const actions = { remove };
    if (Object.prototype.hasOwnProperty.call(actions, rule.action)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      outputNode = actions[rule.action](node, rule);
    }
  }
  return outputNode;
};

/*
 * FIXME: Temporary fix for incorrect types export for camelCase nodeTypes.
 * We should fix canvas node generation (with backward compatibility) to correct case typings (removing the enforced lcase)
 */
export const mappedNodeType = (node: Node): Node => {
  const output = node;
  if (Object.prototype.hasOwnProperty.call(typesMapping, node.type)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    output.type = typesMapping[node.type];
    return output;
  }
  return node;
};

export const sanitiseNode = (node: Node, rules = sanitiseRules): Node => {
  let outputNode: Node = node;
  const targetRules = rules.filter((n) => n.nodeType === node.type || n.nodeType === NodeGroup.ALL);
  targetRules.forEach((rule) => {
    outputNode = processRuleAction(outputNode, rule);
  });
  return mappedNodeType(outputNode);
};

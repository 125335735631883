import { AppState } from "../../../types/customTypes";
import { FileSystemFileHandle, FileSystemWritableFileStream } from "./types";

export const saveCanvasFile = async (state: AppState): Promise<void> => {
  if (state.tabData.current === null) return;
  const jsonFile = new File([JSON.stringify(state.tabData.tabs[state.tabData.current])], "CanvasScript.json", {
    type: "text/json",
  });
  await window
    .showSaveFilePicker()
    .then((handle: FileSystemFileHandle) => handle.createWritable())
    .then(async (writable: FileSystemWritableFileStream) => {
      await writable.write(jsonFile);
      await writable.close();
    })
    .catch((err) => new Error(err as string));
};

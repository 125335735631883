export const setClipboard = async (text: string): Promise<boolean> => {
  if (!navigator?.clipboard) {
    // eslint-disable-next-line no-console
    console.warn("Clipboard not supported");
    return false;
  }

  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn("Copy failed", error);
    return false;
  }
};

export const getClipboard = async (): Promise<string | null> => {
  if (!navigator?.clipboard) {
    // eslint-disable-next-line no-console
    console.warn("Clipboard not supported");
    return null;
  }

  try {
    return await navigator.clipboard.readText();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn("Copy failed", error);
    return null;
  }
};

export const getClipEventData = (e: ClipboardEvent): string | null => {
  return e.clipboardData?.getData("text") || null;
};

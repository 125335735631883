/**
 * SPM User Journey APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 7.2.0
 * Contact: tbc@postoffice.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { StepVisualTransitionProperty } from './StepVisualTransitionProperty';
import { HttpFile } from '../http/http';

export class ChoiceStepVisualProperties {
    'transition'?: StepVisualTransitionProperty;
    /**
    * Used to allow a user to choose between multiple possible choice views
    */
    'view'?: ChoiceStepVisualPropertiesViewEnum;
    /**
    * Name of the icon to be displayed
    */
    'icon'?: ChoiceStepVisualPropertiesIconEnum;
    /**
    * Used to allow disabled the choice
    */
    'disabled'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "transition",
            "baseName": "transition",
            "type": "StepVisualTransitionProperty",
            "format": ""
        },
        {
            "name": "view",
            "baseName": "view",
            "type": "ChoiceStepVisualPropertiesViewEnum",
            "format": ""
        },
        {
            "name": "icon",
            "baseName": "icon",
            "type": "ChoiceStepVisualPropertiesIconEnum",
            "format": ""
        },
        {
            "name": "disabled",
            "baseName": "disabled",
            "type": "boolean",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return ChoiceStepVisualProperties.attributeTypeMap;
    }

    public constructor() {
    }
}


export type ChoiceStepVisualPropertiesViewEnum = "card" | "button" ;
export type ChoiceStepVisualPropertiesIconEnum = "Add" | "Generic activity to add" | "Mail" | "Service" | "Delivery" | "Bank" | "Travel" | "Stamp" | "Insurance" | "Identity" | "ID" | "Bill" | "Receipt" | "Paper" | "Lottery" | "Ticket" | "Local" | "Location" | "Destination" | "Cash" | "Cheque" | "Folder" | "Business" | "Card" | "Chip &amp; Pin" | "Pencil" | "Entry" | "Envelopes" ;

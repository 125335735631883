import React from "react";
import { HiX } from "react-icons/hi";
import { tabStyles } from "../common/helpers/tabs/tabStyles";
import Modal from "./Modal";
import { ModalVisibility, modalData, modalSizes } from "../common/constants/modals";
import ModalRestoreDelete from "./ModalBodyRestoreDelete";

interface TabProps {
  index: number;
  title: string | undefined;
  handleCanvasChange: (canvasIndex: number) => void;
  handleCanvasDelete: (canvasIndex: number) => void;
  currentCanvas: number | null;
  isOnlyTab: boolean;
}

export const Tab = ({
  index,
  title,
  handleCanvasChange,
  currentCanvas,
  handleCanvasDelete,
  isOnlyTab,
}: TabProps): JSX.Element => {
  const { modalTitle, modalMessage, modalPrimaryButtonTitle, modalSecondaryButtonTitle } = modalData.deleteTab;
  const tabStyling = tabStyles(currentCanvas, index);
  const [modalVisibility, setModalVisibility] = React.useState<string>(ModalVisibility.OFF);

  const deleteTab = () => {
    handleCanvasDelete(index);
  };

  const close = () => {
    setModalVisibility(ModalVisibility.OFF);
  };
  return (
    <div className={`flex items-center rounded-t-lg group h-10 w-16 2xl:w-20 relative ${tabStyling.backgroundColor}`}>
      {modalVisibility === ModalVisibility.ON && (
        <Modal
          showHeader
          modalChildren={{
            [`${modalTitle}`]: (
              <ModalRestoreDelete
                modalMessage={modalMessage}
                modalPrimaryButtonTitle={modalPrimaryButtonTitle}
                modalSecondaryButtonTitle={modalSecondaryButtonTitle}
                modalPrimaryFunction={deleteTab}
                modalSecondaryFunction={close}
              />
            ),
          }}
          close={close}
          size={modalSizes.small}
        />
      )}

      <button type="button" onClick={() => handleCanvasChange(index)} className="h-full w-full">
        <div className="flexgroup-hover:border-pol-purple px-2 w-4/5">
          <p data-cy={`tab-${index}`} className={`text-left text-xs truncate text-${tabStyling.buttonTextColor}`}>
            {title}
          </p>
        </div>
      </button>
      {!isOnlyTab && (
        <div className={`${tabStyling.tabBorder} h-fit absolute right-0`}>
          <HiX
            data-testid="close-button"
            size={20}
            className="rounded-full p-1 hover:bg-gray-500 hover:bg-opacity-50"
            color={`${tabStyling.buttonTextColor}`}
            onClick={() => setModalVisibility(ModalVisibility.ON)}
          />
        </div>
      )}
    </div>
  );
};

import { isValidJsonObject } from "./isValidJsonObject";
import { JSONEditorOutput, Primitive } from "../../../types/customTypes";
import { nonEmptyArrayKeys, defaultArrayValue } from "../../constants/jsonEditor";

export const inputValidator = (value: Primitive, key: string, isJSONEditor = false): JSONEditorOutput => {
  const isValid = isValidJsonObject(value);
  let newValue: JSONEditorOutput = value;
  if (isValid && isJSONEditor && typeof value !== "number") {
    newValue = JSON.parse(String(value)) as JSONEditorOutput;
  }
  if (nonEmptyArrayKeys.includes(key) && value === "") {
    newValue = defaultArrayValue;
  }
  return newValue || "";
};
export default inputValidator;

import * as monaco from "monaco-editor";
import { ConfigProps, DataProps, NodeValidationCode } from "../../types/customTypes";
import { byPassedAttributes } from "../../fixtures/byPassAttributes";

export interface ValidResults {
  valid: boolean;
  errorCode: NodeValidationCode | undefined;
}

export interface ElementValidResult {
  valid: boolean;
  errorCodes: NodeValidationCode[];
}

export const validateNode = (
  data: DataProps,
  requiredFields: string[],
  NodeJsonErrors: { [key: string]: monaco.editor.IMarker[] }
): ElementValidResult => {
  const { config } = data;
  const result: ElementValidResult = { valid: true, errorCodes: [] };
  const { valid: requiredFieldValid, errorCode: requiredFieldErrorCode } = validateRequiredFields(
    config,
    requiredFields
  );
  const { valid: jsonFieldValid, errorCode: jsonFieldErrorCode } = validateJsonFields(NodeJsonErrors);
  result.valid = requiredFieldValid && jsonFieldValid;
  if (requiredFieldErrorCode) result.errorCodes.push(requiredFieldErrorCode);
  if (jsonFieldErrorCode) result.errorCodes.push(jsonFieldErrorCode);

  return result;
};

export const isByPassedAttribute = (attr: string): boolean => {
  return byPassedAttributes.includes(attr);
};

export const validateRequiredFields = (config: ConfigProps, requiredFields: string[]): ValidResults => {
  let valid = true;
  let errorCode: NodeValidationCode | undefined;
  if (requiredFields.length < 0) return { valid, errorCode };
  requiredFields.forEach((requiredField) => {
    if (!isByPassedAttribute(requiredField) && String(config[requiredField]).length === 0) {
      valid = false;
      if (errorCode !== NodeValidationCode.REQUIRED) {
        errorCode = NodeValidationCode.REQUIRED;
      }
    }
  });
  return { valid, errorCode };
};

export const validateJsonFields = (NodeJsonErrors: { [key: string]: monaco.editor.IMarker[] }): ValidResults => {
  let valid = true;
  let errorCode: NodeValidationCode | undefined;
  if (Object.values(NodeJsonErrors).every((array) => array.length === 0)) return { valid, errorCode };
  valid = false;
  errorCode = NodeValidationCode.JSON;
  return { valid, errorCode };
};

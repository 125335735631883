import { ReactFlowInstance, XYPosition } from "reactflow";

export const getCanvasMousePosition = (
  event: React.MouseEvent,
  reactFlowWrapper: React.MutableRefObject<HTMLDivElement>,
  reactFlowInstance: ReactFlowInstance
): XYPosition => {
  const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
  return reactFlowInstance.project({
    x: event.clientX - reactFlowBounds.left,
    y: event.clientY - reactFlowBounds.top,
  });
};

import React, { useState, useEffect } from "react";
import {
  SortableItemPropsWithConfig,
  ElementChangeProps,
  AttributeNode,
  NodeValidationDatum,
} from "../types/customTypes";
import NonCommonNodeProperties from "./NonCommonNodeProperties";
import CommonNodeProperties from "./CommonNodeProperties";
import { DeprecatedNodeType } from "../common/constants/deprecatedNode";

interface Props {
  selected: SortableItemPropsWithConfig | null;
  onChange: (id: string, key: string, value: string | number | boolean) => void;
  hasTopConnection: boolean;
  deviceId: string | null;
}

const blankValidationDatum: NodeValidationDatum = {
  requiredFields: [],
  nodeJsonErrors: {},
};

const GroupNodeAttributes = ({ selected, onChange, hasTopConnection, deviceId }: Props): JSX.Element => {
  if (selected === null) {
    return <div />;
  }
  const [selectedAttr, setSelectedAttr] = useState<SortableItemPropsWithConfig | null>(null);

  useEffect(() => {
    if (selected.id !== selectedAttr?.id) {
      setSelectedAttr(selected);
    }
  }, [selected, selectedAttr, setSelectedAttr]);

  const onChangeWithID = (change: ElementChangeProps) => {
    const { value, key } = change;
    if (selectedAttr && selectedAttr.config[key] === value) {
      return;
    }
    const changeElement = { [key]: value };

    if (selectedAttr) {
      setSelectedAttr({
        id: selectedAttr.id,
        config: { ...selectedAttr.config, ...changeElement },
      } as unknown as SortableItemPropsWithConfig);
      onChange(selectedAttr.id, key, value);
    }
  };

  return (
    <div className="h-full overflow-y-auto right-0 bottom-0">
      <h3 className="text-gray-700 text-sm font-bold mb-2 capitalize w-full mt-8 flex justify-center">
        <>{selectedAttr && selectedAttr.config.type} Node</>
      </h3>
      <div className="bg-white w-full grow px-2">
        {selectedAttr && selectedAttr.config.title !== DeprecatedNodeType && (
          <div>
            <CommonNodeProperties
              handleOnChange={onChangeWithID}
              nodeData={
                {
                  id: selectedAttr.id,
                  data: { config: selectedAttr.config, validationDatum: { ...blankValidationDatum } },
                } as AttributeNode
              }
              hasTopConnection={hasTopConnection}
            />
            <NonCommonNodeProperties
              deviceId={deviceId}
              excludedFields={["enablers"]}
              handleOnChange={onChangeWithID}
              nodeData={
                {
                  id: selectedAttr.id,
                  data: { config: selectedAttr.config, validationDatum: { ...blankValidationDatum } },
                } as AttributeNode
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default GroupNodeAttributes;

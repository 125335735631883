import { toast } from "react-toastify";
import React, { useEffect, useState, useRef } from "react";
import ReactFlow, {
  ReactFlowInstance,
  Connection,
  Edge,
  Node,
  EdgeChange,
  NodeChange,
  Controls,
  useViewport,
  useReactFlow,
  NodeTypes,
} from "reactflow";
import { sjDownloader } from "../common/helpers/subJourneys/sjDownloader";
import { dbStore } from "../dataBase/dbStore";
import { useKeyPress } from "../common/hooks/useKeyPress";
import { dropAction } from "../common/actions/dropAction";
import { validateNodeConnectionRules, validateNodeTypeConnectionRules } from "../common/actions/ruleValidation";
import { useCopyCutPaste, EditAction } from "../common/hooks/useCopyCutPaste";
import { ElementActions } from "../common/reducers/elements";
import AttributeBar from "./AttributeBar";
import {
  DataProps,
  ButtonToolProps,
  FocusTools,
  CanvasActionType,
  TabToolProps,
  SjDownloadResponseMessage,
  InputKey,
} from "../types/customTypes";
import { nodeTypes } from "./Nodes";
import Modal from "./Modal";
import { modalData, modalSizes, ModalVisibility } from "../common/constants/modals";
import ModalBodyRestoreDelete from "./ModalBodyRestoreDelete";
import { useCanvasNavigation } from "../common/hooks/useCanvasNavigation";
import { traversalTarget } from "../common/helpers/nodes/nodeTraversal";
import { restoreValidTabs } from "../common/helpers/tabs/restoreValidTabs";
import { attributeBarWidths } from "../common/constants/AttributeBarSizes";
import { CanvasTools } from "../types/collections";
import { CanvasKeyGuide } from "./CanvasKeyGuide";
import { isEqual } from "../common/utils";
import { polyfillNodes } from "../common/helpers/nodes/polyfillNodes";
import { flowKeyValue, FlowAction } from "../common/helpers/modifierKeys";
import { ApiFetchStatus } from "../common/constants/apiConst";
import { useKeyDispatchHandler, DomTarget } from "../common/hooks/useKeyDispatchHandler";
import { getOS, Platform } from "../common/helpers/platform";

interface TabCanvasProps {
  canvasTools: CanvasTools;
  buttonTools: ButtonToolProps;
  focusTools: FocusTools;
  tabTools: TabToolProps;
}

const TabCanvas = ({ canvasTools, buttonTools, focusTools, tabTools }: TabCanvasProps): JSX.Element => {
  const { centerPoint } = buttonTools;
  const { modalTitle, modalMessage, modalPrimaryButtonTitle, modalSecondaryButtonTitle } = modalData.restore;
  const { canvas, state, elementsDispatch, setAttributeBar, isAttributeBar } = canvasTools;
  const reactFlowWrapper = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance | null>(null);
  const [selectedNode, setSelectedNode] = useState<Node<DataProps> | null>(null);
  const [hasTopConnection, setHasTopConnection] = useState<boolean>(false);
  const [isSpacePressed] = useKeyPress(" ");
  const [modalVisibility, setModalVisibility] = useState<string>(ModalVisibility.OFF);
  const {
    editSelectionHandler,
    editMouseHandler,
    editEventHandler,
    data: { notification, action },
  } = useCopyCutPaste(reactFlowInstance, reactFlowWrapper);
  const { setEdges } = useReactFlow();
  const viewport = useViewport();
  const { setCenter, zoomTo } = useReactFlow();
  const [canvasKeyAction] = useCanvasNavigation();
  const { focusedNode, setFocusedNode } = focusTools;
  const unSavedJourneys = dbStore.Get();
  const sidebarRef = useRef<HTMLInputElement>(null);
  const [isResizing, setIsResizing] = useState(false);
  const [attributeBarOpen, setAttributeBarOpen] = useState(true);
  const [attributeBarWidth, setAttributeBarWidth] = useState(attributeBarWidths.defaultSize);
  const [responseMessage, setResponseMessage] = useState<SjDownloadResponseMessage | null>(null);
  const onInit = (_reactFlowInstance: ReactFlowInstance) => setReactFlowInstance(_reactFlowInstance);
  const os = getOS();

  useKeyDispatchHandler([
    {
      keys: [{ action: InputKey.Z, ctrl: true }],
      target: DomTarget.canvas,
      action: () => elementsDispatch({ type: ElementActions.HISTORY_UNDO }),
    },
    {
      keys:
        os === Platform.OSX ? [{ action: InputKey.Z, ctrl: true, shift: true }] : [{ action: InputKey.Y, ctrl: true }],
      target: DomTarget.canvas,
      action: () => elementsDispatch({ type: ElementActions.HISTORY_REDO }),
    },
    {
      keys: [InputKey.Delete, InputKey.Backspace],
      target: DomTarget.canvas,
      action: () => elementsDispatch({ type: ElementActions.REMOVE_SELECTION }),
    },
    {
      keys: [{ action: InputKey.A, ctrl: true }],
      target: DomTarget.canvas,
      action: () => elementsDispatch({ type: ElementActions.SELECT_ALL }),
    },
  ]);

  const startResizing = React.useCallback((): void => {
    if (!attributeBarOpen) {
      setAttributeBarOpen(true);
    } else {
      setIsResizing(true);
    }
  }, [attributeBarOpen]);

  const stopResizing = React.useCallback(() => {
    setIsResizing(false);
  }, []);

  const resize = React.useCallback(
    (mouseMoveEvent: MouseEvent) => {
      if (
        isResizing &&
        sidebarRef.current &&
        sidebarRef.current.getBoundingClientRect().right - mouseMoveEvent.clientX >= attributeBarWidths.min &&
        sidebarRef.current.getBoundingClientRect().right - mouseMoveEvent.clientX <= attributeBarWidths.max
      ) {
        mouseMoveEvent.preventDefault();
        setAttributeBarWidth(sidebarRef.current.getBoundingClientRect().right - mouseMoveEvent.clientX);
      }
    },
    [isResizing]
  );

  const onConnect = (params: Connection | Edge) => {
    const { source, sourceHandle, target, targetHandle } = params;
    if (!target || !sourceHandle || !targetHandle || !source) return;
    if (
      validateNodeConnectionRules(canvas.edges, source, sourceHandle, target, targetHandle) &&
      validateNodeTypeConnectionRules(canvas.nodes, source, sourceHandle, target, targetHandle)
    ) {
      elementsDispatch({ type: ElementActions.CONNECT, payload: { params } });
    }
    setEdges(canvas.edges);
  };

  /* eslint no-param-reassign: "error" */
  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  const onNodesChange = (change: NodeChange[]) => {
    elementsDispatch({ type: ElementActions.APPLY_NODE_CHANGE, payload: { change } });
  };

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (reactFlowInstance === null) return;
    const newNode = dropAction(event, reactFlowWrapper, reactFlowInstance);
    elementsDispatch({ type: ElementActions.DROP, payload: { newNode } });
    setSelectedNode(newNode);
    setAttributeBar(true);
  };

  const onNodeDoubleClick = (_, node: Node<DataProps>) => {
    sjDownloader(node, canvasTools, buttonTools, tabTools, setResponseMessage);
  };

  const findTopConnection = (sNode: Node<DataProps> | null): void => {
    if (sNode && sNode.id) {
      setHasTopConnection(!!canvas.edges.find((edge) => edge.target === sNode.id && edge.targetHandle === "T"));
    } else {
      setHasTopConnection(false);
    }
  };

  useEffect(() => {
    findTopConnection(selectedNode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNode, canvas.edges]);

  // Possible To-do lifecycle event  useNodesHook
  useEffect(() => {
    setCenter(centerPoint.x, centerPoint.y);
    zoomTo(0.6);
  }, [centerPoint, setCenter, zoomTo]);

  useEffect(() => {
    if (responseMessage) {
      const { status, message, journeyName } = responseMessage;
      if (status === ApiFetchStatus.FAIL) {
        toast.error(message);
      } else if (journeyName) toast.success(`${journeyName} ${message}`);
    }
  }, [responseMessage]);

  useEffect(() => {
    elementsDispatch({ type: ElementActions.UPDATE_VIEWPORT, payload: { viewport } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewport]);

  const findNode = (nodes: Node<DataProps>[], selectNode: Node<DataProps>) => {
    return nodes.find((element) => element.id === selectNode.id);
  };

  useEffect(() => {
    if (focusedNode) {
      const foundNode = findNode(canvas.nodes, focusedNode);
      if (foundNode) {
        setSelectedNode(foundNode);
        elementsDispatch({
          type: ElementActions.SHIFT_FOCUS_NODE,
          payload: { node: foundNode },
        });
        const { height, width } = foundNode;
        const offsetX = width ? width / 2 : 0;
        const offsetY = height ? height / 2 : 0;
        const { position: newPosition } = foundNode;
        const { zoom } = viewport;
        setCenter(newPosition.x + offsetX, newPosition.y + offsetY, { zoom, duration: 700 });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusedNode]);

  useEffect(() => {
    if (reactFlowInstance) {
      reactFlowInstance.setViewport(canvas.canvasData.viewport);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tabData.current]);

  useEffect(() => {
    if (unSavedJourneys && unSavedJourneys.tabData.tabs.length) {
      const recoveredData = restoreValidTabs(unSavedJourneys.tabData.tabs);
      if (recoveredData.length) {
        recoveredData.map((tab) => {
          return { ...tab, nodes: polyfillNodes(tab.nodes) };
        });
        setModalVisibility(ModalVisibility.ON);
        elementsDispatch({ type: ElementActions.RECOVER_TABS, payload: { recoveredData } });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unSavedJourneys]);

  useEffect(() => {
    const editEvents = ["cut", "copy", "paste"];
    editEvents.forEach((evt) => {
      document.addEventListener(evt as keyof DocumentAndElementEventHandlersEventMap, editEventHandler);
    });
    return () => {
      editEvents.forEach((evt) => {
        document.removeEventListener(evt as keyof DocumentAndElementEventHandlersEventMap, editEventHandler);
      });
    };
  }, [editEventHandler]);

  useEffect(() => {
    if (canvasKeyAction !== null) {
      const target = traversalTarget(canvas, selectedNode, canvasKeyAction);
      if (!target) return;
      switch (target.type) {
        case CanvasActionType.SEARCH:
          elementsDispatch({
            type: ElementActions.UPDATE_SEARCH_SELECTION_BY_ID,
            payload: { nodeId: target.node.id },
          });
          break;
        default:
          break;
      }
      if (isEqual(target.node, focusedNode)) return;
      if (target.node) {
        setFocusedNode(target.node);
      }
    }
  }, [canvasKeyAction, canvas, selectedNode, setFocusedNode, focusedNode, elementsDispatch]);

  useEffect(() => {
    if (notification.message !== "Copied!") return;
    toast(
      <div>
        <p>{notification.message}</p>
      </div>,
      { type: notification.status }
    );
  }, [notification]);

  useEffect(() => {
    switch (action.type) {
      case EditAction.PASTE:
        elementsDispatch({
          type: ElementActions.PASTE,
          payload: {
            copyCache: action.selectionData,
            mouseData: action.mouseData,
            pasteIncrement: action.pasteIncrement,
          },
        });
        break;
      case EditAction.CUT:
        elementsDispatch({
          type: ElementActions.REMOVE_NODE,
          payload: { nodesToRemove: action.selection.nodes },
        });
        elementsDispatch({
          type: ElementActions.REMOVE_EDGE,
          payload: { edgesToRemove: action.selection.edges },
        });
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action.type, action.pasteIncrement]);

  useEffect(() => {
    const selectedNodes = canvas.nodes.filter((n) => n.selected === true);
    if (selectedNodes.length === 1) {
      setAttributeBar(true);
      if (!isEqual(selectedNode, selectedNodes[0])) {
        setSelectedNode(selectedNodes[0]);
      }
    } else {
      setAttributeBar(false);
      setSelectedNode(null);
    }
  }, [canvas.nodes, setAttributeBar, selectedNode, setSelectedNode]);

  useEffect(() => {
    window.addEventListener("mousemove", resize);
    window.addEventListener("mouseup", stopResizing);
    return () => {
      window.removeEventListener("mousemove", resize);
      window.removeEventListener("mouseup", stopResizing);
    };
  }, [resize, stopResizing]);

  const close = () => {
    setModalVisibility(ModalVisibility.OFF);
  };

  const handleDeleteStorage = () => {
    elementsDispatch({ type: ElementActions.CLEAR_ALL_TABS });
    close();
  };

  return (
    <div className="flex-grow overflow-hidden h-full flex flex-row min-h-full">
      {modalVisibility === ModalVisibility.ON && (
        <Modal
          showHeader
          modalChildren={{
            [`${modalTitle}`]: (
              <ModalBodyRestoreDelete
                modalMessage={modalMessage}
                modalPrimaryButtonTitle={modalPrimaryButtonTitle}
                modalSecondaryButtonTitle={modalSecondaryButtonTitle}
                modalPrimaryFunction={close}
                modalSecondaryFunction={handleDeleteStorage}
              />
            ),
          }}
          close={close}
          size={modalSizes.small}
        />
      )}
      <div className="z-0 flex-grow overflow-hidden" ref={reactFlowWrapper}>
        {modalVisibility === ModalVisibility.OFF && (
          <ReactFlow
            defaultNodes={canvas.nodes}
            defaultEdges={canvas.edges}
            nodes={canvas.nodes}
            edges={canvas.edges}
            onConnect={onConnect}
            onEdgesChange={(change: EdgeChange[]) => {
              elementsDispatch({ type: ElementActions.APPLY_EDGE_CHANGE, payload: { change } });
            }}
            onNodesChange={onNodesChange}
            onInit={onInit}
            onDrop={onDrop}
            onDragOver={onDragOver}
            onNodeDoubleClick={onNodeDoubleClick}
            onNodesDelete={() => setAttributeBar(false)}
            nodeTypes={nodeTypes as unknown as NodeTypes}
            panOnDrag
            panOnScroll={!isSpacePressed}
            zoomOnScroll={isSpacePressed}
            nodesDraggable={!isSpacePressed}
            nodesConnectable={!isSpacePressed}
            elementsSelectable={!isSpacePressed}
            selectNodesOnDrag={false}
            deleteKeyCode={[]}
            nodesFocusable={false}
            selectionKeyCode={flowKeyValue(FlowAction.AREA_SELECT)}
            multiSelectionKeyCode={flowKeyValue(FlowAction.TOGGLE_SELECT)}
            onSelectionChange={editSelectionHandler}
            onPaneClick={editMouseHandler}
            minZoom={0.1}
          >
            <Controls />
            <CanvasKeyGuide />
          </ReactFlow>
        )}
      </div>
      {isAttributeBar ? (
        <>
          <div className="dragableBorder" onMouseDown={startResizing} aria-hidden="true" />
          <div
            ref={sidebarRef}
            className="attributeBarContent"
            style={{ width: attributeBarOpen ? attributeBarWidth : attributeBarWidths.minimizedSize }}
            aria-hidden="true"
          >
            <AttributeBar
              setAttributeBarOpen={setAttributeBarOpen}
              attributeBarOpen={attributeBarOpen}
              selectedNode={selectedNode}
              hasTopConnection={hasTopConnection}
              elementsDispatch={elementsDispatch}
              canvas={state.tabData.current}
              deviceId={
                state.tabData.current === null ? null : state.tabData.tabs[state.tabData.current].canvasData.deviceID
              }
            />
          </div>
        </>
      ) : (
        <div />
      )}
    </div>
  );
};

export default TabCanvas;

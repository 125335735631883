import { Node as CanvasNode, Edge as RFREdge } from "reactflow";
import { DataProps } from "../types/customTypes";
import { JourneyStep, Node } from "../openapi/api";

export type NodePosition = number[];

export type NodeWithPosition = Node & { position: NodePosition };

export interface WithSubJourneyRef {
  subJourneyRef?: string | null;
  sjName?: string;
}

export type NodeWithSubJourneyRef = Node & WithSubJourneyRef;

export interface JBuilderNode {
  id: string;
  journeyNode: NodeWithPosition & WithSubJourneyRef;
  canvasNode: CanvasNode | CanvasNode<DataProps>;
}

export interface ImportedCanvas {
  nodes: JBuilderNode[];
  edges: RFREdge[];
}

export type Edge = Omit<RFREdge, "id">;

export interface GenericNodeTypes {
  id: string;
  hashID?: string;
  journeyNode: NodeWithSubJourneyRef | (NodeWithPosition & WithSubJourneyRef);
}

export interface HashCanvas {
  nodes: GenericNodeTypes[];
  edges: Edge[];
}

export enum EdgeRelationship {
  SiblingConnection = "r",
  ChildConnection = "d",
}

export type Path = string[];
export type Paths = Path[];

export type SubJourneyStruct = Record<string, Path>;

export enum SanitiseAction {
  REMOVE = "remove",
}

export enum SanitiseTrigger {
  EMPTY_STRING_OR_UNDEFINED = "empty_undefined",
  EMPTY_STRING = "empty",
}

export enum NodeGroup {
  ALL = "*",
}

export type SanitiseRule = {
  nodeType: string | NodeGroup;
  key: string;
  action: SanitiseAction;
  trigger: SanitiseTrigger;
  options?: unknown;
};

export interface SJGroup {
  groupID: string;
  id: string;
  group: string[];
}

export interface StackNode {
  node: Node | NodeWithSubJourneyRef | JourneyStep;
  id: string;
}

export type SJ = Record<string, StackNode[]>;

export interface StackNodeWithNodes {
  nodes?: StackNode[] | null | Record<string, string>;
}
export interface NodeWithNodes {
  nodes?: Node[] | null | Record<string, string>;
}

export interface WithNodes {
  nodes?: Node[] | Record<string, string> | null;
}

export interface WithSjName {
  sjName?: string;
}

export interface SubJourneyBreakdown {
  childNodes: Node[];
  subJourneys: Record<string, Node[]>;
}

export interface UrlRefList {
  refs: { [key: string]: React.MutableRefObject<Window | null | undefined> };
}
export type OpenPreViewLink = (
  deviceID: string,
  fileName: string,
  index: number,
  openPreviewInOwnTab?: boolean
) => void;

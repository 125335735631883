import React from "react";
import { HiOutlineX } from "react-icons/hi";
import ModalTab from "./ModalTab";

export interface modalTabLastProps {
  title: string;
  isSelected: boolean;
  close: () => void;
  onSelect: (e) => void;
}
const modalTabLast = ({ title, onSelect, isSelected, close }: modalTabLastProps): JSX.Element => {
  const styles = isSelected ? "" : "text-white bg-gray-500";
  return (
    <div
      data-testid="modal-tab-last"
      className={`${styles} flex items-center justify-center w-full h-full text-lg font-semibold relative`}
    >
      <ModalTab title={title} onSelect={onSelect} isSelected={isSelected} />
      <HiOutlineX data-testid="close-icon" className="h-full text-4xl absolute right-2" onClick={close} />
    </div>
  );
};

export default modalTabLast;
